import React from 'react';
import { useTimer } from 'react-timer-hook';

const Countdown = () => {
  // Set up a countdown for 3 seconds from now
  const expiryTime = new Date();
  expiryTime.setSeconds(expiryTime.getSeconds() + 3);

  const { seconds } = useTimer({
    expiryTimestamp: expiryTime,
    onExpire: () => console.log("Countdown finished!"),
  });

  return (
    <>
      {/* Only display the countdown if seconds are greater than 0 */}
      {seconds > 0 && (
        <div
          style={{
            zIndex: 999999999,
            position: 'fixed',
            top: '8%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '150px',
            fontWeight: '800',
            color: 'white',
            letterSpacing: '-10px',
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.3)',
            fontFamily: "'Oswald', sans-serif",
          }}
        >
          {seconds}
        </div>
      )}
    </>
  );
};

export default Countdown;
