








import {
    Card, CardBody, ModalBody,
    Col, Modal, ModalFooter, ModalHeader, PopoverBody, Row, Table, Media,
    Form,
    Input
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import { addRadioFailure, addRadioRequest, addRadioSuccess, fetchRadioFailure, fetchRadioRequest, fetchRadioSuccess } from '../../store/actions/radioActions';
import axiosClient from '../../../axios-client';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import loadData from '../../../assets/lottie/laod.json'
import { Avatar, Divider, FormGroup, IconButton, InputAdornment, Pagination, Stack, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { fetchUsersFailure, fetchUsersRequest, fetchUsersSuccess } from '../../store/actions/userActions';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdOutlineContentCopy } from "react-icons/md";
import { IoCheckmarkDoneSharp, IoTrash } from "react-icons/io5";
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa';
import { RiImageAddFill } from "react-icons/ri";
import { Button } from 'react-bootstrap';
import { Bounce, ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from '../../../context/ContextProvider';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { FaRadio } from "react-icons/fa6";
import { CiStreamOn } from "react-icons/ci";


import randomstring from 'randomstring'
import CryptoJS from 'crypto-js';







import React, { useState } from 'react'
import { fetchMusicsHistoryFailure, fetchMusicsHistoryRequest, fetchMusicsHistorySuccess } from '../../store/actions/musicsHActions';
import { AiOutlineClose } from 'react-icons/ai';

export default function MusicsH() {

    const { loading, musicshistory, error } = useSelector(state => state.musicshistory);
    const [page, setPage] = useState(1); // State for current page
    const [totalPages, setTotalPages] = useState(1); // State for total pages
    const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const [radioFilter, setRadioFilter] = useState(''); // State for radio filter
    const [radioOptions, setRadioOptions] = useState([]); // State for unique radio options
    const [durationFilter, setDurationFilter] = useState(''); // New state for duration filter

    const [musicHistory, setMusicHistory] = useState([]);
    // const [error, setError] = useState(null);
    const [radio, setRadio] = useState('CHANTE FRANCE');
    // const [searchTerm, setSearchTerm] = useState('valery');
    // const [page, setPage] = useState(1);
    // const [pageSize, setPageSize] = useState(10);
    const dispatch = useDispatch()
    // Handle search input changes
    // Handle radio filter input changes
    const handleRadioChange = (event) => {
        setRadioFilter(event.target.value);  // Update radio filter
        setPage(1);  // Reset to first page when changing the filter
    };

    // Handle search input changes
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);  // Update search term
        setPage(1);  // Reset to first page when changing the search term
    };


    const handleDurationChange = (event) => {
        setDurationFilter(event.target.value);
        setPage(1); // Reset to first page when changing the duration filter
    };


    // Fetch unique radios and music history data on component mount or filter changes
    useEffect(() => {
        dispatch(fetchMusicsHistoryRequest());

        // Fetch unique radios
        axiosClient.get('amhv/')
            .then(response => {
                const radios = response.data;
                setRadioOptions(radios);
            })
            .catch(error => console.error('Error fetching radios:', error));

        // Fetch music history data based on current filters and page
        fetchMusiques(radioFilter, searchTerm, page, durationFilter);
    }, [dispatch, page, searchTerm, radioFilter, durationFilter]);  // Fetch data when any of these values change

    // Fetch music history data based on filter and page
    const fetchMusiques = async (radioId, searchTerm, page, durationFilter) => {
        const params = {
            radio: radioId || '',
            searchTerm: searchTerm,
            page: page,
            page_size: 10,
        };

        // Add duration filters to params if a filter is selected
        if (durationFilter) {
            const [minDuration, maxDuration] = durationFilter.split('-');
            params.min_duration = minDuration;
            params.max_duration = maxDuration;
        }

        try {
            const response = await axiosClient.get('musicshistoryf/', { params });
            if (response.status === 200) {
                dispatch(fetchMusicsHistorySuccess(response.data));
                setTotalPages(Math.ceil(response.data.count / 10));
            }
        } catch (error) {
            console.error('Error fetching music history:', error);
        }
    };


    // Handle page changes (pagination)
    const handlePageChange = (event, value) => {
        console.log('Page changed to: ', value);  // Debugging
        setPage(value);  // Update the page number
        fetchMusiques(radioFilter, searchTerm, value); // Call fetch with updated page
    };
    const musicResults = musicshistory?.results ?? [];

    function formatDuration(ms) {
        let seconds = Math.floor(ms / 1000); // Convert milliseconds to seconds
        let hours = Math.floor(seconds / 3600); // Get the number of hours
        seconds %= 3600; // Remainder of seconds after removing full hours
        let minutes = Math.floor(seconds / 60); // Get the number of minutes
        seconds %= 60; // Remainder of seconds after removing full minutes

        // Format the time as hh:mm:ss with leading zeros if necessary
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }


    const formatDate = (isoDate) => {
        const date = new Date(isoDate);

        // Formatting to YYYY-MM-DD HH:mm:ss
        return date.toLocaleString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    };

    // Clear search input
    const clearInput = () => {
        setSearchTerm('');
    };
    return (
        <div>
            <Row className="icons-demo-content">
                <Col xs="12">

                    <Card>
                        <CardBody>
                            <h4 className="card-title" style={{ fontWeight: 'bold' }}>Gestion Musiques</h4>


                            <Row className="mb-3 align-items-center">

                                <Col lg={2}>
                                    <FormGroup>
                                        <TextField
                                            sx={{
                                                minWidth: '200px',
                                                height: '50px',
                                                '& .MuiOutlinedInput-root': {
                                                    height: '35px',
                                                    marginRight: "10px",
                                                    marginTop: "8px",
                                                    borderRadius: '5px',
                                                },
                                            }}
                                            type="text"
                                            placeholder="Chercher..."
                                            value={searchTerm}
                                            onChange={handleSearchChange}  // Call handleSearchChange when the user types
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: searchTerm && (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={clearInput} edge="end">
                                                            <AiOutlineClose />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col lg={2}>
                                    <Form>
                                        <FormGroup className="d-flex">
                                            <Input
                                                type="select"
                                                name="radioFilter"
                                                id="radioFilter"
                                                value={radioFilter}
                                                onChange={handleRadioChange} // Use handleRadioChange for select
                                            >
                                                <option value="">Toutes les radios</option>
                                                {radioOptions.map((radio, index) => (
                                                    <option key={index} value={radio}>
                                                        {radio}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </Col>

                                <Col lg={2}>
                                    <FormGroup className="d-flex">
                                        <Input
                                            type="select"
                                            name="durationFilter"
                                            id="durationFilter"
                                            value={durationFilter}
                                            onChange={handleDurationChange}
                                        >
                                            <option value="">Filtrer par durée</option>
                                            <option value="0-1">De 0 à 1 minutes</option>

                                            <option value="1-2">De 1 à 2 minutes</option>
                                            <option value="1-3">De 1 à 3 minutes</option>
                                            <option value="1-5">De 1 à 5 minutes</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12}>
                                    <div className="card">
                                        <div className="card-body">

                                            <div style={{ maxHeight: "450px", overflowY: "auto" }} className="table-responsive">
                                                <Table responsive className="table-primary table-striped mb-0">

                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Artiste</th>
                                                            <th>Titre</th>
                                                            <th>Date de creation</th>
                                                            <th>Radio</th>
                                                            <th>Durée</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Conditional rendering: Check if there are no results */}
                                                        {musicResults.length === 0 ? (<tr>
                                                            <td colSpan="6" style={{ textAlign: 'center' }}>Aucune musique trouvée</td>
                                                        </tr>
                                                        ) : (
                                                            musicshistory.results.map(mh => (
                                                                <tr key={mh.id}>
                                                                    <th scope="row">{mh.id}</th>
                                                                    <td>{mh.artist}</td>
                                                                    <td>{mh.title}</td>
                                                                    <td>{formatDate(mh.date_creation)}</td>                                                                    <td>{mh.radio}</td>
                                                                    <td>{formatDuration(mh.duration)}</td>
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>

                                        </div>
                                    </div>
                                </Col>


                            </Row>
                            <Row>

                                <Col lg={6}>
                                    <p style={{ fontSize: "16px", marginLeft: "10px", fontWeight: 'bold' }}>Total  : {musicshistory && musicshistory.count}</p>
                                    {/* Place any other content you want to display */}
                                </Col>
                                <Col lg={6} className="d-flex justify-content-end">
                                    <Stack spacing={2}>
                                        <Pagination count={totalPages} color="secondary" page={page} onChange={handlePageChange} />
                                    </Stack>
                                </Col>
                            </Row>

                        </CardBody>


                    </Card>
                </Col>
            </Row >  </div >
    )
}
