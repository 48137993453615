import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Breadcrumb from "./Breadcrumb/reducer";
import userReducer from "./reducers/userReducers";



import redioReducer from "./reducers/radioReducer";
import musiqueReducer from "./reducers/musiqueReducer";
import musicsHistoryReducer from "./reducers/musicsHReducer";


const rootReducer = combineReducers({
  // public
  Layout,
  //Breadcrumb items
  Breadcrumb,

  users: userReducer,

  radios: redioReducer,
  musiques: musiqueReducer,
  musicshistory: musicsHistoryReducer

})

export default rootReducer
