// action types
export const FETCH_MUSICS_HISTORY_REQUEST = 'FETCH_MUSICS_HISTORY_REQUEST';
export const FETCH_MUSICS_HISTORY_SUCCESS = 'FETCH_MUSICS_HISTORY_SUCCESS';
export const FETCH_MUSICS_HISTORY_FAILURE = 'FETCH_MUSICS_HISTORY_FAILURE';

export const ADD_MUSICS_HISTORY_REQUEST = 'ADD_MUSICS_HISTORY_REQUEST';
export const ADD_MUSICS_HISTORY_SUCCESS = 'ADD_MUSICS_HISTORY_SUCCESS';
export const ADD_MUSICS_HISTORY_FAILURE = 'ADD_MUSICS_HISTORY_FAILURE';

// action creators for fetching music history
export const fetchMusicsHistoryRequest = () => ({
    type: FETCH_MUSICS_HISTORY_REQUEST
});

export const fetchMusicsHistorySuccess = (musicsHistory) => ({
    type: FETCH_MUSICS_HISTORY_SUCCESS,
    payload: musicsHistory
});

export const fetchMusicsHistoryFailure = (error) => ({
    type: FETCH_MUSICS_HISTORY_FAILURE,
    payload: error
});

// action creators for adding music history
export const addMusicsHistoryRequest = (musicsHistoryData) => ({
    type: ADD_MUSICS_HISTORY_REQUEST,
    payload: musicsHistoryData
});

export const addMusicsHistorySuccess = () => ({
    type: ADD_MUSICS_HISTORY_SUCCESS
});

export const addMusicsHistoryFailure = (error) => ({
    type: ADD_MUSICS_HISTORY_FAILURE,
    payload: error
});
