import React, { useState } from 'react'
import { Card, CardBody, Row, Table, ModalBody, Col, Modal, ModalFooter, ModalHeader, Media, Label, Form, FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap'
// import tune from "../../../assets/images/tune.png"
import { useDispatch, useSelector } from 'react-redux';
import { fetchRadioFailure, fetchRadioRequest, fetchRadioSuccess } from '../../store/actions/radioActions';
import axiosClient from '../../../axios-client';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import loadData from '../../../assets/lottie/laod.json'
import { Avatar, CircularProgress, Divider, IconButton, Pagination, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { MdCheckCircle, MdClose, MdPlayArrow } from "react-icons/md";
import { IoTrash } from "react-icons/io5";
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaMusic } from 'react-icons/fa';
// import Resizer from "react-image-file-resizer";
// import { RiImageAddFill } from "react-icons/ri";
import { Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';

import 'react-toastify/dist/ReactToastify.css';
// import { useStateContext } from '../../../context/ContextProvider';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import { FaRadio } from "react-icons/fa6";
import { addMusiqueFailure, addMusiqueRequest, addMusiqueSuccess, fetchMusiqueFailure, fetchMusiqueRequest, fetchMusiqueSuccess } from '../../store/actions/musiqueActions';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Bounce, ToastContainer, toast } from 'react-toastify';

// import MdModal from 'react-bootstrap/Modal';
import MusicLyrics from './LyricsLecture';
import { AiOutlineClose, AiOutlineDownload, AiOutlineFileExcel } from 'react-icons/ai';
import { TextField, InputAdornment } from '@mui/material';
import JSZip from 'jszip';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useStateContext } from '../../../context/ContextProvider';
import MImport from './musiquesImport';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
// import { FaRadio } from 'react-icons/fa6';

export default function Musiques() {
    const [nameMessageError, setNameMessageError] = useState(null); // Initialize nameMessage state variable
    // const { user, setToken, setUser, setRefresh, token } = useStateContext();
    const [musiqueId, setMusiqueId] = useState([]);
    const animatedComponents = makeAnimated();
    const { user } = useStateContext();

    // const [tagMessageError, setTagMessageError] = useState(null); // Initialize nameMessage state variable
    const [alerted, setAlerted] = useState(false); // Initialize nameMessage state variable

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [dModal, setDModal] = useState(false);
    const [expModal, setExpModal] = useState(false);
    const [expModalCsv, setExpModalCsv] = useState(false);

    const toggleDelete = () => setDModal(!dModal);



    const CloseButton = styled.button`
  position: relative;
  top: 10px;
  left: 230px;
  font-size: 1.5rem;
  font-weight: 1000;
  line-height: 1;
  color: black;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
  }
`;

    const toggleExport = () => setExpModal(!expModal);
    const [isExporting, setIsExporting] = useState(false);
    const [showAlertOnClose, setShowAlertOnClose] = useState(false);
    const [dismissExp, setDismissExp] = useState(false);

    const toggleExportCsv = async () => {

        if (dismissExp) {
            setDismissExp(false);


            cancelExport()
        }


        else {
            if (!csvLoad && !isExporting) {
                try {
                    // Start loading and prepare data
                    setIsExporting(true);
                    setCsvLoad(true);
                    await prepareData();

                    setDismissExp(true)

                } catch (error) {
                    console.error('Error preparing data:', error);
                } finally {
                    // End loading
                    setCsvLoad(false);
                    setIsExporting(false);
                    // setDismissExp(false)

                    // Toggle the modal visibility
                    setExpModalCsv(!expModalCsv);
                }
            }
        }

    };

    console.log("Dismiss Export  ", dismissExp);

    const cancelExport = () => {
        // Close the modal without initiating export
        setExpModalCsv(false);
        // Optionally, reset any loading states or other related states if needed
        setIsExporting(false);
        setCsvLoad(false);
    };





    const [selectedRadioFilter, setSelectedRadioFilter] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const [mModal, setMModal] = useState(false);

    const [mLyricsModal, setMLyricsModal] = useState(false);

    const toggleLaunch = () => { setMModal(!mModal); setLyricsTimeStamp("") };

    const toggleLyricsLaunch = () => { setMLyricsModal(!mModal) };

    const [changeModal, setChangeModal] = useState(true)

    // const [copied, setCopied] = useState(false);
    // const [indexLinkCopied, setIndexLinkCopied] = useState(-1)
    const { radios } = useSelector(state => state.radios);

    const [radioData, setRadioData] = useState([
        { value: '', label: '' }
    ]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [radioId, setRadioId] = useState([]);



    const [image, setImage] = useState()

    const [selectedImage, setSelectedImage] = useState()
    const [selectedImg, setSelectedImg] = useState()

    const [instance, setInstance] = useState()
    const [allowLyrics, setAllowLyrics] = useState(false);

    const [tagRadio, setTagRadio] = useState()
    const [selectedUser, setSelectedUser] = useState();
    const [audioLinkStream, setAudioLinkStream] = useState()
    const [name, setName] = useState()

    const [message, setMessage] = useState('');
    const [hasLyricsFilter, setHasLyricsFilter] = useState('');


    const [musiques__, setMusiques__] = useState([]);
    const [mLoad, setMLoad] = useState(true);
    const [errorM, setError] = useState('');

    const [m_Load, setM_Load] = useState(false);


    const { loading, musiques, error } = useSelector(state => state.musiques);
    const [page, setPage] = useState(1); // State for current page
    const [totalPages, setTotalPages] = useState(1); // State for total pages
    const [totalFPages, setTotalFPages] = useState(1); // State for total pages
    const [fPage, setFPage] = useState(1); // State for current page


    const [modal_center, setmodal_center] = useState(false)





    // const [showLoading, setShowLoading] = useState(false);


    const [musicLoad, setMusicLoad] = useState(false);
    const [musicLoadE, setMusicLoadE] = useState(false);

    const [lyricsLoad, setLyricsLoad] = useState(false);



    const [titre, setTitre] = useState()
    const [artiste, setArtiste] = useState()



    const [duration, setDuration] = useState()

    const [selectedMusique, setSelectedMusique] = useState()
    const [selectedRadio, setSelectedRadio] = useState()


    const [selectedMusicFile, setSelectedMusicFile] = useState(null);
    const [showFullFilename, setShowFullFilename] = useState(false);
    const [playingMusic, setPlayingMusic] = useState(null);
    const [openMusicMd, setOpenMusicMd] = useState(false);
    const [musiqueData, setMusiqueData] = useState([]);
    const [currentTrack, setCurrentTrack] = useState(null);

    const [musicLink, setMusicLink] = useState("");



    const [musicL, setMusicL] = useState(false);
    const [lyricsTimeStamp, setLyricsTimeStamp] = useState("");

    const [currentMusicTitle, setCurrentMusicTitle] = useState("");

    const [currentMusicArtiste, setCurrentMusicArtiste] = useState("");
    const [progress, setProgress] = useState(0);
    // const [Exploading, setExpLoading] = useState(false);






    const [lyricsRealTime, setLyricsRealTime] = useState("");

    // const [currentMusicTitle, setCurrentMusicTitle] = useState("");
    // const [lLoadR, setLLoadR] = useState(false);



    console.log("selectedUser", selectedUser);
    useEffect(() => {
        dispatch(fetchRadioRequest());

        const fetchAllUsers = async () => {
            let nextPage = 'radios/'; // Start with the initial endpoint
            let allRadios = [];

            try {
                while (nextPage) {
                    const response = await axiosClient.get(nextPage);
                    allRadios = [...allRadios, ...response.data.results];
                    console.log("radiossssss: ", response.data.results);
                    nextPage = response.data.next; // Update to the next page URL or null if no more pages
                }

                dispatch(fetchRadioSuccess(allRadios));
            } catch (error) {
                dispatch(fetchRadioFailure(error.message));
            }
        };
        const getDataRadios = async () => {
            if (user.id) {

                const user_id = user && user?.id

                await axiosClient.get(`targeted_radios/?user=${user_id}`).then(async (res) => {
                    const transformedData = res.data?.map(item => ({ value: item.id, label: item.name }))
                    setRadioData(transformedData)
                })
                if (radios && Array.isArray(radios)) {
                    console.table(radios);
                }
            }

        }

        getDataRadios()


        fetchAllUsers();

    }, [dispatch, user.id]);


    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function tog_center() {
        setmodal_center(!modal_center)
        removeBodyCss()
        setSelectedImage()
        setSelectedImg()
        setImage()
        setName()
        setAudioLinkStream()
        setInstance()
        setSelectedUser()
        setTagRadio()
        setMessage()
    }


    async function updatingMusiqueDuree() {
        let count = 0
        let lg = 0

        const params = {
            hasLyrics: hasLyricsFilter, // Value from props
            radio: selectedRadioFilter, // Value from props
            searchTerm: searchQuery, // Value from props
        };

        const response = await axiosClient.get('get_all_musics__/', { params });
        const musiques__ = response.data.musique;
        console.log("total process : ", musiques__.length, musiques__);

        for (const msc of musiques__) {
            lg++
            console.log("count log ", lg);
            try {
                const audio = new Audio(msc.musique);
                let durationInSeconds = 0;

                // Create a Promise to wait for the 'loadedmetadata' event
                const durationPromise = new Promise((resolve, reject) => {
                    audio.addEventListener('loadedmetadata', () => {
                        durationInSeconds = audio.duration;
                        console.log("durationInSeconds", durationInSeconds);
                        resolve(durationInSeconds);
                    });

                    audio.addEventListener('error', (err) => {
                        console.error('Error loading audio:', err);
                        reject(new Error('Audio loading error'));
                    });
                });

                // Trigger loading of the audio file's metadata
                audio.load();

                // Wait for the metadata to be loaded and the duration to be resolved
                await durationPromise;

                const formDataEdit = new FormData();
                formDataEdit.append('duree', durationInSeconds);

                await axiosClient.patch(`musiques/${msc.id}/`, formDataEdit);
                count++

                console.log("done!!!", count);
            } catch (error) {
                console.error('Error editing musique:', error);
            }
        }
    }



    useEffect(() => {
        dispatch(fetchMusiqueRequest());

        axiosClient.get(`lyrics_music/?page=${page}&hasLyrics=${hasLyricsFilter}&searchTerm=${searchQuery}&radio=${selectedRadioFilter}`)
            .then(response => {
                const data = response.data;
                if (data.results.length === 0) {
                    dispatch(fetchMusiqueSuccess([]));
                }
                dispatch(fetchMusiqueSuccess(data));
                setTotalPages(Math.ceil(data.count / 10));
            })
            .catch(error => {
                dispatch(fetchMusiqueFailure(error.message));
            });
    }, [dispatch, page, hasLyricsFilter, searchQuery, selectedRadioFilter]);





    const handlePageChange = (event, value) => {
        setPage(value); // Update page state when page changes
    };


    const handleFPageChange = (event, value) => {
        setFPage(value); // Update page state when page changes

        alert("pages changed²")
    };


    console.log("the Fpage", fPage)










    // const check__musicT_availability = async (titre , artiste) => {
    //     try {
    //         const response = await axiosClient.get(`check__musicArtiste_availability/?titre=${titre}`);
    //         const responseData = response.data;

    //         if ('message' in responseData) {
    //             if (responseData.message === "Musique with this title already exists.") {
    //                 return false; // Tag is not available
    //             } else if (responseData.message === "title is available.") {
    //                 return true; // Tag is available
    //             } else {
    //                 return "Unknown response from server"; // Handle unexpected message
    //             }
    //         } else {
    //             return "Unknown response from server"; // Handle unexpected response
    //         }
    //     } catch (error) {
    //         console.error("Error checking title availability:", error);
    //         return "Error checking title availability.";
    //     }
    // };

    const checkMusicAvailability = async (titre, artiste, id = 0) => {
        try {
            const response = await
                axiosClient.get(`/check__musicArtiste_availability/?titre=${titre}&artiste=${artiste}`);
            const data = await response.data;
            console.log("datata", data);

            if (('exists' in data && id === 0) || ('exists' in data && id !== 0 && id !== data.id)) {
                console.log("modif different id et post")

                // console.log("datata", data);
                return data.exists; // Return boolean indicating if music exists
            }
            else if (
                'exists' in data && id !== 0 && id === data.id
            ) {
                console.log("modif meme id")
                return false;
            }
            else {
                console.error("Unexpected response from server:", data);
                return false; // Default to false if unexpected response
            }
        } catch (error) {
            console.error("Error checking music availability:", error);
            return false; // Default to false in case of error
        }
    };

    // const check__musicA_availability = async (artiste) => {
    //     try {
    //         const response = await axiosClient.get(`check__musicA_availability/?artiste=${artiste}`);
    //         const responseData = response.data;

    //         if ('message' in responseData) {
    //             if (responseData.message === "Musique with this artiste already exists.") {
    //                 return false; // Tag is not available
    //             } else if (responseData.message === "artiste is available.") {
    //                 return true; // Tag is available
    //             } else {
    //                 return "Unknown response from server"; // Handle unexpected message
    //             }
    //         } else {
    //             return "Unknown response from server"; // Handle unexpected response
    //         }
    //     } catch (error) {
    //         console.error("Error checking artiste availability:", error);
    //         return "Error checking artiste availability.";
    //     }
    // };


    const notifySuccess = (text) => toast.success(text, {
        position: "top-center",
        autoClose: 400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });

    const notifySuccessDownload = (text) => toast.success(text, {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });

    const notifyError = (text) => toast.error(text, {
        position: "top-center",
        autoClose: 400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });

    const handleRadioChange = (selectedOptions) => {
        console.log("optionssssssssssssssssss: ", selectedOptions);
        setSelectedOptions(selectedOptions);
    };
    const handleSubmit = async () => {

        setMusicLoad(true)


        if (!titre || !artiste || !selectedMusique || !selectedOptions) {
            setMessage("Veuillez remplir tous les champs!");
            setMusicLoad(false)

            return;
        }



        const verificationMessage = await checkMusicAvailability(titre, artiste);
        // const artisteMessage = await check__musicA_availability(artiste);

        if (verificationMessage === true) {
            notifyError("Le titre et l'artiste de la musique existent déjà !");
            setMusicLoad(false)

            return; // Prevent further execution of the function
        }
        // } else {
        //     if (titleMessage === false) {
        //         notifyError("Ce titre de musique existe déjà !");
        //     }
        //     if (artisteMessage === false) {
        //         notifyError("Cet artiste de musique existe déjà !");
        //     }
        //     return; // Prevent further execution of the function
        // }

        // Ensure that selectedUser is set based on the user's role

        // Create a FormData object to send the form data
        const formData = new FormData();
        formData.append('titre', titre);
        formData.append('duree', duration);
        formData.append('artiste', artiste);

        if (selectedOptions.length > 0) {
            for (let index = 0; index < selectedOptions.length; index++) {
                formData.append("radio", selectedOptions[index].value);
            }
        }

        const lyricsCheckUrl = `lyricsChecking/?titre=${titre}&artiste=${artiste}`;

        const lyricsResponse = await axiosClient.get(lyricsCheckUrl);
        const hasLyrics = lyricsResponse.data.hasLyrics;

        const lyrics = lyricsResponse.data;


        console.log("hasLyricshasLyricshasLyrics", lyrics);

        // Append hasLyrics field to the form data
        formData.append('hasLyrics', hasLyrics);
        formData.append('paroles', JSON.stringify(lyrics));  // Stringify JSON before appending to formData


        if (selectedMusique) {
            formData.append('musique', selectedMusique);
        }

        // Dispatch an action to start loading state
        dispatch(addMusiqueRequest(formData));

        // Send a POST request to add the radio
        axiosClient.post('musiques/', formData)
            .then(response => {
                setMusicLoad(false)
                notifySuccess("La musique a été ajoutée avec succès", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                // Handle success
                console.log('musique added successfully:', response.data);
                setmodal_center(false); // Close the modal
                dispatch(addMusiqueSuccess()); // Update loading state to false

                // Fetch updated radio data for the current page
                axiosClient.get(`musiques/?page=${page}`)
                    .then(response => {
                        setSelectedMusique()
                        setTitre("")
                        setArtiste("")
                        setDuration("")
                        setMessage("")
                        setSelectedOptions([])
                        setSelectedRadio()
                        dispatch(fetchMusiqueSuccess(response.data));
                        setTotalPages(Math.ceil(response.data.count / 10)); // Update total pages after adding a radio
                    })
                    .catch(error => {
                        setTitre("")
                        setArtiste("")
                        setSelectedOptions([])
                        setSelectedRadio()
                        setSelectedMusique()
                        dispatch(fetchMusiqueFailure(error.message));
                    });
            })
            .catch(error => {
                setMusicLoad(false)
                setSelectedMusique()
                setTitre("")
                setArtiste("")
                setSelectedOptions([])
                setSelectedRadio()
                // Log and handle error
                console.error('Error adding musique:', error);
                if (error.response) {
                    console.error('Error response status:', error.response.status);
                    console.error('Error response data:', error.response.data);
                    setMessage(error.response.data.error || 'Error adding radio');
                } else if (error.request) {
                    console.error('No response received:', error.request);
                    setMessage('No response received from the server.');
                } else {
                    console.error('Request setup error:', error.message);
                    setMessage('Error setting up request.');
                }

                // Handle error and update loading state
                dispatch(addMusiqueFailure(error.message));
            });
    };



    const handleMusicChange = (event) => {
        setSelectedMusicFile(event.target.files[0]);
    };

    const toggleShowFullFilename = () => {
        setShowFullFilename(!showFullFilename);
    };
    // console.log("nfff", nameMessage);


    const handlePlayMusic = (musiqueId, musicFile) => {
        if (playingMusic === musiqueId) {
            // Stop the music
            const audio = new Audio(musicFile);

            audio.pause();
            audio.currentTime = 0;
            setPlayingMusic(null);
        } else {
            // Play the music
            const audio = new Audio(musicFile);

            // audio.src = musicFile; // Set the audio source to the new music file
            audio.play();
            setPlayingMusic(musiqueId);
        }
    };




    const handleModalMdOpen = () => {
        setOpenMusicMd(true);
    }

    const handleModalMdClose = () => {
        setOpenMusicMd(false);
    }




    const handleDelete = (id) => {
        if (!id) return;
        axiosClient.delete(`/musiques/${id}/`)
            .then((response) => {
                axiosClient.get(`musiques/?page=${page}`) // Fetch updated radio data for the current page
                    .then(response => {
                        dispatch(fetchMusiqueSuccess(response.data));
                        setTotalPages(Math.ceil(response.data.count / 10)); // Update total pages after adding a radio
                    })
                    .catch(error => {
                        console.log(error)
                    });
                notifySuccess("La musique a été supprimée avec succès", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                toggleDelete()
                window.scrollTo(0, 0)
            })
            .catch((error) => {
                console.error(error);
            });
    }



    // let musicLink = ""
    //    


    const handleEditSubmit = async (id) => {


        if (selectedOptions.length === 0) {
            setMessage("Veuillez choisir la radio");
            setMusicLoadE(false)

            return;
        }

        setMusicLoadE(true)



        const formDataEdit = new FormData();

        if (titre) formDataEdit.append('titre', titre);
        if (artiste) formDataEdit.append('artiste', artiste);

        if (duration) formDataEdit.append('duree', duration);

        if (selectedMusique) formDataEdit.append('musique', selectedMusique);

        // if (selectedRadio) formDataEdit.append('radio', selectedRadio);

        if (selectedOptions.length > 0) {
            for (let index = 0; index < selectedOptions.length; index++) {
                formDataEdit.append("radio", selectedOptions[index].value);
            }
        }
        let lyricsCheckUrl;
        let lyricsResponse;
        let hasLyrics;

        if ((titre && artiste) || (musiqueData.titre && musiqueData.artiste)) {



            lyricsCheckUrl = `lyricsChecking/?titre=${titre ? titre : musiqueData.titre}&artiste=${artiste ? artiste : musiqueData.artiste}`;
            // alert(lyricsCheckUrl)
            lyricsResponse = await axiosClient.get(lyricsCheckUrl);
            hasLyrics = lyricsResponse.data.hasLyrics;
            formDataEdit.append('hasLyrics', hasLyrics)



            const lyrics = lyricsResponse.data;



            // Append hasLyrics field to the form data
            formDataEdit.append('paroles', JSON.stringify(lyrics));
            // alert(hasLyrics)
        }

        console.log("treredddddiid", formDataEdit);

        // Append hasLyrics field to the form data
        // if (hasLyrics) formDataEdit.append('hasLyrics', hasLyrics);

        const verificationMessage = await checkMusicAvailability(titre, artiste, musiqueData.id);
        // const artisteMessage = await check__musicA_availability(artiste);

        if (verificationMessage === true) {
            notifyError("Le titre et l'artiste de la musique existent déjà !");
            setMusicLoadE(false)

            return; // Prevent further execution of the function
        }




        axiosClient.patch(`musiques/${id}/`, formDataEdit)
            .then(response => {

                setMusicLoadE(false)
                // Handle success
                setmodal_center(false); // Close the modal
                axiosClient.get(`lyrics_music/?page=${page}&hasLyrics=${hasLyricsFilter}&searchTerm=${searchQuery}&radio=${selectedRadioFilter}`) // Fetch updated radio data for the current page
                    .then(response => {
                        notifySuccess("La musique a été modifiée  avec succès", {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        });
                        dispatch(fetchMusiqueSuccess(response.data));
                        setTotalPages(Math.ceil(response.data.count / 10)); // Update total pages after adding a radio


                        cleanUp()


                    })
                    .catch(error => {
                        console.log(error)
                        setSelectedMusique()

                        setTitre("")
                        setArtiste("")
                        setDuration("")

                        setSelectedRadio()
                        setSelectedOptions([])

                    });
            })
            .catch(error => {


                setTitre("")
                setArtiste("")
                setDuration("")

                setSelectedRadio()
                setSelectedOptions([])
                setMusicLoadE(false)

                notifyError("Erreur lors de la modification de la musique", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                console.error('Error editing musique:', error);
                if (error.response) {
                    console.error('Error response status:', error.response.status);
                    console.error('Error response data:', error.response.data);
                } else if (error.request) {
                    console.error('No response received:', error.request);
                } else {
                    console.error('Request setup error:', error.message);
                }

            });

    }


    console.log("the out durrrrrr", duration);




    const fetchMusiques = async (radioId, hasLyrics, searchTerm, page) => {
        setMusicL(true);
        try {
            const response = await axiosClient.get(`lyrics_music/`, {
                params: {
                    radio: radioId,
                    hasLyrics: hasLyrics,
                    searchTerm: searchTerm,
                    page: page
                }
            });





            dispatch(fetchMusiqueSuccess(response.data));
            setTotalPages(Math.ceil(response.data.count / 10));
            setPage(1)

        } catch (error) {
            console.error("There was an error fetching the musiques!", error);
        } finally {
            setMusicL(false);
        }
    };
    const handleRadioFilterChange = async (radioId, hasLyrics, searchTerm, page) => {
        fetchMusiques(radioId, hasLyrics, searchTerm, page);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };




    console.log("theFMusiques", musiques);
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const filteredMusiques = musiques && musiques.results ? musiques.results.filter((musique) =>
        musique.titre.toLowerCase().includes(searchQuery.toLowerCase()) ||
        musique.artiste.toLowerCase().includes(searchQuery.toLowerCase())
    ) : [];


    console.log("The musiques inside the table are  athe fallao ssin g ", filteredMusiques);




    const showLyricsDuration = async () => {


        setLyricsLoad(true)
        const lyricsCheckUrl = `lyricsChecking/?titre=${currentMusicTitle}&artiste=${currentMusicArtiste}`;

        try {
            const lyricsResponse = await axiosClient.get(lyricsCheckUrl);
            const lyrics = lyricsResponse.data.lyrics;

            if (lyrics && lyrics.length > 0) {
                const lastLyric = lyrics[lyrics.length - 1];
                console.log("lyricslyricslyrics", lastLyric?.lrc_timestamp);
                setLyricsTimeStamp(lastLyric?.lrc_timestamp)
                setLyricsLoad(false)

            } else {
                console.log("No lyrics available.");
                setLyricsTimeStamp("Cette Musique n'a pas de lyrics")
                setLyricsLoad(false)


            }
        } catch (error) {
            console.error("Error fetching lyrics:", error);
            setLyricsLoad(false)

        }
    };
    const clearInput = () => {
        setSearchQuery('');
        handleRadioFilterChange(selectedRadioFilter, hasLyricsFilter, '', page);
    };






    // useEffect(() => {
    //     const fetchMusiques = async () => {
    //         try {
    //             const params = {
    //                 hasLyrics: hasLyricsFilter, // Value from state
    //                 radio: selectedRadioFilter, // Value from state
    //                 searchTerm: searchQuery, // Value from state
    //             };

    //             const response = await axiosClient.get('get_all_musics__/', { params });
    //             // setMusiques__(response.data.musique);
    //             setMLoad(false);
    //         } catch (error) {
    //             console.error('Error fetching musiques:', error);
    //             setError(true);
    //             setMLoad(false);
    //         }
    //     };

    //     fetchMusiques();
    // }, [searchQuery, selectedRadioFilter, hasLyricsFilter]);

    console.log("musiques__", musiques__);
    console.log("searchQueryATHELL", searchQuery);



    console.log("The musics are loaded ", mLoad);

    const handleExport = async () => {
        setM_Load(true); // Start loading process
        toggleExport();

        try {
            const params = {
                hasLyrics: hasLyricsFilter,
                radio: selectedRadioFilter,
                searchTerm: searchQuery,
            };

            const response = await axiosClient.get('get_all_musics__/', { params });
            const musiques__ = response.data.musique;

            if (musiques__.length === 0) {
                notifyError("Echec de téléchargement, veuillez sélectionner des musiques!");
                setM_Load(false);
                return;
            }

            const chunkSize = 200; // Number of files per ZIP chunk
            const totalChunks = Math.ceil(musiques__.length / chunkSize);

            let exportedCount = 0;

            // Function to create and download a ZIP chunk
            const createAndDownloadZip = async (startIndex, endIndex, chunkNumber) => {
                const zip = new JSZip();
                const folder = zip.folder("musiques");

                // Add files to the current ZIP chunk
                for (let i = startIndex; i < endIndex; i++) {
                    const musique = musiques__[i];
                    try {
                        const musiqueResponse = await fetch(musique.musique);
                        const blob = await musiqueResponse.blob();
                        const fileName = `${musique.titre}_${musique.artiste || 'unknown'}`;
                        folder.file(`${fileName}.mp3`, blob);

                        exportedCount++;
                        const progressPercent = Math.round((exportedCount / musiques__.length) * 100);
                        setProgress(progressPercent); // Update progress bar

                    } catch (error) {
                        console.error('Error exporting musique:', error);
                        setError('Failed to export musiques.');
                    }
                }

                // Generate and download the ZIP chunk
                zip.generateAsync({ type: 'blob' }).then((content) => {
                    saveAs(content, `musiques_part_${chunkNumber}.zip`);
                    if (chunkNumber === totalChunks) {
                        setM_Load(false); // Set loading to false after all downloads
                        setProgress(0); // Reset progress bar
                        notifySuccessDownload("Téléchargement terminé.");
                    }
                });
            };

            // Loop through the musiques and create ZIP chunks
            for (let i = 0; i < totalChunks; i++) {
                const startIndex = i * chunkSize;
                const endIndex = Math.min((i + 1) * chunkSize, musiques__.length);
                await createAndDownloadZip(startIndex, endIndex, i + 1); // Wait for each chunk to be processed
            }

        } catch (error) {
            console.error('Error fetching musiques:', error);
            setError('Failed to fetch musiques.');
            setM_Load(false); // Set loading to false on error
        }
    };

    console.log("searchQuerysearchQuerysearchQuerysearchQuery", searchQuery);
    // const handleExport = async () => {
    //     setM_Load(true); // Start loading process
    //     toggleExport();

    //     try {
    //         const params = {
    //             hasLyrics: hasLyricsFilter, // Value from props
    //             radio: selectedRadioFilter, // Value from props
    //             searchTerm: searchQuery, // Value from props
    //         };

    //         const response = await axiosClient.get('get_all_musics__/', { params });
    //         const musiques__ = response.data.musique;

    //         if (musiques__.length === 0) {
    //             notifyError("Echec de téléchargement, veuillez sélectionner des musiques!");
    //             setM_Load(false); // Stop loading process
    //             return;
    //         }

    //         // Prepare for compression with Pako
    //         let exportedCount = 0;
    //         let compressedMusiqueData = [];

    //         for (const musique of musiques__) {
    //             try {
    //                 const musiqueResponse = await fetch(musique.musique);
    //                 const blob = await musiqueResponse.blob();
    //                 const fileName = `${musique.titre}_${musique.artiste || 'unknown'}`;

    //                 // Convert blob to ArrayBuffer (required by Pako for compression)
    //                 const arrayBuffer = await blob.arrayBuffer();

    //                 // Compress the ArrayBuffer using Pako
    //                 const compressedData = pako.deflate(new Uint8Array(arrayBuffer));

    //                 // Store compressed data
    //                 compressedMusiqueData.push({ fileName, compressedData });

    //                 exportedCount++;
    //                 const progressPercent = Math.round((exportedCount / musiques__.length) * 100);
    //                 setProgress(progressPercent); // Update progress bar
    //             } catch (error) {
    //                 console.error('Error exporting musique:', error);
    //                 setError('Failed to export musiques.');
    //             }
    //         }

    //         // After compressing all musiques, start creating the downloadable `.gz` file
    //         const compressedBlob = new Blob(compressedMusiqueData.map(item => {
    //             const header = new TextEncoder().encode(item.fileName + '\n'); // Add file name header for identification
    //             const compressedBuffer = item.compressedData; // Get compressed data from Pako
    //             return new Uint8Array([...header, ...compressedBuffer]); // Combine file name and compressed data
    //         }));

    //         // Trigger the download as a .gz file
    //         const downloadPromise = saveAs(compressedBlob, "musiques_compressed.gz");

    //         if (downloadPromise && downloadPromise.then) { // Check if promise is returned
    //             downloadPromise.then(() => {
    //                 setM_Load(false); // Set loading to false after download
    //                 setProgress(0); // Reset progress bar
    //                 notifySuccessDownload("Téléchargement terminé."); // Show success notification
    //             });
    //         } else {
    //             console.log("saveAs() did not return a promise.");
    //             // Fallback behavior, if needed
    //             setM_Load(false); // Set loading to false after download
    //             setProgress(0); // Reset progress bar
    //             notifySuccessDownload("Téléchargement terminé."); // Show success notification (fallback)
    //         }

    //     } catch (error) {
    //         console.error('Error fetching musiques:', error);
    //         setError('Failed to fetch musiques.');
    //         setM_Load(false); // Set loading to false on error
    //     }
    // };

    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };
    const showRealtimeLyricsDUration = async () => {
        try {
            if (selectedMusique) {
                const audio = new Audio(URL.createObjectURL(selectedMusique));

                audio.addEventListener('loadedmetadata', () => {
                    const durationInSeconds = audio.duration;
                    console.log("durationInSeconds", durationInSeconds);
                    setDuration(durationInSeconds);
                    // You can set this formatted duration in your state or perform other actions with it
                });

                audio.addEventListener('error', (err) => {
                    console.error('Error loading audio:', err);
                    setDuration("00:00:00");
                    // Handle specific error scenarios here, such as CORS issues or network problems
                });

                audio.load();
            } else {
                console.error('Selected musique is not defined.');
            }
        } catch (error) {
            console.error("Error fetching audio duration:", error);
            // Handle generic error scenarios here
        }
    };

    // useEffect to trigger showRealtimeLyricsDuration automatically when selectedMusique changes
    useEffect(() => {
        if (selectedMusique) {
            showRealtimeLyricsDUration();
        }
    }, [selectedMusique]); // useEffect will re-run whenever selectedMusique changes




    console.log("lyricsRealTimelyricsRealTimelyricsRealTime", lyricsRealTime);


    const cleanUp = () => {
        setSelectedMusique()
        setTitre("")
        setArtiste("")
        setDuration("")
        setSelectedRadio()
        tog_center()
        setMessage("")
        setSelectedOptions([])




    }
    const [exportedMusics, setExportedMusics] = useState([]);

    const [dataToExport, setDataToExport] = useState([]);
    const [csvLoad, setCsvLoad] = useState(false);
    const [csvProgress, setCsvProgress] = useState(0);
    // Fetch data and ensure it returns an array
    const fetchData = async () => {
        try {
            const params = {
                hasLyrics: hasLyricsFilter,
                radio: selectedRadioFilter,
                searchTerm: searchQuery,
            };

            const response = await axiosClient.get('get_all_musics__/', { params });

            // Ensure response.data.musique is an array
            return Array.isArray(response.data.musique) ? response.data.musique : [];
        } catch (error) {
            console.error('Error fetching data:', error);
            return []; // Return an empty array in case of error
        }
    };



    const [exportedItemsCount, setExportedItemsCount] = useState(0);


    const prepareData = async () => {
        setCsvLoad(true); // Start loading
        setCsvProgress(0); // Reset progress to 0 at the start

        const exportedMusics = await fetchData(); // Fetch data and wait for it
        console.log("the final exported data", exportedMusics);

        if (!Array.isArray(exportedMusics)) {
            console.error('Expected an array but received:', exportedMusics);
            setCsvLoad(false);
            return;
        }

        const totalItems = exportedMusics.length;
        setExportedItemsCount(totalItems)
        const progressIncrement = totalItems ? 100 / totalItems : 0;

        // Use a local variable to store the prepared data
        let preparedData = [];

        for (let i = 0; i < exportedMusics.length; i++) {
            const { id, paroles, date_creation, hasLyrics, radioNames, musique, duree, ...rest } = exportedMusics[i];

            // Convert seconds to hh:mm:ss format
            const hours = Math.floor(duree / 3600);
            const minutes = Math.floor((duree % 3600) / 60);
            const seconds = duree % 60;

            // Map radio IDs to titles
            const radioInfo = radios && radios.find(r => r.id === parseInt(selectedRadioFilter));
            const rTitre = radioInfo && radioInfo.name;

            // Increment progress
            setCsvProgress(prevProgress => {
                const newProgress = Math.min(prevProgress + progressIncrement, 100);
                if (newProgress >= 100) {
                    // Ensure progress is capped at 100%
                    return 100;
                }
                return newProgress;
            });

            preparedData.push({
                ...rest,
                hasLyrics: hasLyrics ? 'oui' : 'non',
                radioNames: selectedRadioFilter ? rTitre : radioNames,
                duree: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
            });

            // Optional: Add a slight delay to allow the UI to update (adjust delay as needed)
            await new Promise(resolve => setTimeout(resolve, 10));
        }

        setDataToExport(preparedData);
        setCsvLoad(false); // End loading
        setCsvProgress(100); // Ensure progress reaches 100%
    };

    const handleButtonClick = async () => {
        setMLoad(true);
        await prepareData();
        setMLoad(false);
    };


    console.log("exportedItemsCount", exportedItemsCount)
    let prevExpModalCsv = expModalCsv;


    console.log("csv progress ,", csvProgress);

    const headers = [
        { label: 'Artiste', key: 'artiste' },
        { label: 'Title', key: 'titre' },
        { label: 'radioNames', key: 'radioNames' },


        // { label: 'Date Creation', key: 'date_creation' },
        // { label: 'Duration', key: 'duree' },
        // { label: 'Has Lyrics', key: 'hasLyrics' },
        // { label: 'Musique', key: 'musique' },
    ];

    // const dataToExport = prepareData();

    // const handleButtonClick = async () => {
    //     setCsvLoad(true);
    //     await prepareData();
    //     setCsvLoad(false);
    // };


    console.log("prepareDataprepareData", dataToExport);



    console.log("filteredMusiquesIA1B", filteredMusiques);
    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={400}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition="bounce"
            />

            <Row className="icons-demo-content">
                <Col xs="12">

                    <Card>
                        <CardBody>
                            <h4 className="card-title" style={{ fontWeight: 'bold' }}>Gestion Musiques</h4>

                            <Row className="mb-3 align-items-center">

                                <Col lg={2}>
                                    <FormGroup>
                                        <TextField

                                            sx={{
                                                minWidth: '200px',
                                                height: '50px', // Change height here
                                                '& .MuiOutlinedInput-root': {
                                                    height: '35px', // Change height here
                                                    marginRight: "10px",
                                                    marginTop: "8px",
                                                    borderRadius: '5px', // Optional: Rounded corners
                                                    '& input': {
                                                        // padding: '10px 14px', // Adjust the padding to fit your design
                                                    },
                                                },
                                            }}
                                            type="text"
                                            placeholder="Chercher..."
                                            value={searchQuery}
                                            onChange={(e) => {
                                                setSearchQuery(e.target.value)
                                                handleRadioFilterChange(selectedRadioFilter, hasLyricsFilter, e.target.value, 1)
                                            }} variant="outlined"
                                            // fullWidth


                                            InputProps={{
                                                endAdornment: searchQuery && (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={clearInput} edge="end">
                                                            <AiOutlineClose />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}


                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg={2}>
                                    <Form>
                                        <FormGroup className="d-flex">
                                            <Input
                                                type="select"
                                                name="radioFilter"
                                                id="radioFilter"
                                                value={selectedRadioFilter}
                                                onChange={(e) => {
                                                    setSelectedRadioFilter(e.target.value)
                                                    handleRadioFilterChange(e.target.value, hasLyricsFilter, searchQuery, 1)
                                                }}
                                            >
                                                <option value="">Toutes les radios</option>
                                                {/* Iterate over available radios and generate options */}
                                                {radios && Array.isArray(radios) && radios.map(radio => (
                                                    <option key={radio.id} value={radio.id}>{radio.name}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col lg={2}>
                                    <Form>
                                        <FormGroup className="d-flex">
                                            <Input
                                                type="select"
                                                name="hasLyricsFilter"
                                                id="hasLyricsFilter"
                                                value={hasLyricsFilter}
                                                onChange={(e) => {
                                                    setHasLyricsFilter(e.target.value)
                                                    handleRadioFilterChange(selectedRadioFilter, e.target.value, searchQuery, 1)
                                                }}

                                            >
                                                <option value="">Toutes les musiques</option>
                                                <option value="true">Avec Lyrics</option>
                                                <option value="false">Sans Lyrics</option>
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>


                            <Row className="mb-1">
                                <Col lg={12} className="d-flex justify-content-end">
                                    <div>


                                        {m_Load ? (



                                            <button
                                                style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}
                                                type="button"
                                                onClick={handleExport}
                                                className="btn btn-primary waves-effect waves-light"
                                                data-toggle="modal"
                                                data-target="#myModal"
                                                disabled={m_Load}
                                            >
                                                <div style={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>


                                                    {
                                                        progress === 100 ? (
                                                            <CircularProgress
                                                                variant="indeterminate"
                                                                style={{ color: "white", marginRight: '10px' }}
                                                            />


                                                            // <span
                                                            //     class="spinner-border spinner-border-sm"
                                                            //     role="status"
                                                            //     aria-hidden="true"
                                                            // ></span>

                                                        ) : (
                                                            <>
                                                                <CircularProgress
                                                                    variant="determinate"
                                                                    value={progress}
                                                                    style={{ marginRight: '10px' }}
                                                                />
                                                                <Typography
                                                                    variant="caption"
                                                                    component="div"
                                                                    color="textSecondary"
                                                                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                                                >
                                                                    {`${Math.round(progress)}%`}
                                                                </Typography></>
                                                        )
                                                    }

                                                </div>
                                                {
                                                    progress === 100 ? (
                                                        <span style={{ marginLeft: '5px', fontSize: "10px" }}>En cours de téléchargement...</span>
                                                    ) : (
                                                        <span style={{ marginLeft: '5px', fontSize: "10px" }}>Exporting...</span>
                                                    )
                                                }                                            </button>

                                        ) : (
                                            <>
                                                {/* <button
                                                    style={{ marginRight: '10px' }}
                                                    type="button"
                                                    onClick={() => { updatingMusiqueDuree() }}
                                                    className="btn btn-primary waves-effect waves-light"
                                                    data-toggle="modal"
                                                    data-target="#myModal"
                                                    disabled={m_Load}
                                                >
                                                    <i style={{ fontSize: '17px', marginRight: '5px' }} className="mdi  mdi-file" />
                                                    Correcting....
                                                </button> */}



                                                {csvLoad ? (<button
                                                    style={{ marginRight: '10px', height: "60px" }}
                                                    type="button"
                                                    onClick={toggleExport}
                                                    className="btn btn-primary waves-effect waves-light"
                                                    data-toggle="modal"
                                                    data-target="#myModal"
                                                    disabled={m_Load}
                                                >
                                                    <i style={{ fontSize: '17px', marginRight: '5px' }} className="mdi  mdi-download" />
                                                    Télécharger les musiques
                                                </button>)

                                                    :

                                                    (<button
                                                        style={{ marginRight: '10px' }}
                                                        type="button"
                                                        onClick={toggleExport}
                                                        className="btn btn-primary waves-effect waves-light"
                                                        data-toggle="modal"
                                                        data-target="#myModal"
                                                        disabled={m_Load}
                                                    >
                                                        <i style={{ fontSize: '17px', marginRight: '5px' }} className="mdi  mdi-download" />
                                                        Télécharger les musiques
                                                    </button>)
                                                }



                                            </>

                                        )}

                                        {loading && (
                                            <div className="progress mt-3">
                                                <div
                                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                                    role="progressbar"
                                                    aria-valuenow={progress}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                    style={{ width: `${progress}%` }}
                                                >
                                                    {progress}%
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <button


                                        type="button"
                                        onClick={() => {
                                            tog_center();
                                            setChangeModal(true)
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                    >
                                        <i style={{ fontSize: '17px', marginRight: "5px" }} className="mdi mdi-music" />
                                        Ajouter une musique
                                    </button>

                                    {csvLoad ? (

                                        <button
                                            style={{ display: 'flex', alignItems: 'center', marginRight: '10px', marginLeft: '10px' }}
                                            type="button"
                                            onClick={toggleExportCsv}
                                            className="btn btn-primary waves-effect waves-light"
                                            data-toggle="modal"
                                            data-target="#myModal"
                                            disabled={csvLoad || isExporting}
                                        >
                                            <div style={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
                                                <CircularProgress
                                                    variant="determinate"
                                                    value={csvProgress}
                                                    style={{ marginRight: '10px' }}
                                                />
                                                <Typography
                                                    variant="caption"
                                                    component="div"
                                                    color="textSecondary"
                                                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                                >
                                                    {`${Math.round(csvProgress)}%`}
                                                </Typography>
                                            </div>
                                            <span style={{ marginLeft: '5px', fontSize: "10px" }}>chargement ...</span>
                                        </button>

                                    )

                                        : (


                                            <button
                                                disabled={csvLoad || isExporting}

                                                style={{ marginRight: '10px', marginLeft: '10px' }}
                                                type="button"
                                                onClick={toggleExportCsv}
                                                className="btn btn-primary waves-effect waves-light"
                                                data-toggle="modal"
                                                data-target="#myModal"
                                            >
                                                <AiOutlineFileExcel style={{ fontSize: '17px', marginRight: '5px' }} />
                                                Exporter en csv
                                            </button>


                                        )}

                                    < MImport />
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12}>
                                    <div className="card">
                                        <div className="card-body">

                                            {musicL ?



                                                (
                                                    <Lottie options={defaultOptions} height={120} width={120} />

                                                ) :
                                                (

                                                    <div style={{ maxHeight: "400px", overflowY: "auto" }} className="table-responsive">
                                                        <Table responsive className="table-primary table-striped mb-0" style={{ fontSize: '0.6rem', marginBottom: '0' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Titre</th>
                                                                    <th>Artiste</th>
                                                                    <th>lien musique</th>
                                                                    <th>Durée</th>
                                                                    <th>Radio</th>
                                                                    <th>Lyrics</th>
                                                                    <th>Options</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {filteredMusiques.length === 0 ? (
                                                                    <tr>
                                                                        <td colSpan="8">No musics found.</td>
                                                                    </tr>
                                                                ) : (
                                                                    filteredMusiques.map(musique => {
                                                                        const truncatedText = musique.musique.length > 40 ? musique.musique.substring(0, 40) + '...' : musique.musique;
                                                                        const truncatedRadioList = musique.radioNames.length > 1 ?



                                                                            musique.radioNames[0] + ' ...' : musique.radioNames[0];

                                                                        return (
                                                                            <tr key={musique.id} style={{ height: '0.2em' }}> {/* Adjust the height here */}
                                                                                <th style={{ fontSize: '0.6rem' }} scope="row">{musique.id}</th>
                                                                                <td style={{ fontSize: '0.6rem', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }}>{musique.titre}</td>
                                                                                <td style={{ fontSize: '0.6rem', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }}>{musique.artiste}</td>
                                                                                <td style={{ fontSize: '0.6rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                                    <div style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}>
                                                                                        <FaMusic style={{ marginRight: '5px' }} />
                                                                                        {musique.musique.length > 40 ? (
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                overlay={<Tooltip id={`tooltip-${musique.musique}`}>{musique.musique}</Tooltip>}
                                                                                            >
                                                                                                <span style={{ cursor: 'pointer' }}>
                                                                                                    {truncatedText}
                                                                                                </span>
                                                                                            </OverlayTrigger>
                                                                                        ) : (
                                                                                            truncatedText
                                                                                        )}
                                                                                    </div>
                                                                                </td>

                                                                                <td style={{
                                                                                    fontSize: '0.6rem', textOverflow: "ellipsis", overflow: 'hidden',


                                                                                    whiteSpace: "nowrap"
                                                                                }}>{formatDuration(musique.duree)}</td>

                                                                                <td style={{ fontSize: '0.6rem', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }}>


                                                                                    {/* <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip id={`tooltip-${musique.musique}`}>






                                                                                            {musique.radioNames.map((radioName, index) => (
                                                                                                <li key={index} style={{ textAlign: 'left' }}>{radioName}</li>
                                                                                            ))}</Tooltip>}
                                                                                    >
                                                                                        <span style={{ cursor: 'pointer' }}>
                                                                                            <FaRadio
                                                                                                style={{ fontSize: "12px", cursor: "pointer" }}
                                                                                            // onClick={() => window.open(`/home/${radio.id}`, '_blank')}


                                                                                            />
                                                                                        </span>
                                                                                    </OverlayTrigger> */}


                                                                                    {musique.radioNames.length > 1 ? (
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={<Tooltip
                                                                                                id={`tooltip-${musique.musique}`}>   {musique.radioNames.map((radioName, index) => (
                                                                                                    <li key={index} style={{ textAlign: 'left' }}>{radioName}</li>
                                                                                                ))}</Tooltip>}
                                                                                        >
                                                                                            <span style={{ cursor: 'pointer' }}>
                                                                                                {truncatedRadioList}
                                                                                            </span>
                                                                                        </OverlayTrigger>
                                                                                    ) : (
                                                                                        truncatedRadioList
                                                                                    )}



                                                                                </td>
                                                                                <td style={{ fontSize: '0.6rem', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }}>



                                                                                    {musique.hasLyrics ? <MdCheckCircle color="green" size={12} /> : <MdClose color="red" size={12} />}</td>
                                                                                <td style={{ fontSize: '0.6rem', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }}>
                                                                                    {/* Add your buttons here */}

                                                                                    <Button onClick={() => {
                                                                                        setChangeModal(false)
                                                                                        tog_center();
                                                                                        setMusiqueData(musique)
                                                                                        setMusiqueId(musique.id);
                                                                                        handleRadioChange(radioData.filter(op => musique.radio.includes(op.value)))
                                                                                        console.log("test", musiqueData.titre);
                                                                                    }}


                                                                                        style={{ padding: '0.2rem 0.4rem', fontSize: '0.4rem' }} // Adjust dimensions here

                                                                                        variant="outline-primary">Modifier</Button>{' '}
                                                                                    <Button onClick={() => {
                                                                                        setMusicLink(musique.musique)
                                                                                        setCurrentMusicTitle(musique.titre)
                                                                                        setCurrentMusicArtiste(musique.artiste)
                                                                                        toggleLaunch()
                                                                                    }}
                                                                                        style={{ padding: '0.2rem 0.4rem', fontSize: '0.4rem' }} // Adjust dimensions here


                                                                                        variant='success'><MdPlayArrow fontSize={'10px'} /></Button>{' '}
                                                                                    <Button onClick={() => {
                                                                                        toggleDelete()
                                                                                        setMusiqueId(musique.id);
                                                                                    }}




                                                                                        style={{ padding: '0.2rem 0.4rem', fontSize: '0.4rem' }} // Adjust dimensions here


                                                                                        variant='danger'><IoTrash fontSize={'10px'} /></Button>{' '}

                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                )}
                                                            </tbody>
                                                        </Table>


                                                    </div>
                                                )


                                            }


                                        </div>
                                    </div>
                                </Col>


                            </Row>
                            <Row>

                                <Col lg={6}>
                                    <p style={{ fontSize: "16px", marginLeft: "10px", fontWeight: 'bold' }}>Total  : {musiques && musiques.count}</p>
                                    {/* Place any other content you want to display */}
                                </Col>
                                <Col lg={6} className="d-flex justify-content-end">
                                    <Stack spacing={2}>
                                        <Pagination count={totalPages}

                                            color="secondary" page={page} onChange={handlePageChange} />
                                    </Stack>
                                </Col>
                            </Row>

                        </CardBody>






                        {changeModal ?


                            <Modal
                                size='lg'
                                isOpen={modal_center}

                                toggle={() => {
                                    cleanUp()
                                }}
                                centered={true}
                            >


                                <ModalHeader style={{ fontSize: "20px" }} toggle={() => {
                                    cleanUp()
                                    // setSelectedImage()
                                    // setSelectedImg()
                                    // setImage()
                                }}>
                                    <h3>Ajouter une musique</h3>


                                </ModalHeader>

                                <ModalBody className='p-4 mx-2'>


                                    <form encType="multipart/form-data">
                                        <Row>
                                            <Col style={{ alignContent: "center" }} lg="3">

                                                <Media className='mb-3' style={{ justifyContent: 'center', alignItems: "center" }}>
                                                    <div className="position-relative" style={{ width: 150, height: 150 }}>
                                                        <Avatar
                                                            style={{ objectFit: "cover", borderRadius: "5%" }}
                                                            sx={{ width: 150, height: 150 }}
                                                            src={"https://i.ibb.co/G5f5H9h/upmusic.png"}
                                                            variant="square"
                                                        />
                                                        <label htmlFor="musiqueInput" className="position-absolute" style={{
                                                            cursor: 'pointer',
                                                            bottom: -15,
                                                            right: -10,
                                                            border: "3px solid white",
                                                            borderRadius: "50%"
                                                        }}>
                                                            <input
                                                                id="musiqueInput"
                                                                type="file"
                                                                name='Musique'
                                                                accept="audio/*"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => {
                                                                    setSelectedMusique(e.target.files[0]);
                                                                }}
                                                            />
                                                            <div className="d-flex justify-content-center align-items-center"
                                                                style={{ backgroundColor: '#232939', borderRadius: '50%', width: 30, height: 30 }}>
                                                                <FaMusic onClick={showRealtimeLyricsDUration} size={15} color='#fff' />
                                                            </div>
                                                        </label>
                                                        {selectedMusique && (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        {selectedMusique.name}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginTop: "17px",
                                                                        cursor: 'pointer',
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'hidden',
                                                                        whiteSpace: 'nowrap',
                                                                        width: '150px'
                                                                    }}
                                                                > <FaMusic />&nbsp;&nbsp;&nbsp;
                                                                    {selectedMusique.name.length > 20
                                                                        ? selectedMusique.name.substring(0, 20) + '...'
                                                                        : selectedMusique.name}
                                                                </div>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>

                                                </Media>
                                            </Col>
                                            <Col style={{ display: 'flex', justifyContent: "center" }} lg="1">
                                                <Divider style={{ borderColor: "gray" }} orientation="vertical" variant="middle" />
                                            </Col>


                                            <Col lg="8">

                                                {message &&
                                                    <Alert variant={"danger"}>
                                                        {message}
                                                    </Alert>
                                                }
                                                <Row className="mb-3">
                                                    <label htmlFor="name" className="col-md-3 col-form-label">Titre musique</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            required
                                                            style={{ border: nameMessageError ? '2px solid red' : '' }}
                                                            className="form-control "
                                                            type="text"
                                                            id="titre"
                                                            name="titre"
                                                            value={titre}
                                                            onChange={e => setTitre(e.target.value)}
                                                        />
                                                        {nameMessageError &&
                                                            <p style={{ color: 'red' }}> {nameMessageError}</p>
                                                        }
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label htmlFor="URL" className="col-md-3 col-form-label">Artiste musique</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            required
                                                            className="form-control"
                                                            type="text"
                                                            id="URL"
                                                            name="artiste"
                                                            value={artiste}
                                                            onChange={e => setArtiste(e.target.value)}
                                                        />
                                                    </div>
                                                </Row>




                                                <Row className="mb-3">
                                                    <label htmlFor="URL" className="col-md-3 col-form-label">Duree musique</label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Input
                                                                required
                                                                className="form-control"
                                                                type="text"
                                                                id="URL"
                                                                name="artiste"
                                                                value={formatDuration(duration)}
                                                                // disabled={true}
                                                                onChange={e => setDuration(e.target.value)}
                                                            />
                                                            <InputGroupText>
                                                                <i
                                                                    style={{ cursor: 'pointer', fontSize: "22px" }} className="mdi mdi-clock"></i>

                                                            </InputGroupText>
                                                        </InputGroup>
                                                    </div>
                                                </Row>

                                                {/* <Row className="mb-3">
                                                    <label htmlFor="image" className="col-md-3 col-form-label">Image radio</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            required
                                                            className="form-control"
                                                            type="file"
                                                            id="image"
                                                            name="logo"
                                                            onChange={handleFileChange}
                                                        />
                                                    </div>
                                                </Row> */}


                                                <Row className="mb-3">
                                                    <label htmlFor="userType" className="col-md-3 col-form-label">Radio </label>
                                                    <div className="col-md-9">
                                                        {/* <select
                                                            required
                                                            className="form-control"
                                                            id="userType"
                                                            name="user"
                                                            value={selectedRadio}
                                                            onChange={e => setSelectedRadio(e.target.value)}
                                                        >
                                                            <option value="">Selectionner une radio</option> 
                                                            {radios && Array.isArray(radios) && radios.map(radio => (
                                                                <option key={radio.id} value={radio.id}>{radio.name} </option>
                                                            ))}

                                                        </select>
                                                        */}
                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            components={animatedComponents}
                                                            value={selectedOptions}
                                                            onChange={handleRadioChange}
                                                            isMulti
                                                            options={radioData}
                                                            placeholder="Selectionnez radios"
                                                        />
                                                    </div>
                                                </Row>


                                            </Col>
                                        </Row>

                                    </form>
                                </ModalBody>



                                <ModalFooter>
                                    <button
                                        disabled={musicLoad}

                                        type="button"
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                    >
                                        <i style={{ fontSize: '17px', marginRight: "5px" }} className="mdi mdi-music" />
                                        {!musicLoad ? (
                                            <span>                                        Ajouter une musique
                                            </span>
                                        ) : (
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                    </button>
                                </ModalFooter>
                            </Modal>


                            :

                            <Modal
                                size='lg'
                                isOpen={modal_center}
                                toggle={() => {
                                    cleanUp()
                                }}
                                centered={true}
                            >


                                <ModalHeader style={{ fontSize: "20px" }} toggle={() => {
                                    cleanUp()
                                }}>
                                    <h3>Modifier la musique</h3>


                                </ModalHeader>

                                <ModalBody className='p-4 mx-2'>


                                    <form encType="multipart/form-data">

                                        <Row>
                                            <Col style={{ alignContent: "center" }} lg="3">

                                                <Media className='mb-3' style={{ justifyContent: 'center', alignItems: "center" }}>
                                                    <div className="position-relative" style={{ width: 150, height: 150 }}>
                                                        <Avatar
                                                            style={{ objectFit: "cover", borderRadius: "5%" }}
                                                            sx={{ width: 150, height: 150 }}
                                                            src={"https://i.ibb.co/G5f5H9h/upmusic.png"}
                                                            variant="square"
                                                        />
                                                        <label htmlFor="musiqueInput" className="position-absolute" style={{
                                                            cursor: 'pointer',
                                                            bottom: -15,
                                                            right: -10,
                                                            border: "3px solid white",
                                                            borderRadius: "50%"
                                                        }}>
                                                            <input
                                                                id="musiqueInput"
                                                                type="file"
                                                                name='Musique'
                                                                accept="audio/*"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => {
                                                                    setSelectedMusique(e.target.files[0]);
                                                                }}
                                                            />
                                                            <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#232939', borderRadius: '50%', width: 30, height: 30 }}>
                                                                <FaMusic onClick={showRealtimeLyricsDUration} size={15} color='#fff' />
                                                            </div>
                                                        </label>
                                                        {(selectedMusique || musiqueData.musique) && (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        {selectedMusique ? selectedMusique.name : musiqueData.musique}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginTop: "17px",
                                                                        cursor: 'pointer',
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'hidden',
                                                                        whiteSpace: 'nowrap',
                                                                        width: '150px'
                                                                    }}
                                                                > <FaMusic />&nbsp;&nbsp;&nbsp;
                                                                    {(selectedMusique && selectedMusique.name.length > 20)
                                                                        ? selectedMusique.name.substring(0, 20) + '...'
                                                                        : selectedMusique
                                                                            ? selectedMusique.name
                                                                            : musiqueData.musique.length > 20
                                                                                ? musiqueData.musique.substring(0, 20) + '...'
                                                                                : musiqueData.musique}
                                                                </div>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>

                                                </Media>
                                            </Col>
                                            <Col style={{ display: 'flex', justifyContent: "center" }} lg="1">
                                                <Divider style={{ borderColor: "gray" }} orientation="vertical" variant="middle" />
                                            </Col>


                                            <Col lg="8">
                                                {message &&
                                                    <Alert variant={"danger"}>
                                                        {message}
                                                    </Alert>
                                                }
                                                <Row className="mb-3">
                                                    <label htmlFor="name" className="col-md-3 col-form-label">Titre musique</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            required
                                                            style={{ border: nameMessageError ? '2px solid red' : '' }}
                                                            className="form-control "
                                                            type="text"
                                                            id="titre"
                                                            name="titre"
                                                            defaultValue={musiqueData.titre}
                                                            onChange={e => setTitre(e.target.value)}
                                                        />
                                                        {nameMessageError &&
                                                            <p style={{ color: 'red' }}> {nameMessageError}</p>
                                                        }
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label htmlFor="URL" className="col-md-3 col-form-label">Artiste musique</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            required
                                                            className="form-control"
                                                            type="text"
                                                            id="URL"
                                                            name="artiste"
                                                            defaultValue={musiqueData.artiste}
                                                            onChange={e => setArtiste(e.target.value)}
                                                        />
                                                    </div>
                                                </Row>


                                                <Row className="mb-3">
                                                    <label htmlFor="URL" className="col-md-3 col-form-label">Duree musique</label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Input
                                                                required
                                                                className="form-control"
                                                                type="text"
                                                                id="URL"
                                                                name="artiste"
                                                                value={duration ? formatDuration(duration) : formatDuration(musiqueData.duree)}
                                                                // disabled={true}
                                                                onChange={e => setDuration(e.target.value)}
                                                            />
                                                            <InputGroupText>
                                                                <i onClick={showRealtimeLyricsDUration}
                                                                    style={{ cursor: 'pointer', fontSize: "22px" }} className="mdi mdi-clock"></i>

                                                            </InputGroupText>
                                                        </InputGroup>
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label htmlFor="userType" className="col-md-3 col-form-label">Radio </label>
                                                    <div className="col-md-9">
                                                        {/* <select
                                                            required
                                                            className="form-control"
                                                            id="userType"
                                                            name="user"
                                                            defaultValue={musiqueData.radio}
                                                            onChange={e => setSelectedRadio(e.target.value)}
                                                        >
                                                            <option value="">Selectionner une radio</option>
                                                            {radios && Array.isArray(radios) && radios.map(radio => (
                                                                <option key={radio.id} value={radio.id}>{radio.name} </option>
                                                            ))}

                                                        </select> */}
                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            components={animatedComponents}
                                                            defaultValue={radioData.filter(op => musiqueData.radio.includes(op.value))}
                                                            onChange={handleRadioChange}
                                                            isMulti
                                                            options={radioData}
                                                            placeholder="Selectionnez radios"
                                                        />
                                                    </div>
                                                </Row>




                                            </Col>
                                        </Row>

                                    </form>
                                </ModalBody>



                                <ModalFooter>
                                    <button
                                        type="button"
                                        disabled={musicLoadE}
                                        onClick={() => {

                                            handleEditSubmit(musiqueId);
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                    >
                                        <i style={{ fontSize: '17px', marginRight: "5px" }}


                                            className="mdi mdi-music" />

                                        {!musicLoadE ? (


                                            <span>                                        Modifier la musique
                                            </span>
                                        ) : (
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}

                                    </button>
                                </ModalFooter>
                            </Modal>
                        }








                        <Modal isOpen={expModal} toggle={toggleExport}>
                            <ModalHeader toggle={toggleExport}>Exporter</ModalHeader>
                            <ModalBody>
                                êtes-vous sûr ? Vous allez Télécharger {musiques && musiques.count}  musiques .
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleExport} variant='secondary'>
                                    Annuler
                                </Button>{' '}
                                <Button onClick={() => handleExport()} variant='primary' >
                                    <AiOutlineDownload style={{ fontSize: '17px', marginRight: '5px' }} />

                                    Télécharger
                                </Button>
                            </ModalFooter>
                        </Modal>



                        <Modal isOpen={expModalCsv} toggle={toggleExportCsv}>
                            {/* <CloseButton onClick={() => {
                                // Show alert only when the custom close button is clicked
                                alert("test");
                                // toggleExportCsv();
                            }}> <span aria-hidden="true">&times;</span>
                            </CloseButton> */}
                            <ModalHeader toggle={toggleExportCsv}>Exporter</ModalHeader>
                            <ModalBody>
                                êtes-vous sûr ? Vous allez exporter  {exportedItemsCount && exportedItemsCount}  musiques  .
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={cancelExport} variant='secondary'>
                                    Annuler
                                </Button>{' '}

                                <CSVLink
                                    style={{ marginRight: "10px" }}
                                    data={dataToExport}
                                    headers={headers}
                                    filename={"musiques.csv"}
                                    className="mr-5 btn btn-primary waves-effect waves-light"
                                    disabled={csvLoad || isExporting} // Disable if loading or exporting
                                    onClick={() => setExpModalCsv(false)} // Close the modal when exporting starts
                                >
                                    <AiOutlineFileExcel style={{ fontSize: '17px', marginRight: '5px' }} />
                                    Exporter
                                </CSVLink>

                            </ModalFooter>
                        </Modal>



                        <Modal isOpen={dModal} toggle={toggleDelete}>
                            <ModalHeader toggle={toggleDelete}>Supprimer</ModalHeader>
                            <ModalBody>
                                êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleDelete} variant='secondary'>
                                    Annuler
                                </Button>{' '}
                                <Button onClick={() => handleDelete(musiqueId)} variant='danger' >
                                    Supprimer
                                </Button>
                            </ModalFooter>
                        </Modal>


                        <Modal isOpen={mModal} toggle={toggleLaunch}>
                            <ModalHeader toggle={toggleLaunch}>Music player</ModalHeader>
                            <ModalBody>
                                <AudioPlayer
                                    autoPlay={false}
                                    key={musicLink}
                                    src={musicLink}
                                    onPlay={e => console.log("onPlay music right now !!!.......................", musicLink)}
                                />
                            </ModalBody>
                            <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <Button disabled={lyricsLoad} onClick={() => {

                                        showLyricsDuration()
                                    }} variant='secondary'>
                                        {!lyricsLoad ? (
                                            <span>                                  Durée du lyrics
                                            </span>
                                        ) : (
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}

                                    </Button>{' '}

                                    {lyricsTimeStamp}
                                </div>

                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>


                                    <Button disabled={lyricsLoad} onClick={() => {

                                        setMLyricsModal(true)
                                    }} variant='primary'>

                                        Lire avec le lyrics

                                    </Button>
                                    <Button variant='danger' onClick={toggleLaunch}>
                                        Fermer
                                    </Button>
                                </div>

                            </ModalFooter>
                        </Modal>



                        {/* lecture avec lyrics */}
                        <Modal size='fullscreen' isOpen={mLyricsModal} toggle={toggleLyricsLaunch}
                        // style={{
                        //     maxWidth: "1600px",
                        //     maxHeight: "800px",
                        //     height: "800px",
                        //     overflow: "hidden"
                        // }}
                        >
                            <ModalHeader style={{ height: "30px", borderBottom: 'none', marginTop: 5 }} toggle={toggleLyricsLaunch}>Lecture avec lyrics</ModalHeader>
                            <ModalBody>
                                <MusicLyrics
                                    titre={currentMusicTitle} artiste={currentMusicArtiste} music={musicLink}
                                />
                            </ModalBody>
                            {/* <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant='danger' onClick={toggleLyricsLaunch}>
                                    Fermer
                                </Button>
                            </ModalFooter> */}
                        </Modal>




                    </Card>
                </Col>
            </Row >  </div >
    )
}