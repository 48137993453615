import {
    FETCH_MUSICS_HISTORY_REQUEST,
    FETCH_MUSICS_HISTORY_SUCCESS,
    FETCH_MUSICS_HISTORY_FAILURE,
    ADD_MUSICS_HISTORY_REQUEST,
    ADD_MUSICS_HISTORY_SUCCESS,
    ADD_MUSICS_HISTORY_FAILURE
} from '../actions/musicsHActions';

const initialState = {
    loading: false,
    musicshistory: [],  // This will store an array of music history objects
    error: ''
};

const musicsHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MUSICS_HISTORY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_MUSICS_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                musicshistory: action.payload,  // Update the state with the fetched data
                error: ''
            };
        case FETCH_MUSICS_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                musicshistory: [],  // Reset the data if fetch failed
                error: action.payload  // Store the error message
            };
        case ADD_MUSICS_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,  // Loading is true while adding
            };
        case ADD_MUSICS_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,  // Loading is false after the music history is added
            };
        case ADD_MUSICS_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload  // Store the error message
            };
        default:
            return state;  // If the action type is not recognized, return the existing state
    }
};

export default musicsHistoryReducer;
