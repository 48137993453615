import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import { DateTime } from 'luxon';
import WebFont from 'webfontloader';
import Countdown from '../Countdown';
import '../../Lyrics.css'


const LyricsDisplay = (props) => {
    const [currentLine, setCurrentLine] = useState(0);
    const [catchUp, setCatchUp] = useState(false)
    const [catchUpTime, setCatchUpTime] = useState(1000)

    const [decallage, setDecallage] = useState(0)


    const [decallageSetup, setDecallageSetup] = useState(false)



    const [firstLine, setFirstLine] = useState(false)
    const [countdownstate, setcountdownstate] = useState(false)
    const [show, setShow] = useState(false)




    useEffect(() => {
        setCurrentLine(0);
        setFirstLine(false)
        setcountdownstate(false)
        setCatchUp(false)
        setDecallageSetup(false)
        setShow(false)
        setDecallage(0)
        console.log("nouveau lyrics");
        compare()
    }, [props.Lyrics])


    function formatDuration(ms) {
        let seconds = Math.floor(ms / 1000); // Convert milliseconds to seconds
        let hours = Math.floor(seconds / 3600); // Get the number of hours
        seconds %= 3600; // Remainder of seconds after removing full hours
        let minutes = Math.floor(seconds / 60); // Get the number of minutes
        seconds %= 60; // Remainder of seconds after removing full minutes

        // Format the time as hh:mm:ss with leading zeros if necessary
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    async function compare() {
        if (props?.zettaData?.current?.airStartTime) {
            const firstLineDuration = parseInt(props.Lyrics[1].milliseconds)

            const lyricsDuration = parseInt(props.Lyrics[props.Lyrics.length - 1].milliseconds) + parseInt(props.Lyrics[props.Lyrics.length - 1].duration)
            const musicDuration = parseInt(props?.zettaData?.current?.airStopTime - props?.zettaData?.current?.airStartTime) * 1000
            const result = parseInt(lyricsDuration - musicDuration)

            const catchUpDuration = firstLineDuration - result

            const seuil = 60000 //ms

            // console.log(" A rattraper , firstLineDuration: ", firstLineDuration, "decallage:", result);
            // console.log('====================================');

            // // result < -17 à verifier!!!!!!!!!!!
            if (Math.abs(result) < seuil) {
                // setDecallage(result)
                console.log('====================================');
                console.log("Affiché : ", firstLineDuration, "decallage:", result);
                console.log('====================================');
                setShow(true)

            } else {

                console.log('====================================');
                console.log("trop long/retard non autorisé: firstLineDuration: ", firstLineDuration, "decallage:", result);
                console.log('====================================');
            }

            setDecallageSetup(true)
            console.log('====================================');
            console.log(`Durée lyrics: "${formatDuration(lyricsDuration)}", Durée titre: "${formatDuration(musicDuration)}", Decallage: "${parseInt(result)}"`);
            console.log('====================================');
            toast.success(`Durée lyrics: "${formatDuration(lyricsDuration)}", Durée titre: "${formatDuration(musicDuration)}", Decallage: "${parseInt(result) / 1000} s"`,
                {
                    position: "top-left",
                    autoClose: 20000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
        }
        else {
            console.log('====================================');
            console.log("Information du titre non encore enregistrée : ", props?.zettaData);
            console.log('====================================');

            setDecallageSetup(true)
            toast.success("Information du titre non encore enregistrée");
        }

    }

    // useEffect(() => {
    //     let lineInterval;
    //     if (currentLine === 0) {
    //         const duration = parseInt(props.Lyrics[0].milliseconds) || 3000
    //         lineInterval = setTimeout(() => {
    //             console.log("line 1 .....", duration);
    //             setFirstLine(true)
    //         }, duration);

    //         return () => clearTimeout(lineInterval);
    //     }
    // }, [props.Lyrics, currentLine]);


    // useEffect(() => {
    //     let lineInterval;
    //     if (currentLine === 0) {
    //         const duration = (parseInt(props.Lyrics[0].milliseconds) - 3000) || 3000
    //         lineInterval = setTimeout(() => {
    //             console.log("Count down .....", duration);
    //             setcountdownstate(true)
    //         }, duration);

    //         return () => clearTimeout(lineInterval);
    //     }
    // }, [props.Lyrics, currentLine]);

    // console.log("lyrics......: ", props.Lyrics);
    useEffect(() => {
        if (props.firstTime && decallageSetup) {
            const formattedAssetStartTime = props.startTime.replace(' ', 'T');
            const startTime = DateTime.fromISO(formattedAssetStartTime, { zone: 'Europe/Paris' }).toMillis();
            const currentTime = DateTime.now().setZone('Europe/Paris').toMillis();
            const elapsed = currentTime - startTime - props.delai;
            console.log("startTime", startTime);
            console.log("currentTime", currentTime);
            console.log("elapsed", elapsed);

            if (elapsed > 0) {
                let accumulatedTime = 0;
                let startingLine = -1;
                for (let i = 0; i < props.Lyrics.length; i++) {

                    if (i === 0) {
                        // accumulatedTime += parseInt(props.Lyrics[1].milliseconds);
                        accumulatedTime = parseInt(props.Lyrics[1].milliseconds);

                    }
                    else {
                        // accumulatedTime += parseInt(props.Lyrics[i].duration);
                        accumulatedTime = parseInt(props.Lyrics[i].milliseconds);

                    }

                    // accumulatedTime = parseInt(props.Lyrics[i].milliseconds);


                    if (accumulatedTime > elapsed) {
                        // alert(i)
                        if (i > 0) {
                            setCatchUp(true)
                            setCatchUpTime(elapsed)
                            console.log('saut de ligne: ', i);
                        }
                        startingLine = i;
                        break;
                    }
                }

                if (startingLine !== -1) {
                    setCurrentLine(startingLine);
                } else {
                    setCurrentLine(props.Lyrics.length - 1);  // Si le temps écoulé dépasse la durée totale, afficher la dernière ligne
                }
            } else {
                const delay = -elapsed;
                const startTimeout = setTimeout(() => {
                    setCurrentLine(0);
                }, delay);

                return () => clearTimeout(startTimeout);
            }
        }
        else {
            console.log("not firstTime");

        }

    }, [props.Lyrics, decallageSetup]);





    useEffect(() => {
        let interval;
        if (!decallageSetup) {
            return
        }

        if (currentLine >= 0 && currentLine < props.Lyrics.length) {
            let catchUpDuration
            let duration

            // latence à soustraire par parole pour afficher un peu en avance
            let latenceFirstLine = 12000

            // rattraper le temps de la parole à afficher en cas d'un reload ou du premier lancement
            if (catchUp && currentLine < (props.Lyrics.length - 1) && currentLine > 0) {
                // alert(catchUp)
                catchUpDuration = (parseInt(props.Lyrics[currentLine + 1].milliseconds) - parseInt(catchUpTime))
                setCatchUp(false)
                console.log("time and catching up......", currentLine, props.Lyrics[currentLine].lrc_timestamp)
                duration = catchUpDuration
            }


            // si non calculer le temps en fonction de la structure de parole
            else {
                latenceFirstLine = catchUp ? parseInt(catchUpTime) + latenceFirstLine : latenceFirstLine
                setCatchUp(false)

                currentLine === 0 && console.log("adding time: ", decallage);

                const duree = currentLine === 0 ? parseInt(props.Lyrics[currentLine + 1].milliseconds) - decallage - latenceFirstLine : 1000
                const dureeMidle = (currentLine < props.Lyrics.length - 1 && currentLine > 0) ? (parseInt(props.Lyrics[currentLine + 1].milliseconds) - parseInt(props.Lyrics[currentLine].milliseconds)) : 1000
                const dureeEnd = currentLine === props.Lyrics.length - 1 ? parseInt(props.Lyrics[currentLine].duration) : 1000

                duration = currentLine <= 0 ? duree : (currentLine > 0 && currentLine !== props.Lyrics.length - 1 ? dureeMidle : dureeEnd);
            }

            // console.log("duration: ", duration, props.Lyrics[currentLine].lrc_timestamp);
            interval = setTimeout(() => {
                // console.log("next", props.Lyrics[currentLine].line);
                // console.log("duration.....", duration);
                try {
                    if (duration > 1500) {
                        //  slideAnimation() 

                    }
                } catch (error) {
                    console.log('====================================');
                    console.error("error duration", error);
                    console.log('====================================');
                }

                setCurrentLine(currentLine + 1);
            }, duration);

            return () => clearTimeout(interval);
        }
        // console.log('prooooooooooooops', props.Color);
    }, [currentLine, props.Lyrics, catchUp, catchUpTime, decallageSetup]);


    useEffect(() => {
        if (props.Police) {
            WebFont.load({
                google: {
                    families: [props.Police]
                }
            });
        }
    }, [props.Police]);


    if (!show) {
        return <div className='px-5 container'
            style={{ height: "100%", display: "flex", alignItems: 'center', justifyContent: 'center' }}
        >
            <p className={`text-center lyric-text`} style={{ fontSize: `${80}px`, color: "white", fontFamily: props.Police }}>... 😉PAROLES AU PROCHAIN TITRE😉 ...</p>

        </div>
    }


    return (
        <div className='px-5 container'
            style={{ height: "100%", display: "flex", alignItems: 'center', justifyContent: 'center' }}
        >
            {/* <Chrono />*/}

            {/* {countdownstate && <Countdown />} */}

            <br />
            {props.Lyrics.map((lyric, index) => (
                <div
                    key={index}
                    className='text-center'
                    style={{
                        display: currentLine === index ? 'block' : 'none',
                        color: 'white',
                        width: "100%"
                    }}
                >
                    {/* <p className="text-center lyric-text" style={{ fontSize: `${props.Taille}px`, color: props.Color, fontFamily: props.Police }}>{lyric.line}</p> */}
                    {/* {index > 0 && index < props.Lyrics.length - 1 ? */}
                    <>
                        {/* <p className="text-center lyric-text previous" style={{ fontSize: `${props.Taille}px`, color: props.Color, fontFamily: props.Police }}>{props.Lyrics[index - 1]?.line}</p> */}
                        {/* <p className="text-center lyric-text next" style={{ fontSize: `${props.Taille - 30}px`, color: props.configurationRadio.lyricsColorN, fontFamily: props.Police }}>{props.Lyrics[index - 1]?.line}</p> */}

                        <p className="text-center lyric-text next" style={{ fontSize: `${props.Taille}px`, color: props.configurationRadio.lyricsColorN, fontFamily: props.Police }}>{props.Lyrics[index - 1]?.line}</p>
                        <p className={`text-center lyric-text ${firstLine ? "current" : "next"}`} style={{ fontSize: `${props.Taille}px`, color: props.Color, fontFamily: props.Police }}>{lyric.line}</p>
                        <p className="text-center lyric-text next" style={{ fontSize: `${props.Taille}px`, color: props.configurationRadio.lyricsColorN, fontFamily: props.Police }}>{props.Lyrics[index + 1]?.line}</p>
                        <p className="text-center lyric-text next" style={{ fontSize: `${props.Taille}px`, color: props.configurationRadio.lyricsColorN, fontFamily: props.Police }}>{props.Lyrics[index + 2]?.line}</p>
                        <p className="text-center lyric-text next" style={{ fontSize: `${props.Taille}px`, color: props.configurationRadio.lyricsColorN, fontFamily: props.Police }}>{props.Lyrics[index + 3]?.line}</p>
                        <p className="text-center lyric-text next" style={{ fontSize: `${props.Taille}px`, color: props.configurationRadio.lyricsColorN, fontFamily: props.Police }}>{props.Lyrics[index + 4]?.line}</p>

                    </>
                    {/* : <p className="text-center lyric-text" style={{ fontSize: `${props.Taille + 10}px`, color: "green", fontFamily: props.Police }}>{lyric.line}</p> */}
                    {/* } */}
                </div>
            ))}
        </div>
    );
};

export default LyricsDisplay;