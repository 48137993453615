import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useNavigation } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminRoutes } from './admin/routes/allRoutes';
import Authmiddleware from './admin/routes/middleware/Authmiddleware';
import VerticalLayout from './admin/components/VerticalLayout';
import './assets/scss/theme.scss';
import Habillage from './admin/pages/Habillage/habillage';
import HomePage from "./slidesShow/HomePage"
import Login from "./admin/pages/Auth/Login"
import ResetPassword from './admin/pages/Auth/ResetPassword';
import ResetPwdPage from './admin/pages/Auth/ResetPwdPage';
import Dashboard from './admin/pages/dashboard/Dashboard';
import axiosClient from './axios-client';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import VideoPlayer from './PlayerMelody';
import AudioStream from './slidesShow/components/newAnimations/components/stream/AudioStream';

const styles = `
body{
  overflow-y: auto;
}
`

const App = () => {
  function getLayout() {
    return VerticalLayout;
  }

  const Layout = getLayout();

  // const navigation = useNavigation()

  const [backendAvailable, setBackendAvailable] = useState(false);

  const notifyError = (text) => toast.error(text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
  });


  useEffect(() => {
    const checkBackendAvailability = async () => {
      try {
        await axiosClient.get('/');
        setBackendAvailable(true);
      } catch (error) {
        setBackendAvailable(false);
        // Pass specific error message based on error type
        if (error.response) {
          // Request made and server responded with a status code
          notifyError(`Error ${error.response.status}: ${error.response.data.message}`);
        } else if (error.request) {
          // Request made but no response received
          notifyError("No response received from server. Please try again later.");
        } else {
          // Something else happened in making the request
          notifyError("Error occurred. Please try again later.");
        }
      }
    };

    // checkBackendAvailability();

    // Clean up any resources if needed
    return () => {
      // Clean-up function (optional)
    };
  }, []);


  console.log("backendAvailablebackendAvailable", backendAvailable);

  return (

    <React.Fragment>
      < style> {styles}</ style>
      {/* <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="bounce"
      /> */}

      <Router>
        <Routes>
          <Route
            path="/"
            element={<Authmiddleware layout={Layout} component={Dashboard} />}
          />
          {adminRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<Authmiddleware layout={Layout} component={route.component} />}
            />
          ))}
          <Route
            path="/home"
            element={<HomePage />}
          />

          <Route
            path="/audiostream/:id"
            element={<AudioStream />}
          />

          <Route
            path="/reset_password"
            element={<ResetPassword />}
          />
          <Route
            path="/reinitialisation_de_motdepasse/:userId/:token/:expires"
            element={<ResetPwdPage />}
          />
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/MelodyPlayer"
            element={<VideoPlayer />}
          />
        </Routes>
      </Router>
    </React.Fragment>
  );
};



export default App
