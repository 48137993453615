import React, { useState, useEffect } from 'react';
import './StreamStyle.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import disque from "../../../../assets/images/disque.png";
import LyricsDisplay from './StreamLyrics';
import WebFont from 'webfontloader';
import { DateTime } from 'luxon';

import { linkHome } from '../../../../../axios-client';
// import { lowerCase } from 'lodash';
import { toast, ToastContainer } from 'react-toastify';
import SlideAnimation from '../SlideAnimation';
import CryptoJS from 'crypto-js';


const NewAnimation = () => {
    const { id } = useParams();
    // const idRadio = parseInt(id);

    const idRadio = parseInt(CryptoJS.AES.decrypt(CryptoJS.enc.Base64.parse(id).toString(CryptoJS.enc.Utf8), 'secret-key').toString(CryptoJS.enc.Utf8));

    const [currentSlide, setCurrentSlide] = useState(0);

    const [previousSlide, setPreviousSlide] = useState(0);
    const [currentTransitionEffect, setCurrentTransitionEffect] = useState('fondu');
    const [currentSlideShowEffect, setCurrentSlideShowEffect] = useState('zoom-out');

    const [slideImages, setSlideImages] = useState([]);
    const [logoRadio, setLogoRadio] = useState();
    const [configurationRadio, setConfigurationRadio] = useState();
    const [durationTransition, setDurationTransition] = useState(10);
    const [dataTransitionEffect, setDataTransitionEffect] = useState([]);
    const [dataSlideShowEffect, setDataSlideShowEffect] = useState([]);
    const [etatLoading, setEtatLoading] = useState(true);
    const [slideImagesTime, setSlideImagesTime] = useState([]);
    const [lastTitle, setLastTitle] = useState('');
    const [lastArtiste, setLastArtiste] = useState('');
    const [lastAlbumCover, setLastAlbumCover] = useState('');
    const [streamUrl, setStreamUrl] = useState();
    const [loading, setLoading] = useState(true);

    const [Lyrics, setLyrics] = useState([]);
    const [zettaLyrics, setZettaLyrics] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [defaultSleeve, setDefaultSleeve] = useState('');
    const [rotateLogo, setRotateLogo] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const [count, setCount] = useState(0);
    // const [musicInfo, setMusicInfo] = useState({});

    // const [zettaTitleImages, setZettaTitleImages] = useState([]);
    // const [zettaSleeve, setZettaSleeve] = useState('');




    const [zettaData, setZettaData] = useState({});
    const [prev_zettaData, setPrev_zettaData] = useState({});

    const [copyright, setCopyright] = useState([]);




    const delai = 0
    const decallageZettaSymbal = 17 //s
    const seuilDetectionSymbal = 30 //s
    const cumul = decallageZettaSymbal + seuilDetectionSymbal //s




    const [lyricsAllowed, setLyricsAllowed] = useState(false);

    const [countPlus, setCountPlus] = useState(1);
    const [nextData, setNextData] = useState(1);



    const [message, setMessage] = useState({});


    // traitement zetta
    useEffect(() => {
        // Création d'une instance de WebSocket
        const socket = new WebSocket('wss://ns3200830.ip-141-94-133.eu:3001/510/tuple');

        // Événement déclenché lors de l'ouverture de la connexion
        socket.onopen = () => {
            console.log('Connexion zetta ouverte');
        };

        // Événement déclenché lorsqu'un message est reçu
        socket.onmessage = (event) => {
            const newMessage = event.data;
            const zetta = JSON.parse(newMessage)


            // Vérification si le message a changé
            if (zetta !== zettaData) {
                setZettaData(zetta);
                // console.log('====================================');
                // console.log("Zetta data changed: ", zetta.current
                // );
                // console.log('====================================');

                const radioName = "CHANTE FRANCE"

                // renitialisation slide
                setPreviousSlide(0)
                setCurrentSlide(0)
                setSlideImagesTime(slideImages)
                const defaultLogo = (defaultSleeve && defaultSleeve !== "") ? defaultSleeve : logoRadio
                console.log('====================================');
                console.log("logo.......: ", defaultLogo);
                console.log('====================================');
                setLastAlbumCover(defaultLogo)
                // 

                if (zetta?.current?.typeId === 1) {
                    getLyrics(zetta?.current?.title, zetta?.current?.artist, radioName)
                }
                else {
                    setPrev_zettaData(zetta)

                    // console.log('====================================');
                    // console.log("renitialisation de paroles");
                    // console.log('====================================');
                    setZettaLyrics([])
                }

            }
        };

        // Événement déclenché lors de la fermeture de la connexion
        socket.onclose = () => {
            console.log('Connexion zetta fermée');
        };

        // Nettoyage de la connexion lors du démontage du composant
        return () => {
            // console.log('Connexion WebSocket fermée');
            socket.close();
        };
    }, [slideImages, defaultSleeve, logoRadio]); // Ajout de `message` comme dépendance pour la vérification




    useEffect(() => {
        // Création d'une instance de WebSocket
        const socket = new WebSocket('wss://ns3200830.ip-141-94-133.eu:3001/558/tuple');
        // console.log("websocket.......");

        // Événement déclenché lors de l'ouverture de la connexion
        socket.onopen = () => {
            // console.log('Connexion WebSocket ouverte');
        };

        // Événement déclenché lorsqu'un message est reçu
        socket.onmessage = (event) => {
            const newMessage = event.data;
            const msg = JSON.parse(newMessage)

            // console.log('Message reçu:', msg["full"]);

            // Vérification si le message a changé
            if (msg?.current?.title !== message?.current?.title && zettaData) {
                // if ((msg.full[0].title !== lastTitle && msg.full[0].artist !== lastArtiste) && (msg.full[0].title && msg.full[0].artist)) {
                console.log('====================================');
                console.log("new push", msg?.current?.title, message?.current?.title);
                console.log('====================================');
                // setMessage(msg);


                //determiner si un titre est en retard sur la detection
                const diff = msg?.current?.airStartTime - zettaData?.current?.airStartTime
                // zettaData.current.typeId === 1 c'est une musique

                if ((msg?.current?.artist !== message?.current?.artist) && (msg?.current?.title && msg?.current?.artist && diff < cumul)
                    &&
                    msg?.current?.typeId === 1
                ) {

                    // updateSlideOnTime(msg.full[0])
                    updateSlideOnTime(zettaData.current, msg?.current?.simbals?.start)
                    setMessage(msg);

                    getMetaData()

                    console.log('====================================');
                    console.log("prevData: ", prev_zettaData?.current, "diff: ", diff, " secondes"
                    );
                    console.log('====================================');


                    prev_zettaData?.current && toast.error(`Signal zetta avant titre!!!     "${prev_zettaData?.current?.title}" durée:  ${prev_zettaData?.current?.airStartTime && (prev_zettaData?.current?.airStopTime - prev_zettaData?.current?.airStartTime)} secondes`, {
                        // prev_zettaData?.current && toast.error(`Possibilité du saut !!!     "${prev_zettaData?.current?.title}" `, {

                        position: "top-right",
                        autoClose: 30000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });

                    console.log('====================================');
                    console.log("maintenant: ", DateTime.now()
                    );
                    console.log('====================================');

                    toast.success(`Nouveau titre detecté !!!     "${msg?.current?.title}" et  ${diff} secondes de decallage avec zetta`, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });

                    setPrev_zettaData({})

                }

                // gestion pub
                else if ((msg?.current?.typeId !== 1 || !msg?.current || (msg?.current?.typeId === 1 && !msg?.current?.title)) && (zettaData?.current?.title !== lastTitle)) {
                    // updateSlideOnTime(msg.full[0])
                    updateSlideOnTime(zettaData.current, msg?.current?.simbals?.start)

                    // retirer les paroles puisqu'on a pas le signal symbal
                    setLyrics([])
                    console.log('====================================');
                    console.log("pub ou symbal non detecté:", zettaData?.current, "message: ", msg?.current);
                    console.log('====================================');

                    // console.log('Pub:', msg.full[0].title, msg.full[0].artist);
                    // console.log('Pub:', zettaData.current.title, zettaData.current.artist);

                }
                // console.log('Nouveau message:', msg.full[0].title, msg.full[0].artist);
            }
        };

        // Événement déclenché lors de la fermeture de la connexion
        socket.onclose = () => {
            // console.log('Connexion WebSocket fermée');
        };

        // Nettoyage de la connexion lors du démontage du composant
        return () => {
            // console.log('Connexion WebSocket fermée');
            socket.close();
        };
    }, [message, lastArtiste, lastTitle, zettaData, zettaLyrics, prev_zettaData]); // Ajout de `message` comme dépendance pour la vérification





    async function getLyrics(titre, artiste, radio) {
        // try {
        //     await getMusicInfo(titre, artiste, radio)

        // } catch (error) {
        //     console.log('====================================');
        //     console.log("no music info");
        //     console.log('====================================');
        // }


        try {
            const lyricsCheckUrl = `${linkHome}/lyricsChecking/?titre=${titre}&artiste=${artiste}`;

            const lyricsResponse = await axios.get(lyricsCheckUrl);
            const hasLyrics = lyricsResponse.data.hasLyrics;

            const lyrics = lyricsResponse.data;
            console.log("lyrics : ", lyrics);

            const filteredLyrics = lyrics.lyrics.filter(lyric => lyric.duration && lyric.milliseconds);

            // if (firstTime) {
            //     console.log('====================================');
            //     console.log("first title....");
            //     console.log('====================================');
            //     setZettaLyrics(filteredLyrics)
            //     setLyrics(filteredLyrics)

            // } else {
            //     setZettaLyrics(filteredLyrics)
            // }

            setZettaLyrics(filteredLyrics)


        } catch (error) {
            // setLyrics([])
            setZettaLyrics([])
            // console.log('====================================');
            // console.error("no Lyrics", error);
            // console.log('====================================');
        }

    }

    // async function getMusicInfo(titre, artiste, radio) {
    //     const musicInfo = `${linkHome}/musicshistorys/?title=${titre}&artist=${artiste}&radio=${radio}`;
    //     try {
    //         const musicInfoResponse = await axios.get(musicInfo);

    //         const music = musicInfoResponse.data;
    //         // console.log("musiques : ", music);
    //         setMusicInfo(music)

    //     } catch (error) {
    //         setMusicInfo({})
    //         // console.error(error);

    //     }

    // }


    async function getMetaData() {

        const metaDataEndPoint = `https://ns3200830.ip-141-94-133.eu/radioscreen/cache/state/510.json`;
        try {
            const musicInfoResponse = await axios.get(metaDataEndPoint);

            const musicMetadata = musicInfoResponse.data;
            // setZettaTitleImages(musicMetadata.images)
            // setZettaSleeve()
            const server = "https://ns3200830.ip-141-94-133.eu"
            const pochette = musicMetadata.album_cover
            const artisteImage = musicMetadata.musicImages.filter(data => data !== pochette)
            const artisteImageFixedLink = artisteImage.map((img) => {
                return (server + img)?.replace(/\\/g, '/')
            })
            // console.log("musiques metadata: ", (server + pochette)?.replace(/\\/g, '/'), artisteImage);
            const copyrght = musicMetadata?.copyrights

            pochette !== "" && setLastAlbumCover((server + pochette)?.replace(/\\/g, '/'))
            artisteImage.length > 1 && setSlideImagesTime(artisteImageFixedLink)
            setCopyright(copyrght)

        } catch (error) {
            console.error(error);

        }

    }

    async function updateSlideOnTime(data, start = undefined) {
        setLastTitle(data?.title);
        setLastArtiste(data?.artist);
        setStartTime(start)
        setCount(count + 1)
        const radioName = "CHANTE FRANCE"
        // console.log("starttime and count: ", data?.simbals?.start, count);

        setPreviousSlide(0)
        setCurrentSlide(0)
        if (count > 0) {
            setFirstTime(false)
        }

        setLyrics(zettaLyrics)

        // if (data?.typeId === 1) {
        //     getLyrics(data?.title, data?.artist, radioName)
        // }


    }


    const fetchData = async () => {
        try {
            const logoRes = await axios.get(`${linkHome}/radios/${idRadio}/`);
            setLogoRadio(logoRes.data.logo);
            setLastAlbumCover(logoRes.data.logo)
            setStreamUrl(logoRes.data.audioLinkStream);
            setLyricsAllowed(logoRes.data.allowLyrics)
            setRotateLogo(logoRes.data.logoBehavior)

            // console.log("stream link ", logoRes.data.audioLinkStream);

            const imagesRes = await axios.get(`${linkHome}/default_images/${idRadio}/`);
            setSlideImages(imagesRes.data);
            setSlideImagesTime(imagesRes.data || []);
            const configRes = await axios.get(`${linkHome}/current_config/?radio=${idRadio}`);
            setConfigurationRadio(configRes.data);
            setDataTransitionEffect(configRes.data?.transitionEffect);
            setDurationTransition(configRes.data?.transitionDuration);
            setDataSlideShowEffect(configRes.data?.slideShowEffect);
            setCurrentTransitionEffect(configRes.data?.transitionEffect[0]);
            setCurrentSlideShowEffect(configRes.data?.slideShowEffect[0]);
            setDefaultSleeve(configRes.data?.sleeve)

            setLoading(false);
            setEtatLoading(false)


        } catch (error) {
            console.error('Error fetching data:', error);
            setEtatLoading(false)
            setLoading(false);
        }
    };



    useEffect(() => {
        fetchData();
    }, []);



    // animation slideShow
    useEffect(() => {
        if (!loading && !etatLoading && slideImagesTime.length !== 0) {
            const slideInterval = setInterval(() => {
                setPreviousSlide(currentSlide)
                const currentSlideEffect = dataSlideShowEffect[Math.floor(Math.random() * dataSlideShowEffect.length)];
                setCurrentSlideShowEffect(currentSlideEffect);
                // console.log("currentSlideEffect", currentSlideEffect);

                const currentTransitionEffect = dataTransitionEffect[Math.floor(Math.random() * dataTransitionEffect.length)];
                // console.log("currentTransitionEffect", currentTransitionEffect);
                setCurrentTransitionEffect(currentTransitionEffect);

                setCurrentSlide((prevSlide) => {
                    const nextSlide = (prevSlide + 1) % slideImagesTime.length;
                    return nextSlide;
                });
            }, durationTransition * 1000);

            return () => clearInterval(slideInterval);
        }
    }, [durationTransition, loading, slideImagesTime, dataSlideShowEffect, currentSlide, dataTransitionEffect, etatLoading]);


    useEffect(() => {
        if (configurationRadio?.titlePolice) {
            WebFont.load({
                google: {
                    families: [configurationRadio?.titlePolice]
                }
            });
        }
    }, [configurationRadio?.titlePolice]);

    useEffect(() => {
        if (configurationRadio?.artistPolice) {
            WebFont.load({
                google: {
                    families: [configurationRadio?.artistPolice]
                }
            });
        }
    }, [configurationRadio?.artistPolice]);

    const getFileName = (url) => {
        try {
            // console.log('====================================');
            // console.log(url.split("/").pop(), "file path: ", url);
            // console.log('====================================');
            return url.split("/").pop();
        }
        catch (error) {
            return ""
        }

    };


    const renderSlides = () => {
        return slideImagesTime.map((slideImage, index = currentSlide) => {
            return (
                <div style={{ position: 'relative' }} className={``} key={index}>



                    {/* <div style={{ position: 'absolute', top: 5, left: 0, color: 'white', zIndex: 3, width: '100vw', }}>
                        <div style={{
                            display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', color: 'white'
                        }}>
                            © 2024. Tous droits réservés. 77777777777777777 {currentSlide}

                        </div>
                    </div> */}


                    {/* copyright */}
                    <div style={{ position: 'absolute', top: 5, right: 5, color: 'white', zIndex: 3, height: '100vh', }}>
                        <div style={{
                            display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center', color: 'white'
                        }}>
                            {<span style={{ writingMode: 'vertical-rl', textOrientation: 'mixed' }}>
                                {/* © 2024. Tous droits réservés. */}
                                {copyright[getFileName(slideImagesTime[currentSlide])]}
                            </span>}

                        </div>
                    </div>


                    <div
                        className={`${currentTransitionEffect} ${currentSlide === index && `${currentTransitionEffect}-checked`}`}
                    >
                        <div style={{
                            backgroundImage: `url(${slideImagesTime[index]?.replace(/\\/g, '/') || slideImages[0]})`,
                            // background: `yellow`,
                            position: "absolute",
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            width: "120%",
                            height: "120%",
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            // filter: 'blur(12px)',
                            filter: ` ${configurationRadio?.blackWhiteImage ? 'grayscale(1) blur(12px)' : ' grayscale(0) blur(12px)'}`,

                            zIndex: -3,
                            objectFit: 'cover',
                        }}
                        ></div>

                        <img
                            alt=''
                            className={`image ${currentSlide === index && `image-effect-${currentSlideShowEffect}`}`}
                            // src={slideImagesTime[index]?.replace(/\\/g, '/')}
                            // onError={handleImageError}
                            src={slideImagesTime[index]?.replace(/\\/g, '/') || slideImages[0]}
                            style={{
                                animation: currentSlide === index && ` ${currentSlideShowEffect} ${durationTransition}s forwards`,
                                filter: configurationRadio?.blackWhiteImage ? 'grayscale(1)' : 'grayscale(0)',
                                objectFit: 'contain',
                                position: 'relative',
                                // display: imageLoaded[index] ? 'block' : 'none',
                                zIndex: 1
                            }}
                        />

                    </div>
                    {(previousSlide === index - 1 || (previousSlide === slideImagesTime.length - 1 && index === 0) || (previousSlide === currentSlide)) &&
                        <div
                            style={{
                                height: '100vh',
                                width: '100%',
                                animation: `${currentTransitionEffect}-out 1s ease-in-out`,
                                zIndex: -1
                            }}
                        // className={`combiner2-checked`}

                        >

                            <div style={{
                                backgroundImage: previousSlide === currentSlide ? `url(${slideImagesTime[previousSlide]?.replace(/\\/g, '/')})` : `url(${slideImagesTime[previousSlide]?.replace(/\\/g, '/')})`,
                                // backgroundImage: `url(${(currentSlide === 0 ? slideImagesTime[currentSlide] : slideImagesTime[previousSlide])?.replace(/\\/g, '/')})`,
                                // background:'yellow',
                                position: "absolute",
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                                width: "120%",
                                height: "120%",
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                // filter: 'blur(12px)',
                                filter: ` ${configurationRadio?.blackWhiteImage ? 'grayscale(1) blur(12px)' : ' grayscale(0) blur(12px)'}`,
                                zIndex: 0,
                                objectFit: 'cover',
                            }}></div>

                            <img
                                alt=''
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    filter: configurationRadio?.blackWhiteImage ? 'grayscale(1)' : 'grayscale(0)',
                                    objectFit: 'contain',
                                    zIndex: 0
                                }}
                                className={`image`}
                                src={index === 0 ? slideImagesTime[slideImagesTime.length - 1] : slideImagesTime[previousSlide]}
                            // onLoad={() => handleImageLoad(index)}
                            // style={{
                            //     animation: currentSlide === index && ` ${currentSlideShowEffect} ${durationTransition - 2}s forwards`,
                            //     filter: configurationRadio?.blackWhiteImage ? 'grayscale(1)' : 'grayscale(0)',
                            //     objectFit: 'contain',
                            //     position: 'relative',
                            //     display: imageLoaded[index] ? 'block' : 'none',
                            // }}
                            />


                        </div>}

                </div>
            )
        });
    };

    const hexToRgba = (hex, alpha) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    return (
        <div className="slide-container"
        // style={{
        //     backgroundImage: `url(${logoRadio})`,
        // }}
        >
            <ToastContainer />
            {(!loading) && <>

                <SlideAnimation
                    configurationRadio={configurationRadio}
                    Lyrics={Lyrics}
                    lyricsAllowed={lyricsAllowed}
                    lastAlbumCover={lastAlbumCover}
                    defaultSleeve={defaultSleeve}
                    logoRadio={logoRadio}
                    lastTitle={lastTitle}
                    lastArtiste={lastArtiste}
                    nextTitle={[]}
                    rotateLogo={rotateLogo}
                />

            </>
            }


            {!loading && !etatLoading && <>
                {renderSlides()
                }

                <div style={{
                    backgroundColor: hexToRgba(configurationRadio?.lyricsBgColor, 0.5),
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    height: `${configurationRadio?.lyricsFilterSize}%`,
                    backgroundPosition: "bottom",
                    zIndex: 2,
                    objectFit: 'cover',
                    display: "flex", alignItems: 'center', justifyContent: 'center',
                    overflow: "hidden"
                }}>{(
                    (// startTime && 
                        Lyrics.length && lyricsAllowed)) ?
                    <LyricsDisplay Lyrics={Lyrics}
                        firstTime={firstTime}
                        startTime={startTime}
                        delai={delai} Taille={configurationRadio?.lyricsSize}
                        Color={configurationRadio?.lyricsColor} Police={configurationRadio?.lyricsPolice}

                        slideImagesTime={slideImagesTime}

                        currentSlide={currentSlide}
                        setPreviousSlide={setPreviousSlide}

                        dataSlideShowEffect={dataSlideShowEffect}

                        setCurrentSlideShowEffect={setCurrentSlideShowEffect}

                        dataTransitionEffect={dataTransitionEffect}

                        setCurrentSlide={setCurrentSlide}

                        durationTransition={durationTransition}

                        setCurrentTransitionEffect={setCurrentTransitionEffect}

                        configurationRadio={configurationRadio}

                        // musicInfo={musicInfo}

                        zettaData={zettaData}

                    // duree={parseInt((Lyrics[Lyrics.length - 1]?.milliseconds) / 1000)}
                    />

                    :
                    // <p className={`text-center lyric-text`} style={{ fontSize: `${80}px`, color: "white" }}>..."PAROLES AU PROCHAIN TITRE" ...</p>

                    lastArtiste && <p className={`text-center lyric-text`} style={{ fontSize: `${80}px`, color: "white" }}>... PAROLES AU PROCHAIN TITRE ...</p>
                    }

                </div>

            </>}
            {(!etatLoading && !loading) && <audio autoPlay loop>
                <source src={streamUrl} type="audio/mpeg" />
            </audio>}

            {etatLoading &&
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <img alt='' className='animation-disk' src={disque} height={500} width={500} />
                </div>}
        </div>
    );
};

export default NewAnimation;