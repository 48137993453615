import React, { useState, useEffect } from 'react';
// import { DateTime } from 'luxon';
import WebFont from 'webfontloader';
import './Lyrics.css'
import Countdown from './components/Countdown';
// import Chrono from './RecordScreen';

const LyricsDisplay = (props) => {
    // const [currentLine, setCurrentLine] = useState(-1);
    const [currentLine, setCurrentLine] = useState(0);

    const [catchUp, setCatchUp] = useState(false)
    const [catchUpTime, setCatchUpTime] = useState(1000)
    const [firstLine, setFirstLine] = useState(false)
    const [countdownstate, setcountdownstate] = useState(false)



    useEffect(() => {
        setCurrentLine(0);
        setFirstLine(false)
        setcountdownstate(false)
        console.log("nouveau lyrics");
    }, [props.Lyrics])


    useEffect(() => {
        let lineInterval;
        if (currentLine === 0) {
            const duration = (parseInt(props.Lyrics[0].milliseconds) - 3000) || 3000
            lineInterval = setTimeout(() => {
                console.log("Count down .....", duration);
                setcountdownstate(true)
            }, duration);

            return () => clearTimeout(lineInterval);
        }
    }, [props.Lyrics, currentLine]);

    useEffect(() => {
        let lineInterval;
        if (currentLine === 0) {
            const duration = parseInt(props.Lyrics[0].milliseconds) || 3000
            lineInterval = setTimeout(() => {
                console.log("line 1 .....", duration);
                setFirstLine(true)
            }, duration);

            return () => clearTimeout(lineInterval);
        }
    }, [props.Lyrics, currentLine]);


    function slideAnimation() {
        props.setPreviousSlide(props.currentSlide)
        const currentSlideEffect = props.dataSlideShowEffect[Math.floor(Math.random() * props.dataSlideShowEffect.length)];
        props.setCurrentSlideShowEffect(currentSlideEffect);
        // console.log("currentSlideEffect", currentSlideEffect);

        const currentTransitionEffect = props.dataTransitionEffect[Math.floor(Math.random() * props.dataTransitionEffect.length)];
        // console.log("currentTransitionEffect", currentTransitionEffect);
        props.setCurrentTransitionEffect(currentTransitionEffect);

        props.setCurrentSlide((prevSlide) => {
            const nextSlide = (prevSlide + 1) % props.slideImagesTime.length;
            return nextSlide;
        });
    }

    useEffect(() => {
        let interval;
        if (currentLine >= 0 && currentLine < props.Lyrics.length) {
            // let catchUpDuration
            let duration

            // latence à soustraire par parole pour afficher un peu en avance
            const latenceFirstLine = 0

            // rattraper le temps de la parole à afficher en cas d'un reload ou du premier lancement
            // if (catchUp && currentLine < (props.Lyrics.length - 1) && currentLine > 0) {
            //     // alert(catchUp)
            //     catchUpDuration = (parseInt(props.Lyrics[currentLine + 1].milliseconds) - parseInt(catchUpTime))
            //     setCatchUp(false)
            //     console.log("time and catching up......", catchUpDuration, parseInt(props.Lyrics[currentLine + 1].milliseconds), catchUpTime, props.Lyrics[currentLine].lrc_timestamp)
            //     duration = catchUpDuration
            // }


            // si non calculer le temps en fonction de la structure de parole
            // else {
            const duree = currentLine === 0 ? parseInt(props.Lyrics[currentLine + 1].milliseconds) - latenceFirstLine : 1000
            const dureeMidle = (currentLine < props.Lyrics.length - 1 && currentLine > 0) ? (parseInt(props.Lyrics[currentLine + 1].milliseconds) - parseInt(props.Lyrics[currentLine].milliseconds)) : 1000
            const dureeEnd = currentLine === props.Lyrics.length - 1 ? parseInt(props.Lyrics[currentLine].duration) + (parseInt(props.Lyrics[currentLine].milliseconds) - parseInt(props.Lyrics[currentLine - 1].milliseconds)) + 10000 : 1000

            duration = currentLine <= 0 ? duree : (currentLine > 0 && currentLine !== props.Lyrics.length - 1 ? dureeMidle : dureeEnd);
            // }

            // console.log("duration: ", duration, props.Lyrics[currentLine].lrc_timestamp);
            interval = setTimeout(() => {
                // console.log("next", props.Lyrics[currentLine].line);
                console.log("duration.....", duration);
                if (duration > 1500) { slideAnimation() }
                setCurrentLine(currentLine + 1);
            }, duration);

            return () => clearTimeout(interval);
        }
        // console.log('prooooooooooooops', props.Color);
    }, [currentLine, props.Lyrics, catchUp, catchUpTime]);


    useEffect(() => {
        if (props.Police) {
            WebFont.load({
                google: {
                    families: [props.Police]
                }
            });
        }
    }, [props.Police]);




    // animation slideShow
    // useEffect(() => {
    //     if (props.slideImagesTime.length !== 0) {
    //         const slideInterval = setInterval(() => {
    //             props.setPreviousSlide(props.currentSlide)
    //             const currentSlideEffect = props.dataSlideShowEffect[Math.floor(Math.random() * props.dataSlideShowEffect.length)];
    //             props.setCurrentSlideShowEffect(currentSlideEffect);
    //             // console.log("currentSlideEffect", currentSlideEffect);

    //             const currentTransitionEffect = props.dataTransitionEffect[Math.floor(Math.random() * props.dataTransitionEffect.length)];
    //             // console.log("currentTransitionEffect", currentTransitionEffect);
    //             props.setCurrentTransitionEffect(currentTransitionEffect);

    //             props.setCurrentSlide((prevSlide) => {
    //                 const nextSlide = (prevSlide + 1) % props.slideImagesTime.length;
    //                 return nextSlide;
    //             });
    //         }, props.durationTransition * 1000);

    //         return () => clearInterval(slideInterval);
    //     }
    // }, [props.durationTransition, props.slideImagesTime, props.dataSlideShowEffect, props.currentSlide, props.dataTransitionEffect, currentLine]);


    return (
        <div className='px-5 container'
            style={{ height: "100%", display: "flex", alignItems: 'center', justifyContent: 'center' }}
        >
            {/* <Chrono />*/}

            {countdownstate && <Countdown />}

            <br />
            {props.Lyrics.map((lyric, index) => (
                <div
                    key={index}
                    className='text-center'
                    style={{
                        display: currentLine === index ? 'block' : 'none',
                        color: 'white'
                    }}
                >
                    {/* <p className="text-center lyric-text" style={{ fontSize: `${props.Taille}px`, color: props.Color, fontFamily: props.Police }}>{lyric.line}</p> */}
                    {/* {index > 0 && index < props.Lyrics.length - 1 ? */}
                    <>
                        {/* <p className="text-center lyric-text previous" style={{ fontSize: `${props.Taille}px`, color: props.Color, fontFamily: props.Police }}>{props.Lyrics[index - 1]?.line}</p> */}
                        <p className={`text-center lyric-text ${firstLine ? "current" : "next"}`} style={{ fontSize: `${props.Taille}px`, color: firstLine ? props.Color : props.configurationRadio.lyricsColorN, fontFamily: props.Police }}>{lyric.line}</p>
                        <p className="text-center lyric-text next" style={{ fontSize: `${props.Taille - 10}px`, color: props.configurationRadio.lyricsColorN, fontFamily: props.Police }}>{props.Lyrics[index + 1]?.line}</p>

                    </>
                    {/* : <p className="text-center lyric-text" style={{ fontSize: `${props.Taille + 10}px`, color: "green", fontFamily: props.Police }}>{lyric.line}</p> */}
                    {/* } */}
                </div>
            ))}
        </div>
    );
};

export default LyricsDisplay;
