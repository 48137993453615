import React, { useState, useEffect, useRef } from 'react';
// import './Style.css';
import axios from 'axios';
// import disque from "../../assets/images/disque.png";
import disque from "../../../slidesShow/assets/images/disque.png";
import { linkHome } from '../../../axios-client';
import '../../../slidesShow/components/newAnimations/Lyrics.css'
import Countdown from '../../../slidesShow/components/newAnimations/components/Countdown';



const ShowLyrics = (props) => {
    const [currentLine, setCurrentLine] = useState(0);
    const [firstLine, setFirstLine] = useState(false)
    const [countdownstate, setcountdownstate] = useState(false)

    useEffect(() => {
        let interval;
        if (currentLine >= 0 && currentLine < props.Lyrics.length) {
            let duration

            // latence à soustraire par parole pour afficher un peu en avance
            const latenceFirstLine = 0

            const duree = currentLine === 0 ? parseInt(props.Lyrics[currentLine + 1].milliseconds) - latenceFirstLine : 1000
            const dureeMidle = (currentLine < props.Lyrics.length - 1 && currentLine > 0) ? (parseInt(props.Lyrics[currentLine + 1].milliseconds) - parseInt(props.Lyrics[currentLine].milliseconds)) : 1000
            const dureeEnd = currentLine === props.Lyrics.length - 1 ? parseInt(props.Lyrics[currentLine].duration) + (parseInt(props.Lyrics[currentLine].milliseconds) - parseInt(props.Lyrics[currentLine - 1].milliseconds)) + 10000 : 1000

            duration = currentLine <= 0 ? duree : (currentLine > 0 && currentLine !== props.Lyrics.length - 1 ? dureeMidle : dureeEnd);

            // console.log("duration: ", duration, props.Lyrics[currentLine].lrc_timestamp);
            interval = setTimeout(() => {
                setCurrentLine(currentLine + 1);
            }, duration);

            return () => clearTimeout(interval);
        }
        // console.log('prooooooooooooops', props.Color);
    }, [currentLine, props.Lyrics]);




    useEffect(() => {
        let lineInterval;
        if (currentLine === 0) {
            const duration = (parseInt(props.Lyrics[0].milliseconds) - 3000) || 3000
            lineInterval = setTimeout(() => {
                console.log("Count down .....", duration);
                setcountdownstate(true)
            }, duration);

            return () => clearTimeout(lineInterval);
        }
    }, [props.Lyrics, currentLine]);

    useEffect(() => {
        let lineInterval;
        if (currentLine === 0) {
            const duration = parseInt(props.Lyrics[0].milliseconds) || 3000
            lineInterval = setTimeout(() => {
                console.log("line 1 .....", duration);
                setFirstLine(true)
            }, duration);

            return () => clearTimeout(lineInterval);
        }
    }, [props.Lyrics, currentLine]);



    return (
        <div className='px-5 container'
            style={{ height: "100%", display: "flex", alignItems: 'center', justifyContent: 'center' }}
        >
            {/* <Chrono />
            <br /> */}
            {countdownstate && <Countdown />}

            {props.Lyrics.map((lyric, index) => (
                <div
                    key={index}
                    className='text-center'
                    style={{
                        display: currentLine === index ? 'block' : 'none',
                        color: 'white'
                    }}
                >
                    {/* <p className="text-center lyric-text" style={{ fontSize: `${props.Taille}px`, color: props.Color, fontFamily: props.Police }}>{lyric.line}</p> */}
                    {/* {index > 0 && index < props.Lyrics.length - 1 ? */}
                    <>
                        <p className={`text-center lyric-text ${firstLine ? "current" : "next"}`} style={{ fontSize: `${50}px`, color: firstLine ? "yellow" : "white", fontFamily: "futura" }}>{lyric.line}</p>
                        <p className="text-center lyric-text next" style={{ fontSize: `${40}px`, color: "white", fontFamily: "futura" }}>{props.Lyrics[index + 1]?.line}</p>


                    </>
                    {/* // : <p className="text-center lyric-text" style={{ fontSize: `${50}px`, color: "green", fontFamily: "futura" }}>{lyric.line}</p> */}
                    {/* } */}
                </div>
            ))}
        </div>
    );
};


const MusicLyrics = (props) => {
    // const { id } = useParams();
    // const idRadio = parseInt(id);


    const [loading, setLoading] = useState(true);

    const [Lyrics, setLyrics] = useState([]);


    const audioRef = useRef(null);


    const [lyricsAllowed, setLyricsAllowed] = useState(false);




    const fetchData = async () => {
        try {
            const lyrics = await axios.get(`${linkHome}/lyricsChecking/?titre=${props.titre}&artiste=${props.artiste}`);
            setLyricsAllowed(lyrics.data.hasLyrics)
            const filteredLyrics = lyrics.data.lyrics.filter(lyric => lyric.duration && lyric.milliseconds);
            setLyrics(filteredLyrics)

            console.log("stream link ", lyrics.data);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            // setLoading(false);
        }
    };



    useEffect(() => {
        fetchData();
    }, []);




    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                background: "linear-gradient(71deg, rgba(75,47,97,1) 0%, rgba(38,23,48,1) 53%, rgba(28,17,36,1) 100%)",
                paddingTop: 20

            }}
        >


            {(!loading && lyricsAllowed) ? <>

                <div style={{

                    // position: 'absolute',
                    // top: '15%',
                    // left: '50%',
                    // transform: 'translate(-50%, -50%)'
                    height: "20%",
                    display: 'flex',
                    justifyContent: 'center'

                }}>
                    <img
                        alt=''
                        className='animation-disk' src={disque} height={200} width={200} />
                </div>


                <div style={{
                    width: "100%",
                    height: "80%",
                    display: "flex", alignItems: 'center', justifyContent: 'center',
                    // overflow: "hidden",
                    // backgroundColor: "red"
                }}>
                    <ShowLyrics Lyrics={Lyrics}
                    />


                </div>
                <audio ref={audioRef} key={props.music} autoPlay >
                    <source src={props.music} type="audio/mpeg" />
                </audio>

            </> : <div style={{
                bottom: 0,
                width: "100%",
                height: "100%",
                display: "flex", alignItems: 'center', justifyContent: 'center',
                overflow: "hidden",
                fontSize: "80px"
                // backgroundColor: "red"
            }}>
                <p>PAS DE LYRICS POUR CE TITRE !!!!</p>
            </div>}


            {loading &&
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <img
                        alt=''
                        className='animation-disk' src={disque} height={500} width={500} />
                </div>}
        </div>
    );
};

export default MusicLyrics;
