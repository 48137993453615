import React, { useState, useEffect, useRef } from 'react';
import './Style.css';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import disque from "../../assets/images/disque.png";
import { linkHome } from '../../../axios-client';
import LyricsDisplay from './Lyrics';
import WebFont from 'webfontloader';
// import { DateTime } from 'luxon';
import CryptoJS from 'crypto-js';
import SlideAnimation from './components/SlideAnimation';
import NextSlideTransition from './components/NextSlideTransition';
// import video from '../../assets/images/video.mp4'
// import VideoPub from './components/VideoPub';
// import LecteurDailymotion from './components/LecteurDailymotion';

const NewAnimation = (props) => {
    // const { id } = useParams();
    // console.log("IDIDIDIDIDI : ", new URLSearchParams(useLocation().search).get('r'));
    // const idRadio = new URLSearchParams(useLocation().search).get('r')
    const idRadio = parseInt(CryptoJS.AES.decrypt(
        CryptoJS.enc.Base64.parse(new URLSearchParams(useLocation().search).get('r')).toString(CryptoJS.enc.Utf8),
        'secret-key').toString(CryptoJS.enc.Utf8));
    // console.log("idRadioidRadioidRadioidRadio : ", idRadio);
    // console.log("loggggggggggggggggg.......");

    const [currentSlide, setCurrentSlide] = useState(0);

    const [previousSlide, setPreviousSlide] = useState(0);
    const [currentTransitionEffect, setCurrentTransitionEffect] = useState('fondu');
    const [currentSlideShowEffect, setCurrentSlideShowEffect] = useState('zoom-out');

    const [slideImages, setSlideImages] = useState([]);
    const [logoRadio, setLogoRadio] = useState();
    const [configurationRadio, setConfigurationRadio] = useState();
    const [durationTransition, setDurationTransition] = useState(10);
    const [dataTransitionEffect, setDataTransitionEffect] = useState([]);
    const [dataSlideShowEffect, setDataSlideShowEffect] = useState([]);
    const [etatLoading, setEtatLoading] = useState(true);
    const [slideImagesTime, setSlideImagesTime] = useState([]);
    const [lastTitle, setLastTitle] = useState('');
    const [lastArtiste, setLastArtiste] = useState('');
    const [lastAlbumCover, setLastAlbumCover] = useState('');
    const [defaultSleeve, setDefaultSleeve] = useState('');
    const [copyright, setCopyright] = useState([]);

    // const [streamUrl, setStreamUrl] = useState();
    const [loading, setLoading] = useState(true);
    const [end, setEnd] = useState(true);

    const [rotateLogo, setRotateLogo] = useState(false);


    const [showPub, setShowPub] = useState(false);

    const [titleCount, setTitleCount] = useState(1);


    // const [updateStartTime, setUpdateStartTime] = useState(true);

    const [Lyrics, setLyrics] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [nextTitleStartTime, setNextTitleStartTime] = useState(20000);
    const [jingleTime, setJingleTime] = useState(5000);
    const [pubTime, setPubTime] = useState(0);

    const [nextTitle, setNextTitle] = useState({});
    const [musicData, setMusicData] = useState([]);
    const [actualMusic, setActualMusic] = useState("");
    const [actualJingle, setActualJingle] = useState("");

    const [firstTime, setFirstTime] = useState(true)
    // const [audioElapsedTime, setAudioElapsedTime] = useState()

    const audioRef = useRef(null);


    const delai = 0
    const fetchAgainTime = 2000



    const [lyricsAllowed, setLyricsAllowed] = useState(false);

    function calculateNextFetchTime(nextTime, currentStartTime, duree) {
        // const formattedAssetStartTime = nextTime.replace(' ', 'T');
        // const startingTime = DateTime.fromISO(formattedAssetStartTime, { zone: 'Europe/Paris' }).toMillis();
        // const currentTime = DateTime.now().setZone('Europe/Paris').toMillis();

        // const formattedAssetcurrentAudioStart = currentStartTime.replace(' ', 'T');
        // const currentAudioStart = DateTime.fromISO(formattedAssetcurrentAudioStart, { zone: 'Europe/Paris' }).toMillis();

        // let elapsed = startingTime - currentTime;

        // const audioElapsed = currentTime - currentAudioStart;

        // if (elapsed <= 0) {
        //     elapsed = 20000

        //     // pour eviter le recalcul de rattrapage paroles apres les 20s
        //     setUpdateStartTime(false)

        // }
        // else {
        //     setUpdateStartTime(true)

        // }

        // console.log("lancé : ", startTime, currentTime, nextTime, elapsed);
        console.log("dans ", (duree * 1000) + delai, " secondes");
        // elapsed += delai
        setNextTitleStartTime(duree * 1000 + delai)

        // setAudioElapsedTime(audioElapsed / 1000)

        return duree * 1000

    }

    async function updateSlideOnTime(data) {
        console.log("updating slides on time......");
        setLastTitle(data.titre);
        setLastArtiste(data.artist);
        setLastAlbumCover(data.album_cover);
        // setFirstTime(false)

        // Mise à jour du start Time uniquement lorsqu'on a les donnée du prochain titre depuis l'api du client
        // if (updateStartTime === true) {

        console.log("updating startingTime again...");
        setPreviousSlide(0)
        setCurrentSlide(0)

        setActualMusic(data.music)
        // setActualJingle(data.jingle)
        console.log('actual music  updated', data.music);
        const filteredLyrics = data.lyrics.filter(lyric => lyric.duration && lyric.milliseconds);
        setLyrics(filteredLyrics)

        setStartTime(data.startTime)

        // copyright
        setCopyright(data.copyrights)

        // }
        // ....

        // console.log("hey here is my test ..........");
        if (data?.musicImages.length > 0) {
            if (data?.musicImages.length === 1) {
                // concatener avec les liste d'images par defaut
                const array = slideImages.length !== 0 ? data.musicImages.concat(slideImages) : [data.musicImages[0], logoRadio]
                // console.log("array: ", array, slideImages);
                setSlideImagesTime(array);
            } else {
                setSlideImagesTime(data.musicImages);
            }
        } else {
            setSlideImagesTime(slideImages);
        }
    }


    async function musicDataProcessing() {
        try {
            setEnd(false)
            setShowPub(false)
            console.log("next data processing");
            const data = (musicData[0].titre !== lastTitle) ? musicData[0] : musicData[1] //recuperer la premiere liste de donnée
            console.log("next data: ", musicData, data, "lasttitle: ", lastTitle, "data0", musicData[0].titre);
            await updateSlideOnTime(data)
            const next = parseInt(data.duree * 1000) > 0 ? parseInt(data.duree * 1000) + delai : fetchAgainTime
            console.log("nextTitleStartTime: ", next);
            setNextTitleStartTime(next)

            // jingle correspondant à celui du prochain titre
            const nextJingle = parseInt(musicData[1].jingleDuree * 1000) > 5000 ? parseInt(musicData[1].jingleDuree * 1000) : 5000
            console.log("nextJingle: ", nextJingle);
            setJingleTime(nextJingle)

            // const response = await fetch(`${linkHome}/getImageByRadio/?radioId=${idRadio}&lastTitle=${lastTitle}`);
            // let donnee = await response.json()
            // // console.log("donnee[0].nextTitleStartTime", donnee[0].nextTitleStartTime);
            // if (donnee[0].nextTitleStartTime !== "") {
            //     setMusicData(donnee)
            //     // calculateNextFetchTime(donnee[0].nextTitleStartTime, donnee[0].startTime, donnee[0].duree)
            // }
            // else {
            //     // mettre afin de ne pas rendre le lyrics à nouveau
            //     setUpdateStartTime(false)
            //     console.log("updating to false");
            //     // ....

            //     setNextTitleStartTime(5000)

            // }
            // console.log("new donnee: ", donnee);
            // refaire la requete 5s apres pour compenser les latence en cas de non actualisation du server client à temps
            // setNextTitleStartTime(5000)


        }
        catch (error) {
            console.log("and error occured", error);
        };
    }



    const fetchDataWithTime = async (default_images) => {
        try {
            const response = await fetch(`${linkHome}/getImageByRadio/?radioId=${idRadio}&lastTitle=${lastTitle}`);
            let data = await response.json()
            console.log("next", data);

            setMusicData(data)
            setNextTitle(data[1])
            const current_data = data[0] //recuperer la premiere liste de donnée

            // const rndomMusic = listMusic[Math.floor(Math.random() * listMusic.length)];
            setActualMusic(current_data.music)
            setActualJingle(current_data.jingle)

            // copyright
            setCopyright(current_data.copyrights)


            console.log('actual music ', current_data.music);

            setEnd(false)

            if (current_data.titre && current_data.isDataChanged) {
                setLastTitle(current_data.titre);
                setLastArtiste(current_data.artist);
                setLastAlbumCover(current_data.album_cover);
                console.log("current_data lyrics ", current_data.lyrics);
                // setLyrics(data.lyrics)
                const filteredLyrics = current_data.lyrics.filter(lyric => lyric.duration && lyric.milliseconds);
                setLyrics(filteredLyrics)
                setStartTime(current_data.startTime)

                // if (data.nextTitleStartTime !== "")
                //     calculateNextFetchTime(data.nextTitleStartTime, data.startTime, data.duree)
                const next = parseInt(current_data.duree * 1000) + delai > 0 ? parseInt(current_data.duree * 1000) + delai : fetchAgainTime
                setNextTitleStartTime(next)
                console.log("Durée........: ", next);

                // jingle correspondant à celui du prochain titre
                const nextJingle = parseInt(data[1].jingleDuree * 1000) > 5000 ? parseInt(data[1].jingleDuree * 1000) : 5000
                console.log("nextJingle: ", nextJingle);
                setJingleTime(nextJingle)

                setPreviousSlide(0)
                setCurrentSlide(0)

                if (current_data?.musicImages.length > 0) {
                    if (current_data?.musicImages.length === 1) {
                        // concatener avec les liste d'images par defaut
                        const array = default_images.length !== 0 ? current_data.musicImages.concat(default_images) : [current_data.musicImages[0], logoRadio]
                        // console.log("array: ", array, default_images);
                        setSlideImagesTime(array);
                    } else {
                        setSlideImagesTime(current_data.musicImages);
                    }
                } else {
                    // alert('ok')
                    // console.log('IMAGE défauts : ',default_images);
                    setSlideImagesTime(default_images);
                }

                setEtatLoading(false);

            } else if (!current_data.titre && current_data.isDataChanged) {
                if (current_data?.musicImages.length) {
                    setSlideImagesTime(current_data.musicImages);
                } else {
                    setSlideImagesTime(default_images);
                }

                setLastAlbumCover(current_data.album_cover);
                setSlideImagesTime(default_images)
                // setLyrics(data.lyrics)
                const filteredLyrics = current_data.lyrics.filter(lyric => lyric.duration && lyric.milliseconds);
                setLyrics(filteredLyrics)
                setStartTime(current_data.startTime)

                setEtatLoading(false);

            }
        }
        catch (error) {
            console.log("and error occured", error);
        };

    };


    const fetchData = async () => {
        try {
            const logoRes = await axios.get(`${linkHome}/radios/${idRadio}/`);
            setLogoRadio(logoRes.data.logo);
            setLastAlbumCover(logoRes.data.logo)
            props.setStreamUrl(logoRes.data.audioLinkStream);
            setLyricsAllowed(logoRes.data.allowLyrics)
            setRotateLogo(logoRes.data.logoBehavior)
            // console.log("stream link ", logoRes.data.allowLyrics);

            const imagesRes = await axios.get(`${linkHome}/default_images/${idRadio}/`);
            setSlideImages(imagesRes.data);
            setSlideImagesTime(imagesRes.data || []);
            const configRes = await axios.get(`${linkHome}/current_config/?radio=${idRadio}`);
            setConfigurationRadio(configRes.data);
            setDataTransitionEffect(configRes.data?.transitionEffect);
            setDurationTransition(configRes.data?.transitionDuration);
            setDataSlideShowEffect(configRes.data?.slideShowEffect);
            setCurrentTransitionEffect(configRes.data?.transitionEffect[0]);
            setCurrentSlideShowEffect(configRes.data?.slideShowEffect[0]);
            console.log("configRes.data?.sleeve", configRes.data?.sleeve);
            setDefaultSleeve(configRes.data?.sleeve)

            await fetchDataWithTime(imagesRes.data);

            setLoading(false);


        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };


    const getFileName = (url) => {
        try {
            return url.split("\\").pop().split("_").pop();
        }
        catch (error) {
            return ""
        }

    };



    useEffect(() => {
        fetchData();
    }, []);


    async function reloading() {
        if ((musicData[0]?.titre === "" && musicData[1]?.titre === "") || (musicData[0].titre === musicData[1]?.titre)) {
            console.log("reloding the page............");
            window.location.reload()
        }
    }

    // mise à jour de data music
    useEffect(() => {
        async function updatingNewData() {
            console.log("updating.......");
            const response = await fetch(`${linkHome}/getImageByRadio/?radioId=${idRadio}&lastTitle=${lastTitle}`);
            let donnee = await response.json()
            // console.log("donnee[0].nextTitleStartTime", donnee[0].nextTitleStartTime);
            if (donnee[0].nextTitleStartTime !== "") {
                setMusicData(donnee)


                const next_data = (donnee[0].titre !== lastTitle) ? donnee[0] : donnee[1] //recuperer la premiere liste de donnée

                setNextTitle(next_data)
                console.log("updating sucessfull");

            }
            else {

                console.log("updating to false");

            }
            console.log("updated data: ", donnee);
        }

        if (!loading && idRadio) {
            // const MusicDataSlideTime = setInterval(async () => {
            updatingNewData()
            // }, 120000); //chaque 120s/2mn

            // return () => clearInterval(MusicDataSlideTime
            // );
        }
    }, [loading, idRadio, lastTitle]);



    // transition pour le jingle
    useEffect(() => {
        if (!loading) {
            const intervalEnd = setInterval(async () => {

                // relancer en cas de soucis de données
                await reloading()

                console.log("changing jingle");
                // const data = (musicData[0].music === actualMusic && musicData[0].titre !== "") ? musicData[0] : musicData[1] //recuperer la premiere liste de donnée
                setActualJingle(musicData[1].jingle)

                setTitleCount(titleCount + 1)
                console.log("title count: ", titleCount);

                setEnd(true)
                setFirstTime(false)
                console.log("enddd................");
            }, nextTitleStartTime);

            return () => clearInterval(intervalEnd);
        }
    }, [nextTitleStartTime, musicData]);


    // publicité apres le jingle
    // useEffect(() => {
    //     if (!loading) {
    //         const intervalPub = setInterval(async () => {
    //             console.log("setting up the pub..");
    //             setShowPub(true)
    //             console.log("pb ................");
    //         }, nextTitleStartTime + jingleTime);

    //         return () => clearInterval(intervalPub);
    //     }
    // }, [nextTitleStartTime, musicData]);



    // titre suivant
    useEffect(() => {
        if (!loading) {
            console.log("musicData changed but not likely nextTitleStartTime");
            const interval = setInterval(async () => {
                await musicDataProcessing();
            }, nextTitleStartTime + jingleTime + pubTime);

            return () => clearInterval(interval);
        }
    }, [loading, slideImages, logoRadio, musicData, nextTitleStartTime, jingleTime]);





    // animation slideShow
    // useEffect(() => {
    //     if (!loading && !etatLoading && slideImagesTime.length !== 0) {
    //         const slideInterval = setInterval(() => {
    //             setPreviousSlide(currentSlide)
    //             const currentSlideEffect = dataSlideShowEffect[Math.floor(Math.random() * dataSlideShowEffect.length)];
    //             setCurrentSlideShowEffect(currentSlideEffect);
    //             // console.log("currentSlideEffect", currentSlideEffect);

    //             const currentTransitionEffect = dataTransitionEffect[Math.floor(Math.random() * dataTransitionEffect.length)];
    //             // console.log("currentTransitionEffect", currentTransitionEffect);
    //             setCurrentTransitionEffect(currentTransitionEffect);

    //             setCurrentSlide((prevSlide) => {
    //                 const nextSlide = (prevSlide + 1) % slideImagesTime.length;
    //                 return nextSlide;
    //             });
    //         }, durationTransition * 1000);

    //         return () => clearInterval(slideInterval);
    //     }
    // }, [durationTransition, loading, slideImagesTime, dataSlideShowEffect, currentSlide, dataTransitionEffect, etatLoading]);


    // catching up the audio time
    // useEffect(() => {
    //     const audio = audioRef.current;
    //     if (audio && firstTime && audioElapsedTime) {
    //         audio.currentTime = audioElapsedTime; // Set the current time to 600 ms (0.6 seconds)
    //         audio?.play();
    //         setFirstTime(false)
    //         console.log("audio catched up", audioElapsedTime);
    //     }
    // }, [firstTime, audioElapsedTime]);

    useEffect(() => {
        if (configurationRadio?.titlePolice) {
            WebFont.load({
                google: {
                    families: [configurationRadio?.titlePolice]
                }
            });
        }
    }, [configurationRadio?.titlePolice]);

    useEffect(() => {
        if (configurationRadio?.artistPolice) {
            WebFont.load({
                google: {
                    families: [configurationRadio?.artistPolice]
                }
            });
        }
    }, [configurationRadio?.artistPolice]);


    const renderSlides = () => {
        return slideImagesTime.map((slideImage, index = currentSlide) => {
            return (
                <div style={{ position: 'relative' }} className={``} key={index}>

                    {/* <div style={{ position: 'absolute', top: 5, left: 0, color: 'white', zIndex: 3, width: '100vw', }}>
                        <div style={{
                            display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', color: 'white'
                        }}>
                            © 2024. Tous droits réservés. 77777777777777777 {currentSlide}

                        </div>
                    </div> */}
                    <div style={{ position: 'absolute', top: 5, right: 5, color: 'white', zIndex: 3, height: '100vh', }}>
                        <div style={{
                            display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center', color: 'white'
                        }}>
                            {<span style={{ writingMode: 'vertical-rl', textOrientation: 'mixed' }}>
                                {/* © 2024. Tous droits réservés. */}
                                {copyright[getFileName(slideImagesTime[currentSlide])]}
                            </span>}

                        </div>
                    </div>

                    <div
                        className={`${currentTransitionEffect} ${currentSlide === index && `${currentTransitionEffect}-checked`}`}
                    >
                        <div style={{
                            backgroundImage: `url(${slideImagesTime[index]?.replace(/\\/g, '/') || slideImages[0]})`,
                            // background: `yellow`,
                            position: "absolute",
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            width: "120%",
                            height: "120%",
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            // filter: 'blur(12px)',
                            filter: ` ${configurationRadio?.blackWhiteImage ? 'grayscale(1) blur(12px)' : ' grayscale(0) blur(12px)'}`,

                            zIndex: -3,
                            objectFit: 'cover',
                        }}
                        ></div>

                        <img
                            alt=''
                            className={`image ${currentSlide === index && `image-effect-${currentSlideShowEffect}`}`}
                            // src={slideImagesTime[index]?.replace(/\\/g, '/')}
                            // onError={handleImageError}
                            src={slideImagesTime[index]?.replace(/\\/g, '/') || slideImages[0]}
                            style={{
                                animation: currentSlide === index && ` ${currentSlideShowEffect} ${durationTransition}s forwards`,
                                filter: configurationRadio?.blackWhiteImage ? 'grayscale(1)' : 'grayscale(0)',
                                objectFit: 'contain',
                                position: 'relative',
                                // display: imageLoaded[index] ? 'block' : 'none',
                                zIndex: 1
                            }}
                        />

                    </div>
                    {(previousSlide === index - 1 || (previousSlide === slideImagesTime.length - 1 && index === 0) || (previousSlide === currentSlide)) &&
                        <div
                            style={{
                                height: '100vh',
                                width: '100%',
                                animation: `${currentTransitionEffect}-out 1s ease-in-out`,
                                zIndex: -1
                            }}
                        // className={`combiner2-checked`}

                        >

                            <div style={{
                                backgroundImage: previousSlide === currentSlide ? `url(${slideImagesTime[previousSlide]?.replace(/\\/g, '/')})` : `url(${slideImagesTime[previousSlide]?.replace(/\\/g, '/')})`,
                                // backgroundImage: `url(${(currentSlide === 0 ? slideImagesTime[currentSlide] : slideImagesTime[previousSlide])?.replace(/\\/g, '/')})`,
                                // background:'yellow',
                                position: "absolute",
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                                width: "120%",
                                height: "120%",
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                // filter: 'blur(12px)',
                                filter: ` ${configurationRadio?.blackWhiteImage ? 'grayscale(1) blur(12px)' : ' grayscale(0) blur(12px)'}`,
                                zIndex: 0,
                                objectFit: 'cover',
                            }}></div>

                            <img
                                alt=''
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    filter: configurationRadio?.blackWhiteImage ? 'grayscale(1)' : 'grayscale(0)',
                                    objectFit: 'contain',
                                    zIndex: 0
                                }}
                                className={`image`}
                                src={index === 0 ? slideImagesTime[slideImagesTime.length - 1] : slideImagesTime[previousSlide]}
                            // onLoad={() => handleImageLoad(index)}
                            // style={{
                            //     animation: currentSlide === index && ` ${currentSlideShowEffect} ${durationTransition - 2}s forwards`,
                            //     filter: configurationRadio?.blackWhiteImage ? 'grayscale(1)' : 'grayscale(0)',
                            //     objectFit: 'contain',
                            //     position: 'relative',
                            //     display: imageLoaded[index] ? 'block' : 'none',
                            // }}
                            />


                        </div>}

                </div>
            )
        });
    };

    const hexToRgba = (hex, alpha) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };




    return (
        <div className="slide-container"
            style={{
                backgroundColor: "#1C1124",
            }}
        >

            {/* Video pub */}

            {/* {(!loading && showPub) && <> */}
            {/* <LecteurDailymotion videoId="x8yse8i" playerId="xuoyy" /> */}
            {/* <VideoPub key={titleCount} video={video} /> */}
            {/* </>} */}

            {(!loading && !end) && <>

                <SlideAnimation
                    configurationRadio={configurationRadio}
                    Lyrics={Lyrics}
                    lyricsAllowed={lyricsAllowed}
                    lastAlbumCover={lastAlbumCover}
                    defaultSleeve={defaultSleeve}
                    logoRadio={logoRadio}
                    lastTitle={lastTitle}
                    lastArtiste={lastArtiste}
                    nextTitle={nextTitle}
                    rotateLogo={rotateLogo}
                />

            </>
            }

            {(!loading && end && !showPub) &&
                <>
                    <NextSlideTransition
                        disque={disque}
                        nextTitle={nextTitle}
                        logoRadio={logoRadio}
                        defaultSleeve={defaultSleeve}
                    />

                </>
            }


            {(!loading && !etatLoading && !end && !showPub) && <>
                {
                    renderSlides()
                }
                {(Lyrics.length && lyricsAllowed) &&
                    <div style={{
                        backgroundColor: hexToRgba(configurationRadio?.lyricsBgColor, 0.5),
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        height: `${configurationRadio?.lyricsFilterSize}%`,
                        backgroundPosition: "bottom",
                        zIndex: 2,
                        objectFit: 'cover',
                        display: "flex", alignItems: 'center', justifyContent: 'center',
                        overflow: "hidden"
                    }}>
                        <LyricsDisplay Lyrics={Lyrics}
                            //  startTime={startTime}
                            delai={delai} Taille={configurationRadio?.lyricsSize}
                            Color={configurationRadio?.lyricsColor} Police={configurationRadio?.lyricsPolice}

                            slideImagesTime={slideImagesTime}

                            currentSlide={currentSlide}
                            setPreviousSlide={setPreviousSlide}

                            dataSlideShowEffect={dataSlideShowEffect}

                            setCurrentSlideShowEffect={setCurrentSlideShowEffect}

                            dataTransitionEffect={dataTransitionEffect}

                            setCurrentSlide={setCurrentSlide}

                            durationTransition={durationTransition}

                            setCurrentTransitionEffect={setCurrentTransitionEffect}

                            configurationRadio={configurationRadio}

                        // duree={parseInt((Lyrics[Lyrics.length - 1]?.milliseconds) / 1000)}
                        />


                    </div>
                }
            </>}


            {(actualJingle !== "" && !firstTime) && (
                <audio key={actualJingle + titleCount} autoPlay >
                    <source src={actualJingle} type="audio/mpeg" />
                </audio>
            )}

            {actualMusic !== "" && (
                <audio ref={audioRef} key={actualMusic} autoPlay  >
                    <source src={actualMusic} type="audio/mpeg" />
                </audio>
            )}

            {
                (loading) &&
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <img
                        alt=''
                        className='animation-disk' src={disque} height={500} width={500} />
                </div>
            }
        </div >
    );
};

export default NewAnimation;
