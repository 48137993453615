








import React, { useEffect, useState } from 'react'

import {
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardImg,

    ButtonGroup,
    Button,
    Form,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"
import { Modal, ModalHeader, ModalBody, Tooltip } from 'reactstrap';
import { FaCheck, FaEye, FaTrash } from 'react-icons/fa'; // Import the trash icon from react-icons
import loadData from '../../../assets/lottie/laod.json'

import right from '../../../assets/images/right.png'
import left from '../../../assets/images/left.png'
import centerr from '../../../assets/images/centerr.png'

import Dropzone from "react-dropzone"

import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { Divider, Slider, Typography } from '@mui/material';

import Stack from '@mui/material/Stack';
import MUIButton from '@mui/material/Button';
import zoom from "../../../assets/images/animations/zoom.png"
import down from "../../../assets/images/animations/down.png"
import up from "../../../assets/images/animations/up.png"

import fondue from "../../../assets/images/animations/fondue.png"

import scroll from "../../../assets/images/animations/defiler.png"
import aucun from "../../../assets/images/animations/aucun.png"

import countour from "../../../assets/images/animations/countour.png"
import elevation from "../../../assets/images/animations/elevation.png"
import ombres from "../../../assets/images/animations/ombres.png"
import zoomOut from "../../../assets/images/animations/zoomout.png"
import drift from "../../../assets/images/animations/drift.png"
import combine from "../../../assets/images/animations/combine.png"
import role from "../../../assets/images/animations/roll.png"
import snow from "../../../assets/images/snow.jpeg"
import snowc from "../../../assets/images/snowc.jpeg"
import bottomLeft from "../../../assets/images/bottomLeft3.png"
import bottomRight from "../../../assets/images/bottomRight3.png"
import topLeft from "../../../assets/images/topLeft3.png"
import topRight from "../../../assets/images/topRight3.png"
import center from "../../../assets/images/center3.png"

import "./habillage.css"
import { fetchRadioConfigFailure, fetchRadioConfigRequest, fetchRadioConfigSuccess, fetchRadioFailure, fetchRadioRequest, fetchRadioSuccess, fetchSlidesRadioFailure, fetchSlidesRadioRequest, fetchSlidesRadioSuccess } from '../../store/actions/radioActions';
import axiosClient, { link, linkImage } from '../../../axios-client';
import { useStateContext } from '../../../context/ContextProvider';
import Lottie from 'react-lottie';



import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RiSlideshow2Line } from "react-icons/ri";
import PreviewSlide from './Aperçu/PreviewSlide';
import { IoCloseCircleOutline } from 'react-icons/io5';


import { Input } from 'reactstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlickSlider from 'react-slick';
import ImagesSlider from './ImagesSlider';
import LowerSection from './LowerSection';

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        />
    );
};

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        />
    );
};
export default function Habillage() {
    const [tooltipOpen, setTooltipOpen] = useState(null);

    const toggleTooltip = (id) => {
        setTooltipOpen(prevId => prevId === id ? null : id);
    };


    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const notifySuccess = (text) => toast.success(text, {
        position: "top-center",
        autoClose: 400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });

    const notifyError = (text) => toast.error(text, {
        position: "top-center",
        autoClose: 400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const handleAnimationIntensityChange = (event, newValue) => {
        // setdef(newValue); // Update slider value when it changes
        setAnimationIntensity(newValue)
    };
    const handleTransitionDuration = (event, newValue) => {
        // setdefX(newValue); // Update slider value when it changes
        setTransitionDuration(newValue)

    };
    const handleTextEffectInts = (event, newValue) => {
        // setdefY(newValue); // Update slider value when it changes
        setTextEffectIntensity(newValue)
    };

    const [selectedFiles, setselectedFiles] = useState([])

    const [selectedFilesX, setselectedFilesX] = useState([])
    const [selectedFilesY, setselectedFilesY] = useState([])
    const [selectedFilesZ, setselectedFilesZ] = useState([])

    const availableFonts = [
        'ABeeZee',
        'ABeeZee Italic',
        'Actor',
        'Andale Mono',
        'Arial',
        'Arial Black',
        'Arial Bold',
        'Arial Bold Italic',
        'Arial Italic',
        'Barlow',
        'Barlow Black',
        'Barlow Bold',
        'Barlow Bold Italic',
        'Barlow Italic',
        'Comic Sans MS',
        'Comic Sans MS Bold',
        'Courier New',
        'Courier New Bold',
        'Courier New Bold Italic',
        'Courier New Italic',
        'Noto Serif',
        'Noto Serif Italic',
        'Noto Serif Bold',
        'Noto Serif Bold Italic',
        'Noto Serif Black',
        'Oswald',
        'Oswald Bold',
        'Times New Roman',
        'Times New Roman Bold',
        'Times New Roman Bold Italic',
        'Times New Roman Italic',
        'Trebuchet MS',
        'Trebuchet MS Bold',
        'Trebuchet MS Bold Italic',
        'Trebuchet MS Italic',
        'Verdana',
        'Verdana Bold',
        'Verdana Bold Italic',
        'Verdana Italic',

        'FrancoisOne',
        'Futura',
        'Futura Italic',
        'Futura Bold',
        'Futura Bold Italic',
        'Futura Light',
        'Futura Light Italic',
        'Georgia',
        'Georgia Bold',
        'Georgia Bold Italic',
        'Georgia Italic',
        'Gotham',
        'Gotham Bold',
        'Gotham Bold Italic',
        'Gotham Italic',
        'Heebo',
        'Heebo Bold',
        'Impact',
        'Kameron',
        'Kameron Bold'
    ];

    function handleAcceptedFilesY(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFilesY(files)






    }



    const [lengthProhibited, setLengthProhibited] = useState(false)

    useEffect(() => {
        if (selectedFilesY && selectedFilesY.length > 0) {
            const selectedFile = selectedFilesY[0]; // Assuming only one file is selected
            if (!selectedFile) {
                console.error('No file selected');
                return;
            }

            console.log('Selected File:', selectedFile);

            if (selectedFile.name && selectedFile.name.length > 100) {
                notifyError('La taille de la pochette ne doit pas dépasser 100 caractères.');
                setLengthProhibited(true);
                setSleeve(null); // Clear the sleeve if file name is too long
            } else {
                setSleeve(selectedFile);
                setSleeveDisp(true);
                setLengthProhibited(false);
            }

            console.log('Updated Sleeve:', sleeve);

        } else {
            // Clear the sleeve if no files are selected
            setSleeve(cConfig.sleeve);
            setSleeveDisp(false);
            setLengthProhibited(false); // Reset the length prohibition
        }
    }, [selectedFilesY]);



    function handleAcceptedFilesZ(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFilesZ(files)
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const [dropAlign, setDropAlign] = useState(false);
    const [selectedFont, setSelectedFont] = useState("Arial");

    const [dropAlignX, setDropAlignX] = useState(false);
    const [dropAlignP, setDropAlignP] = useState(false);

    const [selectedFontX, setSelectedFontX] = useState("Futura");
    const [selectedFontP, setSelectedFontP] = useState("Futura");

    const [dropAlignRadio, setDropAlignRadio] = useState(false);
    const [selectedRadio, setSelectedRAdio] = useState("Sélectionner une radio");
    console.log("selectedFontXselectedFontX", selectedFontX);

    const [dropAlignZ, setDropAlignZ] = useState(false);
    const [selectedFontZ, setSelectedFontZ] = useState("Futura bold");
    const [shouldIncludeSleeve, setShouldIncludeSleeve] = useState("Futura bold");


    const [dropAlignT, setDropAlignT] = useState(false);
    const [selectedFontT, setSelectedFontT] = useState("Futura ");

    const handleFontSelectX = (font) => {
        setSelectedFontX(font);
        setTitlePolice(font); // Update titlePolice with the selected font

        setDropAlignX(false); // Close the dropdown after selection
    };
    const handleFontSelectP = (font) => {
        setSelectedFontP(font);
        setCopyrightPolice(font); // Update titlePolice with the selected font

        setDropAlignP(false); // Close the dropdown after selection
    };

    const handleFontSelectT = (font) => {
        setSelectedFontT(font);
        setLyricsPolice(font); // Update titlePolice with the selected font

        setDropAlignT(false); // Close the dropdown after selection
    };

    const [radioDataLoad, setRadioDataLoad] = useState(false);

    const handleSelectedRadio = (radioName) => {
        setSelectedRAdio(radioName);
        setselectedFilesZ([]); // Clear selected files when a radio option is selected

        setselectedFilesY([]); // Clear selected files when a radio option is selected
        setDropAlignRadio(false); // Close the dropdown after selection
        // setRadioDataLoad(true);

    };


    console.log("selectedRadioHFL", selectedRadio);

    const [dLogoFlag, setDlogoFlag] = useState(false);

    const handleDeleteImage = () => {
        setselectedFilesZ([]); // Clear selected files when a radio option is selected

        setselectedFilesY([]);
        setDlogoFlag(true)
    }


    console.log("TheSLB", selectedFilesY);
    const handleFontSelectZ = (font) => {
        setSelectedFontZ(font);
        setArtistPolice(font); // Update titlePolice with the selected font
        // setSleeve()
        setDropAlignZ(false); // Close the dropdown after selection
    };





    const { loading, radios, error, slidesRadios, configurations } = useSelector(state => state.radios);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);


    const { user, } = useStateContext();





    useEffect(() => {
        dispatch(fetchRadioRequest());

        // Check if user exists and has an 'id' attribute
        if (!user || !user.id) {
            dispatch(fetchRadioFailure('User ID is missing'));
            return;
        }

        // Extract user ID
        const user_id = user.id;

        axiosClient.get(`targeted_radios/?user=${user_id}`)
            .then(response => {
                dispatch(fetchRadioSuccess(response.data));
                // alert("success");
            })
            .catch(error => {
                dispatch(fetchRadioFailure(error.message));
                // alert("error");
            });
    }, [dispatch, user]);


    console.log("the  user radios", radios);



    let currentSelectedRadio
    if (Array.isArray(radios)) {
        currentSelectedRadio = (radios && selectedRadio) ? radios.find(sc => sc.name === selectedRadio)?.id : null;
        // Rest of the code using currentSelectedRadio
    } else {

        // Handle the case where radios is not defined or not an array
        console.error('Error: radios is not defined or not an array');
    }
    console.log("all the radios", radios);
    // const targetedRadioScreens = slidesRadios ? slidesRadios.filter(sc => sc.radio === currentSelectedRadio).map(sc => sc.id) : [];
    // const targetedRadioScreens = slidesRadios ? slidesRadios.filter(sc => sc.radio === currentSelectedRadio).map(sc => sc.radio) : [];
    useEffect(() => {
        dispatch(fetchRadioConfigRequest()); // Dispatch action to indicate the start of the request

        if (currentSelectedRadio) {
            axiosClient.get(`current_config/?radio=${currentSelectedRadio}`)
                .then(response => {
                    console.log("config data", response.data);
                    dispatch(fetchRadioConfigSuccess(response.data));
                })
                .catch(error => {
                    dispatch(fetchRadioConfigFailure(error.message));
                });
        }
    }, [dispatch, currentSelectedRadio]);

    useEffect(() => {
        dispatch(fetchSlidesRadioRequest()); // Dispatch action to indicate the start of the request

        // Check if currentSelectedRadio is valid
        if (!currentSelectedRadio) {
            dispatch(fetchSlidesRadioFailure('Radio ID is missing'));
            return;
        }

        axiosClient.get(`radio_slides/?radio=${currentSelectedRadio}`)
            .then(response => {
                dispatch(fetchSlidesRadioSuccess(response.data));
            })
            .catch(error => {
                dispatch(fetchSlidesRadioFailure(error.message));
            });
    }, [dispatch, currentSelectedRadio]);
    console.log("the  atrgeted slides", slidesRadios);

    // Function to calculate formatted size of an image
    const [formattedSize, setFormattedSize] = useState('');

    const getFormattedSize = async (imageUrl) => {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error('Failed to fetch image');
        }

        const blob = await response.blob();
        const sizeInBytes = blob.size;

        const units = ['B', 'KB', 'MB', 'GB'];
        let size = sizeInBytes;
        let unitIndex = 0;

        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }

        const formattedSize = `${size.toFixed(2)} ${units[unitIndex]}`;
        return formattedSize;
    };



    const cConfig = configurations ? configurations : null;
    console.log("the  configurations ", cConfig);

    useEffect(() => {
        // Check if radios and selectedRadio are defined and not null
        if (radios && selectedRadio) {
            // Find the radio with the selected name
            const selectedRadioObj = (Array.isArray(radios) ? radios.find(radio => radio.name === selectedRadio) : null) ?? null;

            // Check if selectedRadioObj is defined and has the logo property
            if (selectedRadioObj && selectedRadioObj.logo) {
                // Fetch formatted size for the logo
                getFormattedSize(selectedRadioObj.logo)
                    .then((size) => setFormattedSize(size))
                    .catch((error) => console.error('Error fetching image size:', error));
            }
        }
    }, [radios, selectedRadio]);

    console.log("formattedSizefffffffffffffff", formattedSize);
    const [titleSize, setTitleSize] = useState(10);
    const [copyrightSize, setCopyrightSize] = useState(10);

    const [titlePolice, setTitlePolice] = useState('');
    const [copyrightPolice, setCopyrightPolice] = useState('Arial');
    const [copyrightAlign, setCopyrightAlign] = useState(0);

    const [titleColor, setTitleColor] = useState('#ffffff');
    const [copyrightColor, setCopyrightColor] = useState('##000000');
    const [copyrightContour, setCopyrightContour] = useState('#ffffff');

    const [titlePosition, setTitlePosition] = useState([]);

    const [copyrightPosition, setCopyrightPosition] = useState('bottom'); // Set default to 'bottom' or any other position


    const [walletSize, setWalletSize] = useState(150);
    const [walletPosition, setWalletPosition] = useState([]);
    // console.log("the wallet contains the  position?", walletPosition.includes("bottom"));
    const [artistSize, setArtistSize] = useState(0);
    const [artistPolice, setArtistPolice] = useState('');
    const [artistColor, setArtistColor] = useState('#000000');
    // const [artistPosition, setArtistPosition] = useState([]);

    const [textEffect, setTextEffect] = useState('');
    const [textEffectIntensity, setTextEffectIntensity] = useState(0)


    const [logoSize, setLogoSize] = useState(0);
    const [logoH, setLogoH] = useState(0);

    const [logoW, setLogoW] = useState(0);

    const [walletH, setWalletH] = useState(0);

    const [walletW, setWalletW] = useState(0);

    const [logoPosition, setLogoPosition] = useState([]);


    const [positionInversion, setPositionInversion] = useState(false);
    const [animateOn, setAnimateOn] = useState("");
    const [animationIntensity, setAnimationIntensity] = useState(0)

    const [slideShowEffect, setSlideShowEffect] = useState([]);
    const [transitionEffect, setTransitionEffect] = useState([]);
    const [transitionDuration, setTransitionDuration] = useState(0)
    const [blackWhiteImage, setBlackWhiteImage] = useState(false)


    const [sleeve, setSleeve] = useState("");

    const [lyricsSize, setLyricsSize] = useState(0);
    const [lyricsPolice, setLyricsPolice] = useState('');
    const [lyricsColor, setLyricsColor] = useState('#000000');
    const [lyricsColorN, setLyricsColorN] = useState('#000000');

    const [lyricsBgColor, setLyricsBgColor] = useState('#000000');
    const [lyricsFilterSize, setLyricsFilterSize] = useState(10);

    console.log("cConfig.artistPolic cConfig.artistPolic", cConfig.titlePolice);
    useEffect(() => {
        if (cConfig && cConfig.lyricsBgColor) {
            setLyricsBgColor(cConfig.lyricsBgColor);
        } else {
            setLyricsBgColor("#ffffff");
        }
    }, [cConfig]);

    useEffect(() => {
        if (cConfig && cConfig.radio) {
            setTitleSize(cConfig.titleSize);
            setTitlePolice(cConfig.titlePolice);
            setTitleColor(cConfig.titleColor);
            setCopyrightColor(cConfig.copyrightColor);

            setCopyrightContour(cConfig.copyrightContour);
            setCopyrightPolice(cConfig.copyrightPolice);
            setCopyrightPosition(cConfig.copyrightPosition);
            setCopyrightAlign(cConfig.copyrightAlign);

            setCopyrightSize(cConfig.copyrightSize);

            setTitlePosition(cConfig.titlePosition);
            setArtistSize(cConfig.artistSize);
            setArtistPolice(cConfig.artistPolice);
            setArtistColor(cConfig.artistColor);
            // setArtistPosition(cConfig.artistPosition);
            // setWalletSize(cConfig.walletSize);
            setWalletPosition(cConfig.walletPosition);
            setTextEffect(cConfig.textEffect);
            setTextEffectIntensity(cConfig.textEffectIntensity);
            // setLogoSize(cConfig.logoSize);
            setLogoPosition(cConfig.logoPosition);
            setWalletH(cConfig.walletH)
            setWalletW(cConfig.walletW)
            setLogoH(cConfig.logoH)

            setLogoW(cConfig.logoW)
            setSleeve(cConfig.sleeve)

            setPositionInversion(cConfig.positionInversion);
            setAnimateOn(cConfig.animateOn);
            setAnimationIntensity(cConfig.animationIntensity);
            setSlideShowEffect(cConfig.slideShowEffect);
            setTransitionEffect(cConfig.transitionEffect);
            setTransitionDuration(cConfig.transitionDuration);
            setBlackWhiteImage(cConfig.blackWhiteImage);


            setLyricsBgColor(cConfig.lyricsBgColor)
            setLyricsColor(cConfig.lyricsColor)
            setLyricsColorN(cConfig.lyricsColorN)

            setLyricsPolice(cConfig.lyricsPolice)
            setLyricsSize(cConfig.lyricsSize)
            setLyricsFilterSize(cConfig.lyricsFilterSize)

        } else {
            setTitleSize(10); // Reset newTitleSize if cConfig or titleSize is undefined
            setTitlePolice('Futura'); // Reset newTitleSize if cConfig or titleSize is undefined
            setTitleColor('#000000');
            setTitlePosition([]);
            setArtistSize(10); // Reset newTitleSize if cConfig or titleSize is undefined
            setArtistPolice('Futura'); // Reset newTitleSize if cConfig or titleSize is undefined
            setArtistColor('#000000');
            // setArtistPosition([]);
            // setWalletSize(150); // Reset newTitleSize if cConfig or titleSize is undefined
            setWalletPosition(['bottom', 'left']);

            setCopyrightAlign(0);


            setCopyrightSize(10); // Reset newTitleSize if cConfig or titleSize is undefined
            setCopyrightPolice('Futura'); // Reset newTitleSize if cConfig or titleSize is undefined
            setCopyrightColor('#000000');
            setCopyrightContour('#000000');

            // setArtistPosition([]);
            // setWalletSize(150); // Reset newTitleSize if cConfig or titleSize is undefined
            setCopyrightPosition("left");





            setTitlePosition(['center']);
            setSleeve("")

            setTextEffect('aucun');
            setTextEffectIntensity(0);
            // setLogoSize(150);
            setWalletH(10)
            setWalletW(10)
            setLogoH(10)
            setLogoW(10)
            setLogoPosition(['bottom', 'left']);
            setPositionInversion(false);
            setAnimateOn('');
            setAnimationIntensity(0);
            setSlideShowEffect(['zoom-out']);
            setTransitionEffect(['fondu']);
            setTransitionDuration(10);
            setBlackWhiteImage(false);




            setLyricsBgColor("#ffffff")
            setLyricsColorN("#ffffff")

            setLyricsColor("#000000")
            setLyricsPolice("Futura")
            setLyricsSize(25)
            setLyricsFilterSize(25)
        }
    }, [cConfig]);
    console.log("lddoddoododododd", lyricsColorN);



    console.log("The Copyrights Color", copyrightColor);


    console.log("the  config of v2", cConfig.lyricsPolice);

    console.log("lyricsBgColorlyricsBgColor", lyricsBgColor);
    console.log("titlePositiontitlePositiontitlePosition", titlePosition);
    const [saving, setSaving] = useState(false);


    console.log("lyrics Title2", lyricsBgColor);

    console.log("selectedFontTselectedFontTselectedFontT", selectedFontT);
    const [saveRadioData, setSaveRadioData] = useState(false);


    // useEffect(() => {
    //     setSleeve("https://i.ibb.co/12bHgQ8/bottom-Right.png")

    // }, [])
    // console.log("sleeeeeeve length ", sleeve.name && sleeve.name.length);





    const handleConfirmButtonClick = () => {




        setSaveRadioData(true);
        scrollToTop()
        const validateSize = (size) => {
            if (isNaN(size)) {
                notifyError('Please enter a valid number for size.');
                return false;
            }
            return true;
        };

        if (!validateSize(titleSize) || !validateSize(artistSize) || !validateSize(walletSize) || !validateSize(logoSize)) {
            return;
        }


        if (!cConfig?.id) {

            const formData = new FormData();



            // Function to conditionally clean sleeve
            const cleanSleeve = (formData) => {
                // Check if sleeve needs to be cleaned (set to null or empty)
                if (!shouldIncludeSleeve) { // Replace shouldIncludeSleeve with your logic
                    formData.append('sleeve', ''); // or formData.append('sleeve', null); depending on backend expectation
                } else if (sleeve instanceof File) {
                    formData.append('sleeve', sleeve);
                }
            };

            // Append text fields
            // Append text fields to FormData
            formData.append('titleSize', titleSize);
            formData.append('titlePolice', titlePolice);
            formData.append('titleColor', titleColor);
            formData.append('copyrightColor', copyrightColor);
            formData.append('copyrightContour', copyrightContour);
            formData.append('copyrightSize', copyrightSize);
            formData.append('copyrightPosition', copyrightPosition);
            formData.append('copyrightPolice', copyrightPolice);

            formData.append('copyrightAlign', copyrightAlign);


            // Example: Serialize JSON fields
            formData.append('logoPosition', JSON.stringify(logoPosition));
            formData.append('slideShowEffect', JSON.stringify(slideShowEffect));
            formData.append('titlePosition', JSON.stringify(titlePosition));
            formData.append('transitionEffect', JSON.stringify(transitionEffect));
            formData.append('walletPosition', JSON.stringify(walletPosition));

            formData.append('artistSize', artistSize);
            formData.append('artistPolice', artistPolice);
            formData.append('artistColor', artistColor);
            formData.append('textEffect', textEffect);
            formData.append('textEffectIntensity', textEffectIntensity);
            formData.append('logoH', logoH);
            formData.append('logoW', logoW);
            formData.append('walletH', walletH);
            formData.append('walletW', walletW);
            formData.append('positionInversion', positionInversion);
            formData.append('animateOn', animateOn);
            formData.append('animationIntensity', animationIntensity);
            // formData.append('slideShowEffect', slideShowEffect);
            // formData.append('transitionEffect', transitionEffect);
            formData.append('transitionDuration', transitionDuration);
            formData.append('blackWhiteImage', blackWhiteImage);
            formData.append('lyricsBgColor', lyricsBgColor);
            formData.append('lyricsSize', lyricsSize);
            formData.append('lyricsPolice', lyricsPolice);
            formData.append('lyricsColor', lyricsColor);
            formData.append('lyricsColorN', lyricsColorN);

            formData.append('lyricsFilterSize', lyricsFilterSize);
            formData.append('radio', currentSelectedRadio);




            cleanSleeve(formData)


            axiosClient.post('radio-configurations/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Ensure correct content type for file upload
                }
            })
                .then(response => {
                    setSaving(true);
                    notifySuccess('Nouvelle configuration crée avec succes');
                    setSaveRadioData(false);
                    scrollToTop()
                })
                .catch(error => {
                    console.error('Error creating radio:', error);
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.error('Server responded with:', error.response.data);
                        console.error('Status code:', error.response.status);
                        console.error('Headers:', error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.error('No response received:', error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.error('Error setting up the request:', error.message);
                    }
                    let errorMessage = "Une erreur est survenue lors de la configuration de la radio  !";
                    notifyError(errorMessage);
                    setSaveRadioData(false);
                    scrollToTop();
                });
        } else {
            axiosClient.get(`radio-configurations/${cConfig.id}/`)
                .then(response => {
                    // const currentConfig = response.data;
                    const cleanSleeve = (formData) => {
                        // Check if sleeve needs to be cleaned (set to null or empty)
                        if (!shouldIncludeSleeve) { // Replace shouldIncludeSleeve with your logic
                            formData.append('sleeve', ''); // or formData.append('sleeve', null); depending on backend expectation
                        } else if (sleeve instanceof File) {
                            formData.append('sleeve', sleeve);
                        }
                    };

                    // Create FormData instance
                    const formData = new FormData();

                    // Append text fields to FormData
                    // Append text fields to FormData
                    formData.append('titleSize', titleSize);
                    formData.append('titlePolice', titlePolice);
                    formData.append('titleColor', titleColor);
                    formData.append('copyrightColor', copyrightColor);
                    formData.append('copyrightContour', copyrightContour);
                    formData.append('copyrightSize', copyrightSize);
                    formData.append('copyrightPosition', copyrightPosition);
                    formData.append('copyrightPolice', copyrightPolice);
                    formData.append('copyrightAlign', copyrightAlign);

                    // Example: Serialize JSON fields
                    formData.append('logoPosition', JSON.stringify(logoPosition));
                    formData.append('slideShowEffect', JSON.stringify(slideShowEffect));
                    formData.append('titlePosition', JSON.stringify(titlePosition));
                    formData.append('transitionEffect', JSON.stringify(transitionEffect));
                    formData.append('walletPosition', JSON.stringify(walletPosition));

                    formData.append('artistSize', artistSize);
                    formData.append('artistPolice', artistPolice);
                    formData.append('artistColor', artistColor);
                    formData.append('textEffect', textEffect);
                    formData.append('textEffectIntensity', textEffectIntensity);
                    formData.append('logoH', logoH);
                    formData.append('logoW', logoW);
                    formData.append('walletH', walletH);
                    formData.append('walletW', walletW);
                    formData.append('positionInversion', positionInversion);
                    formData.append('animateOn', animateOn);
                    formData.append('animationIntensity', animationIntensity);
                    // formData.append('slideShowEffect', slideShowEffect);
                    // formData.append('transitionEffect', transitionEffect);
                    formData.append('transitionDuration', transitionDuration);
                    formData.append('blackWhiteImage', blackWhiteImage);
                    formData.append('lyricsBgColor', lyricsBgColor);
                    formData.append('lyricsSize', lyricsSize);
                    formData.append('lyricsPolice', lyricsPolice);
                    formData.append('lyricsColor', lyricsColor);
                    formData.append('lyricsFilterSize', lyricsFilterSize);
                    formData.append('lyricsColorN', lyricsColorN);

                    formData.append('radio', currentSelectedRadio);

                    cleanSleeve(formData)

                    // Send PATCH request using axios
                    axiosClient.patch(`radio-configurations/${cConfig.id}/`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data' // Ensure correct content type for file upload
                        }
                    })
                        .then(response => {
                            setSaving(true);
                            notifySuccess("Configuration radio modifiée avec succès !");
                            setSaveRadioData(false);
                            scrollToTop()
                        })
                        .catch(error => {
                            console.error('Error updating radio:', error);
                            if (error.response) {
                                // The request was made and the server responded with a status code
                                // that falls out of the range of 2xx
                                console.error('Server responded with:', error.response.data);
                                console.error('Status code:', error.response.status);
                                console.error('Headers:', error.response.headers);
                            } else if (error.request) {
                                // The request was made but no response was received
                                console.error('No response received:', error.request);
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                console.error('Error setting up the request:', error.message);
                            }
                            let errorMessage = "Une erreur est survenue lors de la configuration de la radio!";
                            notifyError(errorMessage);
                            setSaveRadioData(false);
                            scrollToTop();
                        });
                })
                .catch(error => {
                    console.error('Error fetching current configuration:', error);
                    notifyError("Erreur lors de l'aquisition de la config !");
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                });
        }
    };



    const handleTitlePositionToggle = (position) => {
        let updatedPositions = [];

        // Push only the selected position
        if (position === "topRight") {
            updatedPositions.push("top", "right");
        } else if (position === "topLeft") {
            updatedPositions.push("top", "left");
        } else if (position === "bottomLeft") {
            updatedPositions.push("bottom", "left");
        } else if (position === "bottomRight") {
            updatedPositions.push("bottom", "right");
        } else if (position === "center") {
            updatedPositions.push("center");
        }

        // Set the updated positions
        setTitlePosition(updatedPositions);
    };

    // const handleCopyrightPositionToggle = (position) => {
    //     let updatedPositions = [];

    //     // Push only the selected position
    //     if (position === "topRight") {
    //         updatedPositions.push("top", "right");
    //     } else if (position === "topLeft") {
    //         updatedPositions.push("top", "left");
    //     } else if (position === "bottomLeft") {
    //         updatedPositions.push("bottom", "left");
    //     } else if (position === "bottomRight") {
    //         updatedPositions.push("bottom", "right");
    //     } else if (position === "center") {
    //         updatedPositions.push("center");
    //     }

    //     // Set the updated positions
    //     setCopyrightPosition(updatedPositions);
    // };


    const handleCopyrightPositionToggle = (position) => {
        // Directly set the selected position
        setCopyrightPosition(position);
    };



    console.log("Copyrights f position ", copyrightPosition);

    const handleCopyrightAlignmentToggle = (position) => {
        // Set the selected position (0 for left, 1 for center, 2 for right)
        setCopyrightAlign(position);
    };

    const handleWalletPositionToggle = (position) => {
        let updatedPositions = [];

        // Push only the selected position
        if (position === "topRight") {
            updatedPositions.push("top", "right");
        } else if (position === "topLeft") {
            updatedPositions.push("top", "left");
        } else if (position === "bottomLeft") {
            updatedPositions.push("bottom", "left");
        } else if (position === "bottomRight") {
            updatedPositions.push("bottom", "right");
        }

        // Set the updated positions
        setWalletPosition(updatedPositions);
    };



    const handleLogoPositionToggle = (position) => {
        let updatedPositions = [];

        // Push only the selected position
        if (position === "topRight") {
            updatedPositions.push("top", "right");
        } else if (position === "topLeft") {
            updatedPositions.push("top", "left");
        } else if (position === "bottomLeft") {
            updatedPositions.push("bottom", "left");
        } else if (position === "bottomRight") {
            updatedPositions.push("bottom", "right");
        }

        // Set the updated positions
        setLogoPosition(updatedPositions);
    };


    const toggleTransitionEffect = (clickedElement) => {
        if (transitionEffect.includes(clickedElement)) {
            // If the clicked element is already in the list, remove it
            const newTransitionEffect = transitionEffect.filter(
                (element) => element !== clickedElement
            );
            setTransitionEffect(newTransitionEffect);
        } else {
            // If the clicked element is not in the list, add it
            setTransitionEffect([...transitionEffect, clickedElement]);
        }
    };
    const toggleSlideShowEffect = (clickedElement) => {
        if (slideShowEffect.includes(clickedElement)) {
            // If the clicked element is already in the list, remove it
            const newSlideShowEffect = slideShowEffect.filter(
                (element) => element !== clickedElement
            );
            setSlideShowEffect(newSlideShowEffect);
        } else {
            // If the clicked element is not in the list, add it
            setSlideShowEffect([...slideShowEffect, clickedElement]);
        }
    };
    // let test = 'Example: 537'

    // console.log("testtest", test);

    // console.log("selected RAdio   ", currentSelectedRadio);
    // let targetedR = radios && radios.find((r) => r.id = currentSelectedRadio)
    // let tag = targetedR && targetedR.tagRadio
    // console.log("the  targeted   currentSelectedRadio ", tag);




    const [sleeveDisp, setSleeveDisp] = useState(false);

    const handleSendSleeve = () => {




        const selectedFile = selectedFilesY[0]; // Assuming only one file is selected
        if (!selectedFile) {
            console.error('No file selected');
            return;
        }


        console.log('selectedFileselectedFilePochetteImage', selectedFile);

        setSleeve(selectedFile)
        setSleeveDisp(true)

        console.log('sleveXXX', sleeve);


        // alert("testtststs")
    };

    useEffect(() => {
        console.log('Updated sleeve:', sleeve); // This will log the new value of sleeve
    }, [sleeve]);





    const [imagesAdded, setImagesAdded] = useState(false); // State variable to track if images are added successfully


    const handleSendSlideImages = () => {
        if (currentSelectedRadio) {
            console.log("selectedFilesZ ztest ztest", selectedFilesZ);

            // Flag to track if toast has been shown
            let toastShown = false;

            // Iterate over selected files to process each file
            selectedFilesZ.forEach(selectedFile => {
                // Create FormData object
                const formData = new FormData();
                formData.append('image', selectedFile);
                formData.append('radio', currentSelectedRadio);

                // Perform a POST request to create a new slide with the image
                axiosClient.post('radio-slides-default-images/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        console.log(`New slide created successfully with image:`, response.data);

                        // Show toast only if it hasn't been shown yet
                        if (!toastShown) {
                            notifySuccess("Images ajoutées avec succès");
                            toastShown = true; // Update flag
                            setImagesAdded(true); // Set imagesAdded to true when images are successfully added

                        }

                        const user_id = user && user.id

                        axiosClient.get(`targeted_radios/?user=${user_id}`)
                            .then(response => {
                                // Assuming you have a method to update slidesRadios state with fetched data
                                dispatch(fetchSlidesRadioSuccess(response.data));
                                console.log('GET request successful for updated slide data:', response.data);
                            })
                            .catch(error => {
                                console.error('Error fetching updated slide data:', error);
                                // Handle error from the GET request
                            });
                        // You can optionally update your local state here if needed
                    })
                    .catch(error => {
                        // Error handling
                    });
            });
        } else {
            // Handle case where no radio is selected
        }
    };






    const handleDeleteImagesSlideShow = (indexToRemove) => {



        setselectedFilesZ(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
        notifySuccess("Image supprimé avec success")


    };

    const handleDeleteImagesSlideShowExisted = (indexToRemove) => {
        // Identify the slide to delete
        // let slideToDelete = null;
        // const targetedSLice = slidesRadios
        const slideToDelete = slidesRadios && currentSelectedRadio && slidesRadios && slidesRadios.filter((slide, index) => slide.radio === currentSelectedRadio && index === indexToRemove);
        const targetedSlices = slidesRadios && slidesRadios && currentSelectedRadio && slidesRadios.filter((slide, index) => slide.radio === currentSelectedRadio)
        const targetedSlice = targetedSlices[indexToRemove]

        console.log("slideToDelete", targetedSlice);

        if (targetedSlice && targetedSlice.id) {
            // Send a DELETE request to the server to delete the image associated with the slide
            axiosClient.delete(`radio-slides-default-images/${targetedSlice.id}/`)
                .then(response => {
                    console.log(`Image for slide ${slideToDelete.id} deleted successfully:`, response.data);
                    // alert(`Image for slide ${slideToDelete.id} deleted successfully`);
                    notifySuccess("Image supprimé avec succes !")
                    const user_id = user && user.id

                    axiosClient.get(`radio_slides/?radio=${currentSelectedRadio}`).then(response => {
                        dispatch(fetchSlidesRadioSuccess(response.data));

                        console.log('GET request successful for updated radio:', response.data);
                        // Handle the updated radio data as needed
                    })
                        .catch(error => {
                            console.error('Error fetching updated radio data:', error);
                            // Handle error from the GET request
                        });


                })
                .catch(error => {
                    console.error(`Error deleting image for slide ${slideToDelete.id}:`, error);
                    let errorMessage = `Error deleting image for slide ${slideToDelete.id}`;
                    if (error.response) {
                        errorMessage += `\nStatus: ${error.response.status}`;
                        errorMessage += `\nResponse data: ${JSON.stringify(error.response.data)}`;
                    } else if (error.request) {
                        errorMessage += "\nNo response received from the server";
                    } else {
                        errorMessage += `\nError message: ${error.message}`;
                    }
                    console.error(errorMessage);
                    notifyError(errorMessage);
                });

        }
    };



    // console.log("loooooooooooooogo", radios.find((radio) => radio.name === selectedRadio)?.logo);
    console.log("selectedFileZselectedZ", selectedFilesZ);




    console.log("the currrent user role", user.role);

    const updateRadioLogo = () => {


        setShouldIncludeSleeve(false)
    };
    const [userRadios, setUserRadios] = useState([]);

    useEffect(() => {

        setUserRadios(radios)

    }, [radios])



    console.log("the  radiiiiios", userRadios);
    const reloadPage = () => {
        window.location.reload(); // Reload the page
    };

    const [shouldScroll, setShouldScroll] = useState(false);

    // Inside your component, calculate the height of each item and the total height
    const itemHeight = 40; // Assuming each item has a height of 40px
    const maxHeight = 400; // Maximum height of the dropdown menu
    const totalHeight = userRadios && userRadios.length * itemHeight;
    console.log("userRAdiooooooos", userRadios);
    // Use useEffect to determine if the dropdown menu should have scroll
    useEffect(() => {
        if (totalHeight > maxHeight) {
            setShouldScroll(true);
        } else {
            setShouldScroll(false);
        }
    }, [userRadios]);




    console.log("all slides radio", slidesRadios && slidesRadios);

    if (currentSelectedRadio) {
        // Filter slidesRadios to get only the corresponding slide radios
        const correspondingSlides = slidesRadios.filter(correspondingSlideRadio => correspondingSlideRadio.radio === currentSelectedRadio);
        console.log("the corresponding slides are the following  : ", correspondingSlides);
        // Log the corresponding slide radios
        console.log("all the stucking    Slide Radios:", correspondingSlides);
        correspondingSlides.forEach((correspondingSlideRadio, index) => {
            console.log(`Slide Radio ${index + 1}:`, correspondingSlideRadio);
        });
    }

    console.log("userffff", currentSelectedRadio);
    const [modal_center, setmodal_center] = useState(false)

    useEffect(() => {
        if (modal === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [modal])
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function tog_center() {
        setmodal_center(!modal_center)
        removeBodyCss()

    }

    const [changeModal, setChangeModal] = useState(true)
    // let globalPreviewUrl = "";

    const [globalPreviewUrl, setGlobalPreviewUrl] = useState("")

    console.log("globalPreviewUrlglobalPreviewUrl",);

    const [modalUrl, setModalUrl] = useState(""); // State variable to store the URL of the image preview to be displayed in the modal


    const handleEyeButtonClick = (previewUrl) => {
        tog_center(); // Toggle the modal
        setChangeModal(true); // Change the modal content (if necessary)
        setModalUrl(previewUrl); // Set the URL of the clicked image preview
    };

    console.log("the  modalUrl", modalUrl);


    const [fontLoading, setFontLoading] = useState(true); // State to manage loading

    const [activeFontFamily, setActiveFontFamily] = useState("Open Sans");


    const [combinedFonts, setCombinedFonts] = useState([]);


    console.log("combinedFontscombinedFonts", combinedFonts);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermP, setSearchTermP] = useState('');

    // Filter fonts based on the search term
    const filteredFonts = availableFonts.filter(font =>
        font.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const filteredFontsP = availableFonts.filter(font =>
        font.toLowerCase().includes(searchTermP.toLowerCase())
    );


    const [searchTerm_, setSearchTerm_] = useState('');

    // Filter fonts based on the search term
    const filteredFonts_ = availableFonts.filter(font =>
        font.toLowerCase().includes(searchTerm_.toLowerCase())
    );


    const [searchTerm__, setSearchTerm__] = useState('');

    // Filter fonts based on the search term
    const filteredFonts__ = availableFonts.filter(font =>
        font.toLowerCase().includes(searchTerm__.toLowerCase())
    );
    const [fontSquirelList, setFontSquirelList] = useState([]);

    console.log("lyricsBgColorTestovvvvvvb1", lyricsBgColor);
    console.log("saving check", saving);
    // const scrollToTop = () => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth' // Smooth scroll
    //     });
    // };

    const scrollToTop = () => {
        const topElement = document.getElementById('top'); // Assuming you have an element with id 'top' at the top of your page
        if (topElement) {
            topElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    if (sleeve && sleeve.length > 0) {
        console.log("The existantb sleeve ", sleeve);

    } else {

    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: selectedRadio !== "Sélectionner une radio" ? <SampleNextArrow /> : null,
        prevArrow: selectedRadio !== "Sélectionner une radio" ? <SamplePrevArrow /> : null,
    };



    console.log("SLides radio length ", slidesRadios.length)


    // console.log("dd ssk", radios.find((radio) => radio.name === selectedRadio)?.name);
    console.log('selectedFilesZUIOP', selectedFilesZ);
    return (
        <Row className="icons-demo-content">
            <Col xs="12">

                <Card>

                    <CardBody>
                        <h4 id='top' className="card-title" style={{ fontWeight: 'bold' }}>Choix de la radio</h4>

                        <Row >
                            <Col md={12} className="d-flex justify-content-center position-relative"> {/* Center the content and position relative */}
                                <ButtonDropdown
                                    style={{ width: '500px' }}
                                    className="btn-group mt-1 position-absolute bottom-0"
                                    isOpen={dropAlignRadio}
                                    direction="right"
                                    toggle={() => setDropAlignRadio(!dropAlignRadio)}
                                >
                                    <DropdownToggle caret color="secondary" className="btn btn-secondary">
                                        {selectedRadio ? selectedRadio : "Select Radio"}
                                    </DropdownToggle>
                                    <DropdownMenu style={{ width: '100%' }} className={`dropdown-menu-right-custom${shouldScroll ? ' with-scroll' : ''}`}>
                                        {userRadios && Array.isArray(userRadios) && userRadios.map(radio => (
                                            <DropdownItem key={radio.id} onClick={() => handleSelectedRadio(radio.name)}>
                                                {radio.name}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </Row>



                    </CardBody>

                </Card>



                {(loading) ?
                    (<>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                            <Lottie options={defaultOptions} height={120} width={120} />
                        </div>

                    </>)

                    :

                    (

                        <>

                            <Modal
                                size='lg'
                                isOpen={modal_center}

                                toggle={() => {
                                    tog_center()

                                }}
                                centered={true}
                            >

                                <ModalHeader style={{ fontSize: "20px" }} toggle={() => {
                                    tog_center()
                                }}>


                                </ModalHeader>

                                <ModalBody className=' '>
                                    <CardImg style={{ height: "80%" }} top className="img-fluid" src={modalUrl} alt="image" />
                                </ModalBody>

                            </Modal>
                            <Card>

                                <CardBody>
                                    <h4 className="card-title" style={{ fontWeight: 'bold' }}></h4>



                                    <Row>
                                        <Card className="col-md-12">
                                            <Row>

                                                <Col md={6}>


                                                    <CardBody>
                                                        <CardTitle className="h4">Logo</CardTitle>
                                                        <hr style={{ borderTop: '2px solid #999' }} />
                                                        <Row className="mb-3  mt-4">
                                                            <div className="col-md-12" >
                                                                <Typography
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center', // Align items vertically
                                                                        height: '70px',
                                                                        backgroundColor: '#e0f0ff',
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderBottom: 'none',
                                                                        borderLeft: '5px solid #419af2',
                                                                        borderRadius: '0px',
                                                                        paddingLeft: '10px', // Add padding to adjust icon position
                                                                    }}
                                                                >
                                                                    <i style={{ color: "#65ace7", fontSize: '30px' }} className='dripicons-warning'></i>
                                                                    <Typography style={{ fontWeight: 'bold', color: '#559bd5', marginLeft: '10px', fontSize: '10px' }}>
                                                                        IMAGE (1:1 ,JPEG ou PNG )


                                                                    </Typography>
                                                                </Typography>

                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3  mt-4" >
                                                            <Form>
                                                                <Dropzone
                                                                    onDrop={acceptedFiles => {
                                                                        handleAcceptedFilesY(acceptedFiles)
                                                                    }}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <form encType="multipart/form-data">

                                                                            <div className="dropzone dz-clickable">
                                                                                <div className="dz-message needsclick" {...getRootProps()}>
                                                                                    <input {...getInputProps()} type="file" /> {/* Specify type="file" here */}
                                                                                    <div className="mb-3">
                                                                                        <i className="mdi mdi-cloud-upload-outline text-muted display-4"></i>
                                                                                    </div>
                                                                                    <h4 className="fs-5 fs-sm-3">Pochette par défaut</h4>
                                                                                </div>
                                                                            </div>

                                                                        </form>
                                                                    )}
                                                                </Dropzone>
                                                                <div className="dropzone-previews mt-3" id="file-previews">
                                                                    {selectedFilesY.length > 0 ? (
                                                                        // Render selected files from upload
                                                                        selectedFilesY.map((f, i) => (

                                                                            <Card
                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                key={i + "-file"}
                                                                            >
                                                                                <div className="p-2">
                                                                                    <Row className="align-items-center">
                                                                                        <Col className="col-auto">
                                                                                            <img
                                                                                                data-dz-thumbnail=""
                                                                                                height="80"
                                                                                                className="avatar-sm rounded bg-light"
                                                                                                alt={f.name}
                                                                                                src={f.preview}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Link to="#" className="text-muted font-weight-bold">
                                                                                                {f.name}
                                                                                            </Link>


                                                                                        </Col>
                                                                                        <Col className="col-auto">

                                                                                            <Button onClick={() => handleEyeButtonClick(f.preview)} className="btn btn-info btn-sm ml-8">
                                                                                                <FaEye />
                                                                                            </Button>
                                                                                        </Col>
                                                                                        <Col className="col-auto">
                                                                                            <Button onClick={handleDeleteImage} className="btn btn-danger btn-sm ml-2">
                                                                                                <FaTrash />
                                                                                            </Button>
                                                                                            {/* {!sleeveDisp && <Button onClick={handleSendSleeve} className="btn btn-success btn-sm mr-2">
                                                                                                <FaCheck />
                                                                                            </Button>} */}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Card>
                                                                        ))
                                                                    ) : (

                                                                        // Render selected radio logo
                                                                        selectedRadio && radios && Array.isArray(radios) && radios.find((radio) => radio && radio.name === selectedRadio) && (


                                                                            selectedRadio && radios && radios.find((radio) => radio.name === selectedRadio && radio.sleeve !== null) ? (

                                                                                (<Card
                                                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                >
                                                                                    <Card encType="multipart/form-data">
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    {sleeve && sleeve.length > 0 &&
                                                                                                        (
                                                                                                            <>

                                                                                                                <img
                                                                                                                    data-dz-thumbnail=""
                                                                                                                    height="80"
                                                                                                                    className="avatar-sm rounded bg-light"
                                                                                                                    alt={linkImage + sleeve}
                                                                                                                    src={linkImage + sleeve}
                                                                                                                /></>
                                                                                                        )}

                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link to="#" className="text-muted font-weight-bold">
                                                                                                        {radios.find((radio) => radio.name === selectedRadio)?.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{formattedSize}</strong>
                                                                                                    </p>

                                                                                                </Col>
                                                                                                <Col className="col-auto">

                                                                                                    <Button onClick={() => {
                                                                                                        handleEyeButtonClick(sleeve)
                                                                                                    }} className="btn btn-info btn-sm ">
                                                                                                        <FaEye />
                                                                                                    </Button>
                                                                                                    {/* 
                                                                                                    <Button onClick={() => updateRadioLogo()} className="btn btn-danger btn-sm">
                                                                                                        <FaTrash />
                                                                                                    </Button> */}
                                                                                                </Col>
                                                                                                <Col className="col-auto">
                                                                                                    {/* <Button onClick={handleDeleteLogoApi} className="btn btn-danger btn-sm mr-2">
                                                                                    <FaTrash />
                                                                                </Button> */}

                                                                                                    {/* <Button onClick={handleSendLogo} className="btn btn-success btn-sm mr-2">
                                                                                        <FaCheck />
                                                                                    </Button> */}

                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                </Card>)
                                                                            ) : (<p></p>)
                                                                        )
                                                                    )}
                                                                </div>


                                                            </Form>




                                                        </Row>






                                                    </CardBody>
                                                </Col>
                                                <Col md={6}>

                                                    <CardBody >


                                                        <Row className="mb-1  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label mt-3"
                                                            >
                                                                Position
                                                            </label>
                                                            <div className="col-md-8" >
                                                                <div style={{ display: 'flex', marginLeft: "30px" }}>
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={topLeft}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: logoPosition.includes('top') && logoPosition.includes('left') ? '2px solid blue' : '1px solid black'

                                                                        }}
                                                                        onClick={() => handleLogoPositionToggle("topLeft")}
                                                                    />
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={topRight}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: logoPosition.includes('top') && logoPosition.includes('right') ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleLogoPositionToggle("topRight")}
                                                                    />
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={bottomLeft}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: logoPosition.includes('bottom') && logoPosition.includes('left') ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleLogoPositionToggle("bottomLeft")}
                                                                    />
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={bottomRight}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: logoPosition.includes('bottom') && logoPosition.includes('right') ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleLogoPositionToggle("bottomRight")}
                                                                    />
                                                                </div>


                                                                {/* <ButtonGroup className="w-50 mt-2" style={{ marginLeft: '30px' }} size="md" role="group" aria-label="position">
                                                                    <Button style={{ backgroundColor: logoPosition.includes("left") ? "#11708a" : "" }} type="button" color="info" onClick={() => handleLogoPositionToggle("left")}>
                                                                        gauche
                                                                    </Button>{" "}
                                                                    <Button style={{ backgroundColor: logoPosition.includes("right") ? "#11708a" : "" }} type="button" color="info" onClick={() => handleLogoPositionToggle("right")}>
                                                                        droite
                                                                    </Button>{" "}
                                                                </ButtonGroup> */}

                                                            </div>



                                                        </Row>




                                                        <Row className="mb-3  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Hauteur(px)
                                                            </label>
                                                            <div className="col-md-8">
                                                                <input style={{ marginLeft: '30px', borderRadius: '9px', backgroundColor: '#f2f6f9', borderColor: '3px solid  #f2f6f9' }}
                                                                    className="form-control"
                                                                    type="number"
                                                                    value={logoH}
                                                                    onChange={(e) => {
                                                                        // Convert the input value to a number
                                                                        let newValue = parseInt(e.target.value);

                                                                        // // Check if the new value is greater than 50, if so, set it to 50
                                                                        // if (newValue > 50) {
                                                                        //     newValue = 50;
                                                                        // }

                                                                        // Update the state with the new value
                                                                        setLogoH(newValue);
                                                                    }}
                                                                    id="example-number-input"
                                                                // max={50} // This limits the input to a maximum value of 50
                                                                />
                                                            </div>
                                                        </Row>


                                                        <Row className="mb-3  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Largeur(px)
                                                            </label>
                                                            <div className="col-md-8">
                                                                <input style={{ marginLeft: '30px', borderRadius: '9px', backgroundColor: '#f2f6f9', borderColor: '3px solid  #f2f6f9' }}
                                                                    className="form-control"
                                                                    type="number"
                                                                    value={logoW}
                                                                    onChange={(e) => {
                                                                        // Convert the input value to a number
                                                                        let newValue = parseInt(e.target.value);

                                                                        // // Check if the new value is greater than 50, if so, set it to 50
                                                                        // if (newValue > 50) {
                                                                        //     newValue = 50;
                                                                        // }

                                                                        // Update the state with the new value
                                                                        setLogoW(newValue);
                                                                    }}
                                                                    id="example-number-input"
                                                                // max={50} // This limits the input to a maximum value of 50
                                                                />
                                                            </div>

                                                        </Row>




                                                    </CardBody>
                                                </Col>
                                            </Row>


                                        </Card>

                                    </Row>

                                    <Divider orientation="horizontal" flexItem
                                        sx={{ borderColor: 'blue', marginBottom: "10px", borderWidth: "3px" }} />










                                    <Row>
                                        <Card className="col-md-12">
                                            <Row>

                                                <Col md={6}>
                                                    <CardBody>

                                                        <CardTitle className="h4">Titre musical / Titre programme</CardTitle>
                                                        <hr style={{ borderTop: '2px solid #999' }} />

                                                        <Row className="mb-3 mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Taille(pts)
                                                            </label>
                                                            <div className="col-md-8" >
                                                                <input style={{ marginLeft: '30px', borderRadius: '9px', backgroundColor: '#f2f6f9', borderColor: '3px solid  #f2f6f9' }}
                                                                    className="form-control"
                                                                    type="number"
                                                                    value={titleSize}
                                                                    onChange={(e) => setTitleSize(e.target.value)} id="example-number-input"
                                                                />
                                                            </div>
                                                        </Row>


                                                        <Row className="mb-3 mt-4">
                                                            <label htmlFor="example-number-input" className="col-md-3 col-form-label">Police</label>
                                                            <div className="col-md-8" style={{ marginLeft: '30px' }}>
                                                                <ButtonDropdown
                                                                    style={{ width: '340px' }}
                                                                    className="btn-group mt-1"
                                                                    isOpen={dropAlignX}
                                                                    direction="right"
                                                                    toggle={() => setDropAlignX(!dropAlignX)}
                                                                >
                                                                    <DropdownToggle caret color="secondary" className="btn btn-secondary">
                                                                        {titlePolice} <i className="mdi mdi-chevron-down" />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu-right-custom with-scroll">
                                                                        <div style={{ padding: '10px' }}>
                                                                            <Input
                                                                                type="text"
                                                                                placeholder="Search fonts..."
                                                                                value={searchTerm}
                                                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                                            />
                                                                        </div>
                                                                        {filteredFonts.map((font, index) => (
                                                                            <DropdownItem key={index} onClick={() => handleFontSelectX(font)}>
                                                                                <span style={{ fontFamily: font }}>{font}</span>
                                                                            </DropdownItem>
                                                                        ))}
                                                                    </DropdownMenu>
                                                                </ButtonDropdown>


                                                            </div>

                                                        </Row>
                                                        <Row className="mb-3  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Couleur
                                                            </label>
                                                            <div className="col-md-8" >
                                                                <input
                                                                    style={{
                                                                        marginLeft: '30px',
                                                                        width: "100%"
                                                                    }}
                                                                    className="form-control form-control-color mw-100"
                                                                    type="color"
                                                                    defaultValue={titleColor} // Set the defaultValue to the current value of titleColor
                                                                    onChange={(e) => setTitleColor(e.target.value)


                                                                    }

                                                                    id="example-color-input"

                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-1 mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label mt-4"
                                                            >
                                                                Position du texte
                                                            </label>
                                                            <div className="col-md-8 d-flex flex-column align-items-center">

                                                                <div style={{ display: 'flex', marginLeft: "30px" }}>
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={topLeft}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: titlePosition.includes('top') && titlePosition.includes('left') ? '2px solid blue' : '1px solid black'

                                                                        }}
                                                                        onClick={() => handleTitlePositionToggle("topLeft")}
                                                                    />
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={topRight}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: titlePosition.includes('top') && titlePosition.includes('right') ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleTitlePositionToggle("topRight")}
                                                                    />
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={center}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: titlePosition.includes('center') ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleTitlePositionToggle("center")}
                                                                    />
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={bottomLeft}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: titlePosition.includes('bottom') && titlePosition.includes('left') ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleTitlePositionToggle("bottomLeft")}
                                                                    />
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={bottomRight}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: titlePosition.includes('bottom') && titlePosition.includes('right') ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleTitlePositionToggle("bottomRight")}
                                                                    />
                                                                </div>

                                                            </div>


                                                        </Row>
                                                    </CardBody>
                                                    <CardBody>
                                                        <CardTitle className="h4">Artiste / Sous-titre programme</CardTitle>
                                                        <hr style={{ borderTop: '2px solid #999' }} />


                                                        <Row className="mb-3  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Taille(pts)
                                                            </label>
                                                            <div className="col-md-8" >
                                                                <input style={{ marginLeft: '30px', borderRadius: '9px', backgroundColor: '#f2f6f9', borderColor: '3px solid  #f2f6f9' }}
                                                                    className="form-control"
                                                                    type="number"
                                                                    value={artistSize}
                                                                    onChange={(e) => setArtistSize(e.target.value)} id="example-number-input"
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3 mt-4">
                                                            <label htmlFor="example-number-input" className="col-md-3 col-form-label">Police</label>
                                                            <div className="col-md-8" style={{ marginLeft: '30px' }}>
                                                                <ButtonDropdown
                                                                    style={{ width: '340px' }}
                                                                    className="btn-group mt-1"
                                                                    isOpen={dropAlignZ}
                                                                    direction="right"
                                                                    toggle={() => setDropAlignZ(!dropAlignZ)}
                                                                >
                                                                    <DropdownToggle caret color="secondary" className="btn btn-secondary">
                                                                        {artistPolice} <i className="mdi mdi-chevron-down" />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu-right-custom with-scroll">
                                                                        <div style={{ padding: '10px', width: '300px' }}>
                                                                            <Input
                                                                                type="text"
                                                                                placeholder="Search fonts..."
                                                                                value={searchTerm_}
                                                                                onChange={(e) => setSearchTerm_(e.target.value)}
                                                                            />
                                                                        </div>
                                                                        {filteredFonts_.map((font, index) => (
                                                                            <DropdownItem key={index} onClick={() => handleFontSelectZ(font)}>
                                                                                <span style={{ fontFamily: font }}>{font}</span>
                                                                            </DropdownItem>
                                                                        ))}
                                                                    </DropdownMenu>
                                                                </ButtonDropdown>
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Couleur
                                                            </label>
                                                            <div className="col-md-8" >
                                                                <input
                                                                    style={{
                                                                        marginLeft: '30px',
                                                                        width: "100%"
                                                                    }}
                                                                    className="form-control form-control-color mw-100"
                                                                    type="color"
                                                                    defaultValue={artistColor} // Set the defaultValue to the current value of titleColor
                                                                    onChange={(e) => setArtistColor(e.target.value)


                                                                    } id="example-color-input"

                                                                />
                                                            </div>
                                                        </Row>

                                                    </CardBody>
                                                </Col>
                                                <Col md={6}>
                                                    <CardBody style={{ height: "200px" }}>


                                                        <CardTitle className="h4">Autres paramètres</CardTitle>
                                                        <hr style={{ borderTop: '2px solid #999' }} />


                                                        <Row className="mb-3  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Inverser position Titre / Artiste
                                                            </label>
                                                            <div className="col-md-8" >
                                                                <ButtonGroup className="w-50" style={{ marginLeft: '30px' }} size="md" role="group" aria-label="position">
                                                                    <Button style={{ backgroundColor: positionInversion === false ? "#11708a" : "" }}
                                                                        type="button" color="info" onClick={() => { setPositionInversion(false) }}>
                                                                        Non
                                                                    </Button>{" "}
                                                                    <Button style={{ backgroundColor: positionInversion === true ? "#11708a" : "" }}
                                                                        type="button" color="info" onClick={() => { setPositionInversion(true) }}>
                                                                        Oui
                                                                    </Button>{" "}
                                                                </ButtonGroup>


                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                    <CardBody style={{ height: "250px" }}>
                                                        <CardTitle className="h4"> Effets text</CardTitle>
                                                        <hr style={{ borderTop: '2px solid #999' }} />
                                                        <Row style={{ height: "150px", marginLeft: "20px" }} className="h-30 "> {/* Upper section takes 30% */}
                                                            <Col sm={3}>
                                                                <div className={`square-box ${textEffect === "ombre" ? 'clicked' : ''}`}
                                                                    onClick={() => setTextEffect("ombre")}>
                                                                    <CardImg style={{ height: "80%" }}

                                                                        top className="img-fluid" src={ombres} alt="Lexa" />

                                                                    <span className="box-text">Ombre</span>

                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <div className={`square-box ${textEffect === "elevation" ? 'clicked' : ''}`}
                                                                    onClick={() => setTextEffect("elevation")}>
                                                                    <CardImg style={{ height: "80%" }}

                                                                        top className="img-fluid" src={elevation} alt="Lexa" />

                                                                    <span className="box-text">Elévation</span>

                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <div className={`square-box ${textEffect === "contour" ? 'clicked' : ''}`}
                                                                    onClick={() => setTextEffect("contour")}>
                                                                    <CardImg style={{ height: "80%" }}

                                                                        top className="img-fluid" src={countour} alt="Lexa" />


                                                                    <span className="box-text">Contour</span>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <div className={`square-box ${textEffect === "aucun" ? 'clicked' : ''}`}
                                                                    onClick={() => setTextEffect("aucun")}>
                                                                    <CardImg style={{ height: "80%" }}

                                                                        top className="img-fluid" src={aucun} alt="Lexa" />


                                                                    <span className="box-text">Aucun</span>
                                                                </div>
                                                            </Col>

                                                        </Row>


                                                    </CardBody>
                                                    <CardBody style={{ backgroundColor: "#f6f7f8", height: "160px" }}>



                                                        <Row style={{ marginLeft: "20px" }} className="mb-1  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-6 col-form-label"
                                                            >
                                                                Intensité
                                                            </label>

                                                        </Row>

                                                        <Row>


                                                            <div style={{
                                                                marginLeft: '30px'
                                                            }} className="col-md-10 " >
                                                                <Slider value={textEffectIntensity} // Set slider value
                                                                    onChange={handleTextEffectInts} size='medium' defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />

                                                            </div>
                                                        </Row>


                                                    </CardBody>
                                                </Col>

                                            </Row>
                                        </Card>
                                    </Row>




                                    <Divider orientation="horizontal" flexItem
                                        sx={{ borderColor: 'blue', marginBottom: "10px", borderWidth: "3px" }} />





                                    <Row>
                                        <Card className="col-md-12">
                                            <Row>

                                                <Col md={6}>
                                                    <CardBody>
                                                        <CardTitle className="h4">Images Slide Show</CardTitle>
                                                        <hr style={{ borderTop: '2px solid #999' }} />
                                                        <Row className="mb-3  mt-4">
                                                            <div className="col-md-12" >
                                                                <Typography
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center', // Align items vertically
                                                                        height: '70px',
                                                                        backgroundColor: '#e0f0ff',
                                                                        borderTop: 'none',
                                                                        borderRight: 'none',
                                                                        borderBottom: 'none',
                                                                        borderLeft: '5px solid #419af2',
                                                                        borderRadius: '0px',
                                                                        paddingLeft: '10px', // Add padding to adjust icon position
                                                                    }}
                                                                >
                                                                    <i style={{ color: "#65ace7", fontSize: '30px' }} className='dripicons-warning'></i>
                                                                    <Typography style={{ fontWeight: 'bold', color: '#559bd5', marginLeft: '10px', fontSize: '10px' }}>
                                                                        IMAGE (16:9 ,JPEG ou PNG )


                                                                    </Typography>
                                                                </Typography>

                                                            </div>
                                                        </Row>

                                                        <ImagesSlider
                                                            radios={radios}
                                                            imagesAdded={imagesAdded}
                                                            settings={settings}
                                                            selectedFilesZ={selectedFilesZ}
                                                            slidesRadios={slidesRadios}
                                                            currentSelectedRadio={currentSelectedRadio}
                                                            tooltipOpen={tooltipOpen}
                                                            toggleTooltip={toggleTooltip}
                                                            handleAcceptedFilesZ={handleAcceptedFilesZ}
                                                            handleEyeButtonClick={handleEyeButtonClick}
                                                            handleDeleteImagesSlideShow={handleDeleteImagesSlideShow}
                                                            handleSendSlideImages={handleSendSlideImages}
                                                            handleDeleteImagesSlideShowExisted={handleDeleteImagesSlideShowExisted}
                                                        />


                                                    </CardBody>
                                                    <CardBody style={{ backgroundColor: "#f6f7f8" }}>
                                                        <Row style={{ marginLeft: "20px" }} className="mb-3 mt-4">
                                                            <label htmlFor="example-number-input" className="col-md-3 col-form-label">
                                                                Filtre images                                                        </label>
                                                        </Row>

                                                        <Row className="mt-3" style={{ marginLeft: "10px" }}>
                                                            {/* Center the row horizontally */}
                                                            <div className="col-md-12 d-flex justify-content-center">
                                                                {/* Center the stack horizontally */}
                                                                <Stack spacing={2} direction="row">
                                                                    <Col xs={6}>
                                                                        <CardImg
                                                                            style={{
                                                                                height: "auto", // Maintain aspect ratio
                                                                                width: "92%", // Half of the column width
                                                                                border: blackWhiteImage === true ? "3px solid black" : "",
                                                                            }}
                                                                            onClick={() => { setBlackWhiteImage(true) }}
                                                                            top
                                                                            className="img-fluid"
                                                                            src={snow}
                                                                            alt="Lexa"
                                                                        />
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <CardImg
                                                                            style={{
                                                                                height: "auto", // Maintain aspect ratio
                                                                                width: "92%", // Half of the column width
                                                                                border: blackWhiteImage === false ? "3px solid black" : "",
                                                                            }}
                                                                            onClick={() => { setBlackWhiteImage(false) }}
                                                                            top
                                                                            className="img-fluid"
                                                                            src={snowc}
                                                                            alt="Lexa"
                                                                        />
                                                                    </Col>
                                                                </Stack>
                                                            </div>
                                                        </Row>
                                                    </CardBody>


                                                    <CardBody>

                                                        <CardTitle className="h4">Copyrights</CardTitle>
                                                        <hr style={{ borderTop: '2px solid #999' }} />







                                                        <Row className="mb-1 mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label mt-4"
                                                            >
                                                                Position
                                                            </label>
                                                            <div className="col-md-8 d-flex flex-column align-items-center">
                                                                <div style={{ display: 'flex', marginLeft: "30px" }}>
                                                                    {/* <div style={{ display: 'flex', marginLeft: "30px" }}>
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={topLeft}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: copyrightPosition.includes('top') && copyrightPosition.includes('left') ? '2px solid blue' : '1px solid black'

                                                                        }}
                                                                        onClick={() => handleCopyrightPositionToggle("topLeft")}
                                                                    />
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={topRight}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: copyrightPosition.includes('top') && copyrightPosition.includes('right') ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleCopyrightPositionToggle("topRight")}
                                                                    />
                                                                   
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={bottomLeft}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: copyrightPosition.includes('bottom') && copyrightPosition.includes('left') ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleCopyrightPositionToggle("bottomLeft")}
                                                                    />
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={bottomRight}
                                                                        alt="Lexa"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: copyrightPosition.includes('bottom') && copyrightPosition.includes('right') ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleCopyrightPositionToggle("bottomRight")}
                                                                    />
                                                                </div> */}
                                                                    <ButtonGroup className="w-50 mt-4" style={{ marginLeft: '30px' }} size="md" role="group" aria-label="position">
                                                                        <Button
                                                                            style={{ backgroundColor: copyrightPosition === "left" ? "#11708a" : "" }}
                                                                            type="button"
                                                                            color="info"
                                                                            onClick={() => handleCopyrightPositionToggle("left")}
                                                                        >
                                                                            Gauche
                                                                        </Button>
                                                                        <Button
                                                                            style={{ backgroundColor: copyrightPosition === "right" ? "#11708a" : "" }}
                                                                            type="button"
                                                                            color="info"
                                                                            onClick={() => handleCopyrightPositionToggle("right")}
                                                                        >
                                                                            Droite
                                                                        </Button>
                                                                        <Button
                                                                            style={{ backgroundColor: copyrightPosition === "top" ? "#11708a" : "" }}
                                                                            type="button"
                                                                            color="info"
                                                                            onClick={() => handleCopyrightPositionToggle("top")}
                                                                        >
                                                                            Haut
                                                                        </Button>
                                                                        <Button
                                                                            style={{ backgroundColor: copyrightPosition === "bottom" ? "#11708a" : "" }}
                                                                            type="button"
                                                                            color="info"
                                                                            onClick={() => handleCopyrightPositionToggle("bottom")}
                                                                        >
                                                                            Bas
                                                                        </Button>
                                                                    </ButtonGroup>


                                                                </div>

                                                            </div>




                                                        </Row>

                                                        <Row className="mb-3 mt-4">
                                                            <label htmlFor="example-number-input" className="col-md-3 col-form-label">
                                                                Alignment
                                                            </label>
                                                            <div className="col-md-8 d-flex flex-column align-items-center">
                                                                <div style={{ display: 'flex', marginLeft: "30px" }}>
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={right}
                                                                        alt="Left"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: copyrightAlign === 0 ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleCopyrightAlignmentToggle(0)}
                                                                    />
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={centerr}
                                                                        alt="Center"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: copyrightAlign === 1 ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleCopyrightAlignmentToggle(1)}
                                                                    />
                                                                    <CardImg
                                                                        className="img-fluid"
                                                                        src={left}
                                                                        alt="Right"
                                                                        style={{
                                                                            width: '60px', height: '60px', marginRight: "10px", border: copyrightAlign === 2 ? '2px solid blue' : '1px solid black'
                                                                        }}
                                                                        onClick={() => handleCopyrightAlignmentToggle(2)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3 mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Taille(pts)
                                                            </label>
                                                            <div className="col-md-8" >
                                                                <input style={{ marginLeft: '30px', borderRadius: '9px', backgroundColor: '#f2f6f9', borderColor: '3px solid  #f2f6f9' }}
                                                                    className="form-control"
                                                                    type="number"
                                                                    value={copyrightSize}
                                                                    onChange={(e) => setCopyrightSize(e.target.value)} id="example-number-input"
                                                                />
                                                            </div>
                                                        </Row>


                                                        <Row className="mb-3 mt-4">
                                                            <label htmlFor="example-number-input" className="col-md-3 col-form-label">Police</label>
                                                            <div className="col-md-8" style={{ marginLeft: '30px' }}>
                                                                <ButtonDropdown
                                                                    style={{ width: '340px' }}
                                                                    className="btn-group mt-1"
                                                                    isOpen={dropAlignP}
                                                                    direction="right"
                                                                    toggle={() => setDropAlignP(!dropAlignP)}
                                                                >
                                                                    <DropdownToggle caret color="secondary" className="btn btn-secondary">
                                                                        {copyrightPolice} <i className="mdi mdi-chevron-down" />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="dropdown-menu-right-custom with-scroll">
                                                                        <div style={{ padding: '10px' }}>
                                                                            <Input
                                                                                type="text"
                                                                                placeholder="Search fonts..."
                                                                                value={searchTermP}
                                                                                onChange={(e) => setSearchTermP(e.target.value)}
                                                                            />
                                                                        </div>
                                                                        {filteredFontsP.map((font, index) => (
                                                                            <DropdownItem key={index} onClick={() => handleFontSelectP(font)}>
                                                                                <span style={{ fontFamily: font }}>{font}</span>
                                                                            </DropdownItem>
                                                                        ))}
                                                                    </DropdownMenu>
                                                                </ButtonDropdown>


                                                            </div>

                                                        </Row>

                                                        <Row className="mb-3  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Couleur
                                                            </label>
                                                            <div className="col-md-8" >
                                                                <input
                                                                    style={{
                                                                        marginLeft: '30px',
                                                                        width: "100%"
                                                                    }}
                                                                    className="form-control form-control-color mw-100"
                                                                    type="color"
                                                                    defaultValue={copyrightColor} // Set the defaultValue to the current value of titleColor
                                                                    onChange={(e) => setCopyrightColor(e.target.value)


                                                                    }

                                                                    id="example-color-input"

                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Contour
                                                            </label>
                                                            <div className="col-md-8" >
                                                                <input
                                                                    style={{
                                                                        marginLeft: '30px',
                                                                        width: "100%"
                                                                    }}
                                                                    className="form-control form-control-color mw-100"
                                                                    type="color"
                                                                    defaultValue={copyrightContour} // Set the defaultValue to the current value of titleColor
                                                                    onChange={(e) => setCopyrightContour(e.target.value)


                                                                    }

                                                                    id="example-color-input"

                                                                />
                                                            </div>
                                                        </Row>

                                                    </CardBody>


                                                </Col>


                                                <Col md={6}>
                                                    <CardBody>
                                                        <CardTitle className="h4">Effets Slide show</CardTitle>
                                                    </CardBody>
                                                    <CardBody>
                                                        <Row style={{ height: "150px", marginLeft: "20px" }} className="h-30 "> {/* Upper section takes 30% */}
                                                            <Col sm={4}>
                                                                <div className={`square-box ${slideShowEffect.includes("zoom-in-in") ? 'clicked' : ''}`} onClick={() => toggleSlideShowEffect("zoom-in-in")}>
                                                                    <CardImg style={{ height: "80%" }} top className="img-fluid" src={zoom} alt="Lexa" />
                                                                    <span className="box-text">Zoom In</span>
                                                                </div>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <div className={`square-box ${slideShowEffect.includes("zoom-out") ? 'clicked' : ''}`}
                                                                    onClick={() => toggleSlideShowEffect("zoom-out")}>
                                                                    <CardImg style={{ height: "80%" }} top className=" img-fluid" src={zoomOut} alt="Lexa" />

                                                                    <span className="box-text">Zoom Out</span>

                                                                </div>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <div className={`square-box ${slideShowEffect.includes("monter") ? 'clicked' : ''}`}
                                                                    onClick={() => toggleSlideShowEffect("monter")}>
                                                                    <CardImg style={{ height: "80%" }} top className=" img-fluid" src={up} alt="Lexa" />


                                                                    <span className="box-text">Monter</span>
                                                                </div>
                                                            </Col>


                                                        </Row>
                                                        <Row style={{ height: "150px", marginLeft: "20px" }} className="h-30 "> {/* Upper section takes 30% */}
                                                            <Col sm={4}>
                                                                <div className={`square-box ${slideShowEffect.includes("descendre") ? 'clicked' : ''}`}
                                                                    onClick={() => toggleSlideShowEffect("descendre")}>

                                                                    <CardImg style={{ height: "80%" }} top className=" img-fluid" src={down} alt="Lexa" />

                                                                    <span className="box-text">Descendre</span>

                                                                </div>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <div className={`square-box ${slideShowEffect.includes("droite") ? 'clicked' : ''}`}
                                                                    onClick={() => toggleSlideShowEffect("droite")}>
                                                                    <CardImg style={{ height: "80%" }} top className=" img-fluid" src={scroll} alt="Lexa" />

                                                                    <span className="box-text">Défiler droite </span>

                                                                </div>
                                                            </Col>

                                                            <Col sm={4}>
                                                                <div className={`square-box ${slideShowEffect.includes("gauche") ? 'clicked' : ''}`}
                                                                    onClick={() => toggleSlideShowEffect("gauche")}>
                                                                    <CardImg style={{ height: "80%" }} top className=" img-fluid" src={scroll} alt="Lexa" />

                                                                    <span className="box-text">Défiler gauche </span>

                                                                </div>
                                                            </Col>

                                                        </Row>
                                                        {/* <Row style={{ height: "300px" }} className="h-70 bg-secondary">
                                                        <Col>Second Section</Col>
                                                    </Row> */}
                                                    </CardBody>
                                                    <CardBody style={{ backgroundColor: "#f6f7f8", height: "250px" }}>
                                                        <Row style={{ marginLeft: "20px" }} className="mb-3  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Animer
                                                            </label>

                                                        </Row>
                                                        <Row style={{ marginLeft: "70px" }} > {/* Center the row horizontally */}
                                                            <div className="col-md-8 d-flex justify-content-center"> {/* Center the stack horizontally */}
                                                                <Stack spacing={2} direction="row">
                                                                    <MUIButton
                                                                        variant="outlined"
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            color: "black",
                                                                            width: "110px",
                                                                            height: "50px",
                                                                            fontSize: '10px',
                                                                            border: animateOn === "both" ? "3px solid #11708a" : ""
                                                                        }}
                                                                        onClick={() => setAnimateOn("both")}
                                                                    >
                                                                        Les deux
                                                                    </MUIButton>
                                                                    <MUIButton
                                                                        variant="outlined"
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            color: "black",
                                                                            width: "110px",
                                                                            height: "50px",
                                                                            fontSize: '10px',
                                                                            border: animateOn === "entrance" ? "3px solid #11708a" : ""
                                                                        }}
                                                                        onClick={() => setAnimateOn("entrance")}
                                                                    >
                                                                        A l'entrée
                                                                    </MUIButton>
                                                                    <MUIButton
                                                                        variant="outlined"
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            color: "black",
                                                                            width: "115px",
                                                                            height: "50px",
                                                                            fontSize: '10px',
                                                                            border: animateOn === "exit" ? "3px solid #11708a" : ""
                                                                        }}
                                                                        onClick={() => setAnimateOn("exit")}
                                                                    >
                                                                        A la sortie
                                                                    </MUIButton>
                                                                </Stack>
                                                            </div>
                                                        </Row>


                                                        <Row style={{ marginLeft: "20px" }} className="mb-3  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Ampleur
                                                            </label>

                                                        </Row>

                                                        <Row>


                                                            <div style={{
                                                                marginLeft: '30px'
                                                            }} className="col-md-9 " >
                                                                <Slider value={animationIntensity} // Set slider value
                                                                    onChange={handleAnimationIntensityChange} size='medium' defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />

                                                            </div>
                                                        </Row>
                                                        {/* <Row className="mt-3" style={{ marginLeft: "-30px" }} > 
                                                        <div className="col-md-8 d-flex justify-content-center"> 
                                                            <Stack spacing={2} direction="row">
                                                                <MUIButton
                                                                    variant="outlined"
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        color: "black",
                                                                        width: "110px",
                                                                        height: "50px",
                                                                        fontSize: '10px',
                                                                        border: clickedIndexA === 0 ? "#11708a" : ""
                                                                    }}
                                                                    onClick={() => handleClickIdxA(0)}
                                                                >
                                                                    Intérieur
                                                                </MUIButton>
                                                                <MUIButton
                                                                    variant="outlined"
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        color: "black",
                                                                        width: "110px",
                                                                        height: "50px",
                                                                        fontSize: '10px',
                                                                        border: clickedIndexA === 1 ? "#11708a" : ""
                                                                    }}
                                                                    onClick={() => handleClickIdxA(1)}
                                                                >
                                                                    Extérieur
                                                                </MUIButton>
                                                            </Stack>
                                                        </div>
                                                    </Row> */}




                                                    </CardBody>
                                                    <CardBody style={{ marginTop: "35px", backgroundColor: "#f6f7f8", height: "180px" }}>

                                                        <Row style={{ marginLeft: "30px" }} className="mb-1  mt-4">
                                                            <label
                                                                htmlFor="example-number-input"
                                                                className="col-md-6 col-form-label"
                                                            >
                                                                Durée du slide (en secondes)
                                                            </label>

                                                        </Row>

                                                        <Row>


                                                            <div style={{
                                                                marginLeft: '30px'
                                                            }} className="col-md-9 " >
                                                                <Slider value={transitionDuration} // Set slider value
                                                                    onChange={handleTransitionDuration} size='medium' defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />

                                                            </div>
                                                        </Row>
                                                    </CardBody>

                                                    <CardBody>
                                                        <CardTitle className="h4">Effets transition slide show</CardTitle>
                                                    </CardBody>
                                                    <CardBody>
                                                        <Row style={{ height: "150px", marginLeft: "20px" }} className="h-30 "> {/* Upper section takes 30% */}
                                                            <Col sm={3}>
                                                                <div className={`square-box ${transitionEffect.includes("fondu") ? 'clicked' : ''}`}
                                                                    onClick={() => toggleTransitionEffect("fondu")}>
                                                                    <CardImg
                                                                        style={{ height: "80%" }

                                                                        } top className=" img-fluid" src={fondue} alt="Lexa" />

                                                                    <span className="box-text">Fondue</span>

                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <div className={`square-box ${transitionEffect.includes("flux") ? 'clicked' : ''}`}
                                                                    onClick={() => toggleTransitionEffect("flux")}>
                                                                    <CardImg
                                                                        style={{ height: "80%" }

                                                                        } top className=" img-fluid" src={drift} alt="Lexa" />

                                                                    <span className="box-text">Flux</span>

                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <div className={`square-box ${transitionEffect.includes("combiner") ? 'clicked' : ''}`}
                                                                    onClick={() => toggleTransitionEffect("combiner")}>                                                                <CardImg
                                                                        style={{ height: "80%" }

                                                                        } top className=" img-fluid" src={combine} alt="Lexa" />


                                                                    <span className="box-text">Combiné</span>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <div className={`square-box ${transitionEffect.includes("glisser") ? 'clicked' : ''}`}
                                                                    onClick={() => toggleTransitionEffect("glisser")}>                                                                <CardImg style={{ height: "80%" }}


                                                                        top className=" img-fluid" src={role} alt="Lexa" />


                                                                    <span className="box-text">Glisser</span>
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                        {/* <Row style={{ height: "300px" }} className="h-70 bg-secondary">
                                                        <Col>Second Section</Col>
                                                    </Row> */}
                                                    </CardBody>


                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>


                                    <Divider orientation="horizontal" color='blue' flexItem
                                        sx={{ marginBottom: "10px", borderWidth: "3px" }} />


                                    <LowerSection
                                        walletH={walletH}
                                        setWalletH={setWalletH}
                                        walletW={walletW}
                                        setWalletW={setWalletW}
                                        walletPosition={walletPosition}
                                        handleWalletPositionToggle={handleWalletPositionToggle}
                                        topLeft={topLeft}
                                        topRight={topRight}
                                        bottomLeft={bottomLeft}
                                        bottomRight={bottomRight}
                                        lyricsSize={lyricsSize}
                                        setLyricsSize={setLyricsSize}
                                        lyricsFilterSize={lyricsFilterSize}
                                        setLyricsFilterSize={setLyricsFilterSize}
                                        lyricsPolice={lyricsPolice}
                                        dropAlignT={dropAlignT}
                                        setDropAlignT={setDropAlignT}
                                        searchTerm__={searchTerm__}
                                        setSearchTerm__={setSearchTerm__}
                                        filteredFonts__={filteredFonts__}
                                        handleFontSelectT={handleFontSelectT}
                                        lyricsColor={lyricsColor}
                                        setLyricsColor={setLyricsColor}
                                        setLyricsColorN={setLyricsColorN}

                                        cConfig={cConfig}
                                        setLyricsBgColor={setLyricsBgColor}
                                        lyricsColorN={lyricsColorN}
                                    />

                                </CardBody >




                                <Row className="mb-3 mt-4">
                                    <label
                                        htmlFor="example-number-input"
                                        className="col-md-3 col-form-label"
                                    >
                                    </label>
                                    <div className="col-md-8 d-flex justify-content-end">
                                        <Button disabled={saveRadioData || lengthProhibited} style={{ marginRight: "20px" }}



                                            type="button" color="info" onClick={() => { handleConfirmButtonClick() }}>

                                            {!saveRadioData ? (


                                                <span>

                                                    Enregistrer
                                                </span>
                                            ) : (
                                                <span
                                                    class="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            )}


                                        </Button>
                                        <Button style={{ marginRight: "20px" }} type="button" color="secondary" onClick={reloadPage}>
                                            Recharger
                                        </Button>
                                        {currentSelectedRadio && <Button type="button" color="success" onClick={() => {
                                            toggle()
                                        }}>
                                            <RiSlideshow2Line size={18} style={{ marginRight: 5 }} />
                                            Aperçu
                                        </Button>}
                                    </div>
                                </Row>


                            </Card ></>

                    )
                }






            </Col >
            <ToastContainer
                position="top-center"
                autoClose={400}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition="bounce"
            />


            <Modal size='fullscreen' isOpen={modal} toggle={toggle}>

                <div style={{
                    position: 'relative'
                }}>
                    <div style={{
                        position: 'absolute',
                        top: '1%',
                        right: '1%',
                        zIndex: 9999,
                        background: '#d6d6d6',
                        borderRadius: '50%',
                        padding: 0,
                        margin: 0,
                        cursor: 'pointer'
                    }}
                        onClick={toggle}>
                        <IoCloseCircleOutline size={45} color='red' />
                    </div>

                    <PreviewSlide
                        idRadio={currentSelectedRadio}
                        configurationRadio={{
                            'blackWhiteImage': blackWhiteImage,
                            'walletPosition': walletPosition,
                            'walletH': walletH,
                            'walletW': walletW,
                            'textEffect': textEffect,
                            'titlePosition': titlePosition,
                            'titleColor': titleColor,



                            'copyrightColor': copyrightColor,
                            'copyrightContour': copyrightContour,
                            'copyrightPolice': copyrightPolice,
                            'copyrightPosition ': copyrightPosition,
                            'copyrightSize': copyrightSize,

                            'titleSize': titleSize,
                            'titlePolice': titlePolice,
                            'positionInversion': positionInversion,
                            'artistColor': artistColor,
                            'artistSize': artistSize,
                            'artistPolice': artistPolice,
                            'logoPosition': logoPosition,
                            'logoH': logoH,
                            'logoW': logoW,
                            "lyricsSize": lyricsSize,
                            "lyricsPolice": lyricsPolice,
                            "lyricsColor": lyricsColor,
                            "lyricsColorN": lyricsColorN,

                            "lyricsBgColor": lyricsBgColor,
                            "lyricsFilterSize": lyricsFilterSize,
                        }}
                        durationTransition={transitionDuration}
                        dataTransitionEffect={transitionEffect}
                        dataSlideShowEffect={slideShowEffect}
                    />
                </div>

            </Modal>
        </Row >
    )
}
